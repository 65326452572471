import React from "react"

export enum MediaRule {
    PhoneOnly = "(max-width: 767px)",
    TabletAndAbove = "(min-width: 768px)",
    TabletOnly = "(min-width: 768px) and (max-width: 1023px)",
    DesktopOnly = "(min-width: 1024px)",
}

export const useMatchMedia = (mediaRules: MediaRule) => {
    const [matches, setMatches] = React.useState(false)

    React.useEffect(() => {
        const mediaQueryList = window.matchMedia(mediaRules)
        setMatches(mediaQueryList.matches)

        const handleMediaChange = (e: MediaQueryListEvent) => {
            setMatches(e.matches)
        }

        mediaQueryList.addEventListener("change", handleMediaChange)

        return () => mediaQueryList.removeEventListener("change", handleMediaChange)
    }, [])

    return matches
}
