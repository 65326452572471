import React from "react"
import ApplicationContextProvider from "@/contexts/ApplicationContext"
import NotificationContextProvider from "@/contexts/NotificationsContext"
import ChatContextProvider from "@/contexts/ChatContext"
import ConfirmContextProvider from "@/contexts/ConfirmContext"

/**
 * Simply add your context provider to this array and it
 * will be injected as a global context to the entire application.
 */
const providers = [
    <NotificationContextProvider key="notifications-context-provider" />,
    <ApplicationContextProvider key="application-context-provider" />,
    <ChatContextProvider key="chat-context-provider" />,
    <ConfirmContextProvider key="confirm-context-provider" />
]

const nestProviders = (
    children: React.ReactNode,
    component: React.ReactElement
) => React.cloneElement(component, {}, children)

const GlobalContextProvider: React.FC = ({ children }) => <>{providers.reduceRight(nestProviders, children)}</>


export default GlobalContextProvider
