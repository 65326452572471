import React, { useEffect, useState } from "react"
import Router from "./Router"
import * as Sentry from "@sentry/react"
import GlobalContextProvider from "@/contexts/GlobalContextProvider/GlobalContextProvider"
import { BrowserRouter } from "react-router-dom"
import { QueryClient, QueryClientProvider } from "react-query"
import ReactGA from "react-ga4"
import IdleWarningModal from "@/components/Vault/Modals/IdleFolderModal/idleWarningModal"
import IdleLogoutModal from "@/components/Vault/Modals/IdleFolderModal/idleLogoutModal"
import { Userpilot } from "userpilot"



Userpilot.initialize("NX-afeaa9db")
ReactGA.initialize("G-73CXSK8D8X")
ReactGA.send("pageview")





const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            staleTime: Infinity,
        },
    },
})



export default function Application() {
    const [idleWarning, setIdleWarning] = useState(false)
    const [logout, setLogout] = useState(false)
    const MINUTE_MS = 60000 / 3

    React.useEffect(() => {
        Userpilot.reload()
    }, [window.location.pathname])

    useEffect(() => {
        const interval = setInterval(() => {
            const lastActive = localStorage.getItem("lastActive")
            if (lastActive && window.location.pathname !== "/" && window.location.pathname !== "/login") {
                const diffMs = new Date().getTime() - new Date(lastActive ? lastActive : "").getTime()
                const diffMin = diffMs % 86400000 % 3600000 / 60000
                if (Math.round(diffMin) >= 55 && Math.round(diffMin) < 56) {
                    setIdleWarning(true)
                    setLogout(false)
                }
                else if (Math.round(diffMin) >= 60) {
                    setLogout(true)
                    setIdleWarning(false)
                }
            }

        }, MINUTE_MS)
        return () => clearInterval(interval)
    }, [])

    window.addEventListener("click", () => {
        const d1 = new Date()
        localStorage.setItem("lastActive", String(d1))
    })

    return (
        <Sentry.ErrorBoundary>
            <BrowserRouter>
                <QueryClientProvider client={queryClient}>
                    <GlobalContextProvider>
                        <Router />
                        {
                            idleWarning && !logout && (<IdleWarningModal showCloseModal={false} closeModal={() => setIdleWarning(false)} />)
                        }
                        {
                            logout && !idleWarning && (<IdleLogoutModal showCloseModal={false} closeModal={() => setLogout(false)} />)
                        }
                    </GlobalContextProvider>
                </QueryClientProvider>
            </BrowserRouter>
        </Sentry.ErrorBoundary>
    )
}
