export const formatBytes = (bytes: number, decimals = 2) => {
    if (bytes === 0) return "0 Bytes"

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
}

export const isEnumValue = (v: unknown, e: object) => Object.values(e).some(_v => _v === v)
export const isValidDateValue = (x: never) => !isNaN(new Date(x).getTime())

export const nicknameToWorkspaceTitle = (nickname: string) => {
    const possessive = nickname.slice(-1) === "s" ? nickname : nickname + "s"
    return __("misc.other_workspace", { name: possessive })
}

export const copyToClipboard = async (value: string) => {
    try {
        await navigator.clipboard?.writeText(value)
    } catch {
        const input = document.createElement("input")
        input.value = value
        input.style.position = "absolute"
        input.style.top = "-1000px"
        document.body.append(input)
        input.select()
        input.focus()
        const success = document.execCommand("copy")

        if (!success) {
            console.error("Failed to copy content to the Clipboard.")
        }
    }
}
