import React from "react"
import { useDebouncedState } from "@/utilities/useDebouncedState"
import { icons } from "@/config/icons"

interface SearchBoxProps {
    queryHandler: (query: string) => void
    placeholder?: string
    debounceDuration?: number
}

const SearchInput: React.FC<SearchBoxProps> = ({ placeholder, queryHandler, debounceDuration = 0 }) => {
    const [query, setQuery] = React.useState("")
    const debouncedQuery = useDebouncedState(query, debounceDuration)

    React.useEffect(() => queryHandler(debouncedQuery), [debouncedQuery])

    const handleKeyDown = (e: React.KeyboardEvent) => {
        if (e.key !== "Escape") return

        queryHandler("")
        setQuery("")
    }

    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!e.target.value) queryHandler("")
        setQuery(e.target.value)
    }

    return (
        <div className="input__wrapper">
            <div className="input__wrapper--inner search-input-wrapper">
                <input
                    className="input input--has--icon"
                    placeholder={placeholder || __("common.dictionary.search")}
                    value={query}
                    onKeyDown={handleKeyDown}
                    onChange={handleOnChange} />
                <div className="input__wrapper__icon input__wrapper__icon--left">{icons["search"]}</div>
                {query && (
                    <button
                        className="input__wrapper__icon input__wrapper__icon--right"
                        onClick={() => setQuery("")}>
                        {icons["close"]}
                    </button>
                )}
            </div>
        </div>

    )
}

export default SearchInput
