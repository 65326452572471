import React, { useState } from "react"
import { useGetUserQuery } from "@/api/user"
import CircleCheck from "resources/assets/svgs/circle-check.svg"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import ArrowForwardIcon from "@mui/icons-material/ArrowForward"
import NumberCircle from "../NumberCircle"

const Walkthrough = () => {
    const { data: user } = useGetUserQuery()

    const hebSteps = [
        {
            title: "Bekanta dig med Stegvisa hjälpen",
            text: "Börja utforska de olika verktygen och resurserna som nu finns tillgängliga för dig",
        },
        {
            title: "Se över anhörig-möjligheten",
            text: "Bjud in anhöriga till arbetsytan för att gemensamt se den avlidnes information och chatta via vår chatt.",
        },
        {
            title: "Utforska digitala valvet",
            text: "Förenkla för dina närstående i framtiden genom att lagra dina viktiga dokument och foton i Digitala valvet.",
        }
    ]

    const [currentStep, setCurrentStep] = useState(0)
    const canGoBack = currentStep > 0
    const canGoForward = currentStep < hebSteps.length - 1
    const [closed, setClosed] = useState(() => {
        const savedState = localStorage.getItem("walkthrough-HEB-closed")
        return savedState !== null ? JSON.parse(savedState) : false
    })

    const updateClosedState = (newClosedState: boolean) => {
        localStorage.setItem("walkthrough-HEB-closed", JSON.stringify(newClosedState))
        setClosed(newClosedState)
    }

    return (
        !closed ? (

            <div className={`walkthrough-container ${closed ? "closed" : ""}`}>
                <div className="walkthrough-left-box">
                    <div className="walkthrough-intro">
                        <h3>Hej {user ? user.nickname : ""}!</h3>
                        <p>Vi ska hjälpa dig att komma igång, för att du ska kunna fokusera på det som spelar roll; minnen, viktiga handlingar och mycket mer.</p>
                    </div>
                    <div className="walkthrough-list">
                        <ul>
                            {hebSteps.map((item, index) => (
                                <li className="" key={index}>
                                    <svg className={`walkthrough-outro-icon ${currentStep !== index ? "inactive" : ""}`}>
                                        <NumberCircle currentStep={index + 1} width={17} height={17} />
                                    </svg>
                                    <span className={`walkthrough-outro-text ${currentStep !== index ? "inactive" : ""}`}>{item.title}</span>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className="walkthrough-outro">
                        <CircleCheck className="walkthrough-outro-icon" width={17} height={17} />
                        <span className="walkthrough-outro-text">
                            <b>Klar med alla steg?</b> Då kan du{" "}
                            <button className="walkthrough-outro-button" onClick={() => updateClosedState(!closed)}>
                                stänga guiden.
                            </button>{" "}
                            Den nås igen i övre högra hörnet.
                        </span>
                    </div>
                </div>
                <div className="walkthrough-right-box">
                    <div className="walkthrough-right-box-upper">
                        <NumberCircle currentStep={currentStep + 1} width={46} height={46} />
                        <h3>{hebSteps[currentStep].title}</h3>
                        <p>{hebSteps[currentStep].text}</p>
                    </div>
                    <div className="walkthrough-right-box-inner">
                        <div className="walkthrough-right-box-arrows">
                            <button className={!canGoBack ? "inactive" : ""} onClick={() => setCurrentStep(currentStep - 1)} disabled={!canGoBack}>
                                <ArrowBackIcon />
                            </button>
                            <button className={!canGoForward ? "inactive" : ""} onClick={() => setCurrentStep(currentStep + 1)} disabled={!canGoForward}>
                                <ArrowForwardIcon />
                            </button>
                        </div>

                    </div>

                </div>
            </div>

        )
            : (
                <div className={`start-guide ${!closed ? "closed" : ""}`}>
                    <button onClick={() => updateClosedState(!closed)}>
                        <u><b>STARTA KOM-IGÅNG-HJÄLPEN</b></u>
                    </button>
                </div>
            )

    )
}

export default Walkthrough