import React from "react"
import ProfilePicture from "@/components/ProfilePicture"
import SettingsIcon from "@mui/icons-material/Settings"
import { Link } from "react-router-dom"
import ExitToAppIcon from "@mui/icons-material/ExitToApp"
import { routes } from "@/config/routes"
import WorkspaceSelectorButton from "@/components/WorkspaceButton"
import Modal, { IModalProps, ModalBody } from "@/components/Modal"
import { nicknameToWorkspaceTitle } from "@/utilities"
import { WorkspaceResource } from "@/types/models/User"
import { useCurrentWorkspace, useGetUserQuery, useIsProactiveWorkspace, useSetCurrentWorkspaceMutation, useWorkspaces } from "@/api/user"
import { useLogoutMutation } from "@/api/auth"
import { GA } from "@/utilities/googleAnalytics"
import RoundUser from "../../../../resources/assets/svgs/icon_round_user.svg"
import AddSign from "../../../../resources/assets/svgs/add_sign.svg"

const ProfileMenuModal: React.FC<IModalProps> = (props) => {
    const logout = useLogoutMutation()
    const workspaces = useWorkspaces()
    const workspace = useCurrentWorkspace()
    const currentWorkspace = useCurrentWorkspace()
    const setCurrentWorkspaceMutation = useSetCurrentWorkspaceMutation()
    const isProactiveWorkspace = useIsProactiveWorkspace()
    const { data: user } = useGetUserQuery()

    const vault = workspaces && !workspace?.has_sole_actor && workspace?.deceased_user_id !== (user && user.id)

    const hasProactiveWorkspace = workspaces.some(workspace => workspace.deceased_user_id === user?.id)

    const sortOwnWorkspaceFirst = React.useCallback(
        (workspace: WorkspaceResource) => user && workspace.deceased_user_id === user.id ? -1 : 1,
        [user]
    )

    const handleLogout = async () => {
        GA("button",isProactiveWorkspace ? "HEB: Log out": "DV: Log out", "Clicked the button" )
        logout.mutate()
    }

    const changeWorkspace = (workspaceId: number, isDecised:boolean) => {
        setCurrentWorkspaceMutation.mutate(workspaceId)
        GA("button",isDecised ? "DV: Connected workspace": "HEB: My workspace", "Clicked the button")
        props.closeModal()
    }

    return (
        <Modal {...props}>
            <div className="profile-menu">
                <div className="profile-menu__header">
                    <div className="profile-menu__profile">
                        <ProfilePicture src={user?.profile_picture_url || null} size="large" isVault={vault} />
                        <div>
                            <div className="profile-menu__name">{user?.nickname} {user?.last_name}</div>
                        </div>
                    </div>
                    <div className="profile-menu__account-actions">
                        <Link to={routes.account} onClick={() => GA("button",isProactiveWorkspace ? "DV: Account settings" : "HEB: Account settings", "Clicked the button" )}>
                            <SettingsIcon />
                            {__("views.account_management.title")}
                        </Link>
                        <button onClick={handleLogout}>
                            <ExitToAppIcon />
                            {__("common.dictionary.logout")}
                        </button>
                    </div>
                </div>
                <ModalBody>
                    <div className="workspace-list mb-8">
                        <div className="workspace-list__title">
                            {__("views.workspaces.title")}:
                        </div>
                        {workspaces
                            ?.sort(sortOwnWorkspaceFirst)
                            .map(workspace => {
                                const workspaceTitle = workspace.deceased_user_id === user?.id
                                    ? __("misc.my_workspace")
                                    : nicknameToWorkspaceTitle(workspace.nickname)

                                return (
                                    <WorkspaceSelectorButton
                                        key={workspace.relation_id}
                                        selected={workspace.relation_id === currentWorkspace?.relation_id}
                                        profilePictureSrc={workspace.profile_picture_url}
                                        workspaceTitle={workspaceTitle}
                                        notMine={workspace.deceased_user_id === user?.id}
                                        onClick={() => changeWorkspace(workspace.relation_id, workspace.is_deceased)} />
                                )
                            })}
                    </div>
                    <div className="grid gap-2">
                        {!hasProactiveWorkspace && (
                            <Link
                                className="font-primary-500"
                                to={routes.registration.proactive}
                                onClick={() => GA("button", "HEB : Create additional account", "Clicked the button")}>
                                {__("onboarding.registration.proactive.prompt")}
                            </Link>
                        )}
                        <Link className="white-workspace-button" to={routes.registration.reactive} onClick={() => GA("button", "DV : Create additional account", "Clicked the button")}>
                            <RoundUser height={36} width={36} />Skapa ytterligare arbetsyta för anhörigs bortgång. <AddSign height={12} width={12} className="white-workspace-button__icon" />
                        </Link>
                    </div>
                </ModalBody>
            </div>
        </Modal>
    )
}

export default ProfileMenuModal
