import { useGuideResponse } from "@/api/guide"

/**
 * Property path string passed is expected to be in dot notation. Ex.
 * "property-inventory."
 *
 * @param propertyPath
 */
export const useGuideResponseValue = (propertyPath: string): unknown => {
    const responseQuery = useGuideResponse()
    if (!responseQuery.data) return

    return propertyPath
        .split(".")
        .reduce((acc, property) => acc?.[property], responseQuery.data)
}
