import React from "react"
import { useHistory } from "react-router-dom"
import ArrowBack from "@mui/icons-material/ArrowBack"
import Button from "@/components/Button"

const Backlink = () => {
    const history = useHistory()

    return (
        <Button
            size="small"
            className="onboarding__backlink button--rectangle button--white"
            onClick={() => history.goBack()}>
            <ArrowBack /> {__("common.dictionary.back")}
        </Button>
    )
}

export default Backlink
