import React from "react"
import { InputProps } from "@/components/Inputs/TextInput"
import classnames from "classnames"

type CheckboxProps = Omit<InputProps, "Icon" | "placeholder" | "error" | "value" >

const Checkbox: React.FC<CheckboxProps> = ({ label, ...props }) => {
    const classes = classnames("checkbox-radio checkbox-radio__label", {
        "checkbox-radio__label--disabled": props.disabled,
    })

    return (
        <label className={classes}>
            <input
                className="checkbox-radio__input"
                type="checkbox"
                {...props} />
            <span className="checkbox-radio__custom-checkbox" />
            <span className="checkbox-radio__label-text">{label}</span>
        </label>
    )
}

export default Checkbox
