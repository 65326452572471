import React from "react"
import {
    ComponentInputType,
    ComponentType,
    GuideComponent,
    GuideStep,
    IInputComponent,
    StepType
} from "@/components/Guide/types"
import ChecklistStep from "@/components/Guide/Steps/ChecklistStep"
import ConfirmationStep from "@/components/Guide/Steps/ConfirmationStep"
import FormStep from "@/components/Guide/Steps/FormStep"
import InformationStep from "@/components/Guide/Steps/InformationStep"
import Select from "@/components/Guide/Components/Select"
import { TextInput } from "@/components/Inputs/TextInput"
import Repeater from "@/components/Guide/Components/Repeater"
import Checkbox from "@/components/Inputs/Checkbox"
import { TextAreaInput } from "@/components/Inputs/TextAreaInput"
import { NumericTextInput } from "@/components/Inputs/NumericTextInput"
import RadioGroup from "@/components/Guide/Components/RadioGroup"
import { DateInput } from "@/components/Inputs/DateInput"
import { useGuideResponseValue } from "@/contexts/GuideContext/utils"
import parseHtml, { attributesToProps, DOMNode, domToReact, Element } from "html-react-parser"

export const GuideInputComponent = (props: IInputComponent) => {
    const value = useGuideResponseValue(props.id)
    const forwardedProps = React.useMemo(() => ({
        name: props.id,
        label: props.label,
        type: props.subtype,
        required: props.required,
        defaultValue: value as string | number | undefined,
        defaultChecked: props.subtype === ComponentInputType.Checkbox && !!value,
    }), [value])

    const Component = React.useMemo(() => getComponentElement(props.subtype), [props.subtype])

    return <Component {...forwardedProps} />
}

const getComponentElement = (type: ComponentInputType) => {
    switch (type) {
    case ComponentInputType.Checkbox:
        return Checkbox
    case ComponentInputType.TextArea:
        return TextAreaInput
    case ComponentInputType.Number:
        return NumericTextInput
    case ComponentInputType.Date:
        return DateInput
    default:
        return TextInput
    }
}

export const renderGuideComponent = (component: GuideComponent) => {
    switch (component.type) {
    case ComponentType.RadioGroup:
        return <RadioGroup {...component} key={component.id} />
    case ComponentType.Repeater:
        return <Repeater {...component} key={component.id} />
    case ComponentType.Select:
        return <Select {...component} key={component.id} />
    default:
        return <GuideInputComponent {...component} key={component.id} />
    }
}

export const renderStep = (step: GuideStep) => {
    switch (step.type) {
    case StepType.Checklist:
        return <ChecklistStep {...step} key={step.id} />
    case StepType.Confirmation:
        return <ConfirmationStep {...step} key={step.id} />
    case StepType.Form:
        return <FormStep {...step} key={step.id} />
    case StepType.Information:
        return <InformationStep {...step} key={step.id} />
    }
}

export const renderHtml = (html:string) =>{
    const htmlOptions = {
        replace: (domNode:DOMNode) => {
            if (domNode instanceof Element && domNode.tagName === "a" && domNode.attribs && !domNode.attribs["target"]) {
                const props = attributesToProps(domNode.attribs)
                props["target"] = "_blank"
                return <a {...props}>{domToReact(domNode.children, htmlOptions)}</a>
            }

            return null
        },
    }

    return parseHtml(html, htmlOptions)
}
