import React, { useState } from "react"
import Modal, { IModalProps } from "../Modal"
import ReactPlayer from "react-player"
import { PlayArrow, Pause, ArrowForward } from "@mui/icons-material"
import { routes } from "@/config/routes"






const HebIntroModal: React.FC<IModalProps> = ({ ...modalProps }) => {
    const [shouldPlay, setShouldPlay] = useState(true)
    const width = screen.width


    return (
        <Modal {...modalProps }>

            <div className="heb-intro-modal-section">
                <div className="small-gap">
                    <h1>Introduktion till Hantera en bortgång</h1>
                    <p className="heb-intro-modal-text">
                    Checklista som guidar dig genom allt det som du behöver göra när en närstående har gått bort.
                    </p>
                </div>
                <div className="heb-player-wrapper">

                    <ReactPlayer
                        playsInline
                        autoPlay
                        url="https://fernita.gatsby.qte.nu/wp-content/uploads/2023/09/Fernita_-_Hantera_en_bortgang_komprimerad.mp4"
                        playing={shouldPlay}
                        width=""
                        height="401px !important" />
                    <div className="player-controls">
                        <button
                            onClick={() => setShouldPlay(!shouldPlay)}
                            type="button"
                            aria-label="puse">
                            {shouldPlay ? <Pause /> : <PlayArrow />}
                        </button>
                    </div>
                </div>
                <div className="heb-intro-modal-button">
                    <a href={routes.registration.reactive} className="button button--rounded button--dark-green">{width > 500 ? "Skapa arbetsyta för Hantera en bortgång" : "Skapa arbetsyta" }<ArrowForward /></a>
                </div>
            </div>
        </Modal>
    )
}

export default HebIntroModal
