import React from "react"
import VaultServiceHeader from "@/components/Vault/VaultServiceHeader"
import { TextAreaInput } from "@/components/Inputs/TextAreaInput"
import SubmitButton from "@/components/SubmitButton"
import { FormActions } from "@/components/Modal"
import { useIsProactiveWorkspace } from "@/api/user"
import { useQuestionnaireResponse, useQuestionnaireResponseMutation } from "@/api/questionnaire"
import { GA } from "@/utilities/googleAnalytics"

type Questionnaire = Array<Array<Record<string, string>>>

const questionnaire: Questionnaire = [
    [
        { title: "Var föddes jag?", placeholder: "" },
        { title: "Var växte jag upp?", placeholder: "" },
        { title: "Vad hade jag för favoritleksak som liten?", placeholder: "" },
        { title: "Mitt första minne?", placeholder: "" },
        { title: "Vad hade jag för drömmar som liten?", placeholder: "" },
        { title: "Vem såg jag upp till som barn?", placeholder: "" },
        { title: "Vad ville jag bli som vuxen?", placeholder: "" },
        { title: "Mina sommarjobb?", placeholder: "" },
        { title: "Vilka utbildningar har jag gått?", placeholder: "" },
        { title: "Mitt första jobb?", placeholder: "" },
        { title: "Min karriär?", placeholder: "" },
        { title: "Mitt första egna boende?", placeholder: "" },
        { title: "Mina boenden?", placeholder: "" },
        { title: "Den bästa julen?", placeholder: "" },
        { title: "Mina bästa sommarminnen?", placeholder: "" },
        { title: "Mina stoltaste ögonblick?", placeholder: "" },
        { title: "Min favoritmusik/band?", placeholder: "" },
        { title: "Vilka är mina föräldrar?", placeholder: "" },
        { title: "Vilka är mina syskon?", placeholder: "" },
        { title: "Mina bästa semesterminnen?", placeholder: "" },
        { title: "Vad har jag haft för hobbys?", placeholder: "" },
        { title: "Mina största intressen?", placeholder: "" },
        { title: "Vilka bilar/fordon har jag haft?", placeholder: "" },
        { title: "Vilken idrott har jag utövat genom livet?", placeholder: "" },
        { title: "Min bästa vän/vänner?", placeholder: "" },
        { title: "Min första vän?", placeholder: "" },
        { title: "Vilket smeknamn har jag haft?", placeholder: "" },
        { title: "Vilka olika husdjur har jag haft?", placeholder: "" },
        { title: "Minnesvärda resor i livet?", placeholder: "" },
        { title: "Min favoritmat?", placeholder: "" },
        { title: "Vad har jag ångrat att jag inte gjorde?", placeholder: "" },
        { title: "Vad har jag ångrat att jag har gjort?", placeholder: "" },
        { title: "Vilka råd kan jag ge till framtiden? Någon eller några.", placeholder: "" }
    ],
    [
        { title: "Hur vill du bli begravd?", placeholder: "Här kan du skriva ner dina önskningar. Jord, kremering osv?" },
        { title: "Plats", placeholder: "Har du några önskemål på plats?" },
        { title: "Ceremonin", placeholder: "Borgerligt, Svenska kyrkan eller annat samfund?" },
        { title: "Musik", placeholder: "Vilken musik eller sång önskar du dig?" },
        { title: "Kläder", placeholder: "Önskemål på klädsel?" },
        { title: "Blommor", placeholder: "Vad gillar du?" },
        { title: "Minnesgåva", placeholder: "Vill du att minnesgåvor ska skänkas till en särskild förening eller organisation?" },
        { title: "Annons i tidningen", placeholder: "Vill du ha en dödsannons? Vilken tidning? Dikt, bild eller symbol i din annons?" },
        { title: "Minnesstund", placeholder: "Önskemål kring eventuell minnesstund." },
        { title: "Sten/minnesmärke", placeholder: "Om det är aktuellt. Önskemål om bild, text eller symbol." },
        { title: "Något jag vill få sagt", placeholder: "Kanske till någon speciell eller bara rent allmänt" },
        { title: "Fritext", placeholder: "" }
    ]
]

const AfterlifeServiceView = () => {
    const { data: response } = useQuestionnaireResponse()
    const questionnaireResponseMutation = useQuestionnaireResponseMutation()
    const formRef = React.useRef<HTMLFormElement>(null)
    const isProactiveWorkspace = useIsProactiveWorkspace()

    const save = async () => {
        if (!formRef.current || !isProactiveWorkspace) return

        const body: Record<string, string> = {}
        new FormData(formRef.current).forEach((value, key) => {
            if (typeof value === "string") body[key] = value
        })

        questionnaireResponseMutation.mutate(body)
        GA("button","DV: My after life: Save", "Clicked the button" )
    }

    const answeredQuestionsFilter = ({ title }: Record<string, string> ) => !!response?.[title]

    const firstSection = !isProactiveWorkspace
        ? questionnaire[0].filter(answeredQuestionsFilter)
        : questionnaire[0]

    const secondSection = !isProactiveWorkspace
        ? questionnaire[1].filter(answeredQuestionsFilter)
        : questionnaire[1]

    const shouldRenderFirstSection = !!firstSection.length
    const shouldRenderSecondSection = !!secondSection.length

    return (
        <div>
            <VaultServiceHeader translationKey="funeral" />
            {isProactiveWorkspace && (
                <FormActions>
                    <SubmitButton
                        className="mt-15"
                        isSubmitting={questionnaireResponseMutation.isLoading}
                        onClick={save}>
                        {__("common.dictionary.save")}
                    </SubmitButton>
                </FormActions>
            )}
            <form ref={formRef}>
                {shouldRenderFirstSection && (
                    <section className={!isProactiveWorkspace ? "prose" : ""}>
                        <h1 className="mb-5">{__("vault.funeral.first_section_title")}</h1>
                        {isProactiveWorkspace && <p className="mb-8">{__("vault.funeral.first_section_description")}</p>}
                        {firstSection.map(textfield => isProactiveWorkspace ? (
                            <TextAreaInput
                                key={textfield.title}
                                name={textfield.title}
                                defaultValue={response?.[textfield.title]}
                                label={textfield.title}
                                placeholder={textfield.placeholder} />
                        ) : (
                            <React.Fragment key={textfield.title}>
                                <h4>{textfield.title}</h4>
                                <p>{response?.[textfield.title]}</p>
                            </React.Fragment>
                        ))}
                    </section>
                )}
                {shouldRenderSecondSection && (
                    <section className={`mt-15 ${!isProactiveWorkspace ? "prose" : ""}`}>
                        <h1 className="mb-5">{__("vault.funeral.second_section_title")}</h1>
                        {isProactiveWorkspace && <p className="mb-8">{__("vault.funeral.second_section_description")}</p>}
                        {secondSection.map(textfield => isProactiveWorkspace ? (
                            <TextAreaInput
                                key={textfield.title}
                                name={textfield.title}
                                label={textfield.title}
                                defaultValue={response?.[textfield.title]}
                                placeholder={textfield.placeholder} />
                        ) : (
                            <React.Fragment key={textfield.title}>
                                <h4>{textfield.title}</h4>
                                <p>{response?.[textfield.title]}</p>
                            </React.Fragment>
                        ))}
                    </section>
                )}
            </form>
            {isProactiveWorkspace && (
                <FormActions>
                    <SubmitButton
                        className="mt-15"
                        isSubmitting={questionnaireResponseMutation.isLoading}
                        onClick={save}>
                        {__("common.dictionary.save")}
                    </SubmitButton>
                </FormActions>
            )}
        </div>
    )
}

export default AfterlifeServiceView
