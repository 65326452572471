import classNames from "classnames"
import React from "react"

interface ITextAreaInputProps extends React.ComponentProps<"textarea"> {
    error?: string
    label?: string
}

export const TextAreaInput: React.FC<ITextAreaInputProps> = ({ error, label, ...props }) => {

    const inputClassNames = classNames("input input--textarea", {
        "input--has--error": !!error,
    })

    const labelClassNames = classNames("input__label input__label--textarea", {
        "input__label--error": !!error,
    })

    return (
        <div className="input__wrapper input__wrapper--textarea">
            {label && <label className={labelClassNames}>{label}</label>}
            <textarea
                {...props}
                className={inputClassNames}
                rows={6} cols={30} />
            {error && <span className="input__wrapper--error">{error}</span>}
        </div>
    )
}
