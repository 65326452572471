import React from "react"
import { Info } from "@mui/icons-material"
import Modal, { ModalBody } from "@/components/Modal"

const MoreInfo: React.FC = ({ children }) => {
    const [open, setOpen] = React.useState(false)

    return (
        <React.Fragment>
            <button
                type="button"
                className="more-info"
                onClick={() => setOpen(true)}
                title={__("misc.more_info_title_attr")}>
                <span>{__("misc.more_info")}</span> <Info />
            </button>
            {open && (
                <Modal closeModal={() => setOpen(false)}>
                    <ModalBody>
                        {children}
                    </ModalBody>
                </Modal>
            )}
        </React.Fragment>
    )
}

export default MoreInfo
