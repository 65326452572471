import React from "react"
import Modal, { FormActions, ModalBody, ModalFooter, ModalHeader } from "../Modal"
import Personnummer from "personnummer"
import { Relative, RelativeForm, useCreateRelative, useDeleteRelative, useGetRelatives, usePatchRelative } from "@/api/relatives"
import SubmitButton from "@/components/SubmitButton"
import Button from "@/components/Button"
import { TextInput } from "@/components/Inputs/TextInput"
import { NumericTextInput } from "@/components/Inputs/NumericTextInput"
import Heading from "@/components/Heading"
import { useConfirm } from "@/contexts/ConfirmContext/hooks"
import { useCurrentWorkspace } from "@/api/user"
import { AxiosError } from "axios"
import { captureException } from "@sentry/react"
import { useNotify } from "@/contexts/NotificationsContext"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import { NotificationStatus, NotificationType } from "@/contexts/NotificationsContext/types"

interface IRelativeModalProps {
    relative: Relative | RelativeForm
    closeModal: () => void
}

// TODO Move type utilities to own file and give them a second look
type Form = Record<string, unknown>
type MapValidationErrors<FormValues = Form> = Partial<Record<keyof FormValues, string[]>>

interface ServerValidationErrorResponseData<FormValues = Form> {
    message: string
    errors: MapValidationErrors<FormValues>
}

type ServerValidationError<FormValues = Form> = AxiosError<ServerValidationErrorResponseData<FormValues>>
type RelativeFormValidationErrors = MapValidationErrors<RelativeForm>

const RelativeModal: React.FC<IRelativeModalProps> = ({ relative, closeModal }) => {
    const { current: isNewRelative } = React.useRef(!relative.ssn)
    const [form, setForm] = React.useState(relative)
    const notify = useNotify()
    const [formErrors, setFormErrors] = React.useState<RelativeFormValidationErrors>({})
    const updateMutation = usePatchRelative()
    const deleteMutation = useDeleteRelative()
    const createMutation = useCreateRelative()
    const confirm = useConfirm()
    const currentWorkspace = useCurrentWorkspace()
    const { data: relatives } = useGetRelatives()
    const invites = relatives?.filter(relative => relative.type === "invite") || []

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setForm({ ...form, [e.target.name]: e.target.value })
    }

    const handleDelete = async () => {
        if ("id" in relative) {
            const confirmed = await confirm(__("misc.confirmations.delete_item_specific", { name: relative.first_name }))

            if (confirmed) {
                deleteMutation.mutate(relative.id)
                closeModal()
            }
        }
    }

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault()

        if (!Personnummer.valid(form.ssn)) {
            setFormErrors(prevState => ({ ...prevState, ssn: [__("validation.custom.ssn.format") as string] }))
        }

        // Set personnummer to long format if short was provided
        const ssn = Personnummer.parse(form.ssn).format(true)

        try {
            if (isNewRelative) {
                await createMutation.mutateAsync({ ...form, ssn })
            } else {
                await updateMutation.mutateAsync({ ...form, ssn } as Relative)
            }

            closeModal()
            invites.length === 0 && notify({
                Content: <div className="toast__wrapper"><CheckCircleIcon className="font-primary-400" /><p>Din kontakt är nu inlagd. Bra jobbat!</p></div>,
                notificationId: "1",
                type: NotificationType.TOAST,
                status: NotificationStatus.SUCCESS,
            })
        } catch (error) {
            const e = error as ServerValidationError<RelativeForm>
            if (e.response?.data.errors) {
                setFormErrors(e.response.data.errors)
                return
            }

            captureException(error)
        }
    }

    const isSubmitting = updateMutation.isLoading || createMutation.isLoading

    let title
    if (isNewRelative) {
        title = __("misc.relative_title_create")
    } else {
        title = __("misc.relative_title_edit")
    }

    const canAdministerRelative = currentWorkspace?.is_admin && relative.type === "invite"

    return (
        <Modal closeModal={closeModal}>
            <ModalHeader>
                <Heading level={3} title={title} />
            </ModalHeader>
            <form onSubmit={handleSubmit}>
                <ModalBody>
                    <TextInput
                        error={formErrors.first_name?.[0]}
                        name="first_name"
                        label={__("common.dictionary.first_name")}
                        value={form.first_name}
                        disabled={!canAdministerRelative}
                        onChange={handleChange} />
                    <TextInput
                        error={formErrors.last_name?.[0]}
                        name="last_name"
                        label={__("common.dictionary.last_name")}
                        value={form.last_name}
                        disabled={!canAdministerRelative}
                        onChange={handleChange} />
                    <TextInput
                        error={formErrors.email?.[0]}
                        name="email"
                        label={__("common.dictionary.email")}
                        type="email"
                        value={form.email}
                        disabled={!canAdministerRelative}
                        onChange={handleChange} />
                    <NumericTextInput
                        error={formErrors.phone?.[0]}
                        name="phone"
                        label={__("common.dictionary.phone")}
                        type="tel"
                        value={form.phone}
                        disabled={!canAdministerRelative}
                        onChange={handleChange} />
                    <NumericTextInput
                        error={formErrors.ssn?.[0]}
                        name="ssn"
                        aria-placeholder={__("common.dictionary.ssn-format")}
                        label={__("common.dictionary.ssn")}
                        placeholder={__("common.dictionary.ssn-format")}
                        value={form.ssn}
                        disabled={!canAdministerRelative}
                        onChange={handleChange} />
                </ModalBody>
                <ModalFooter>
                    <FormActions>
                        <Button variant="secondary" type="button" onClick={closeModal}>
                            {__("common.dictionary.cancel")}
                        </Button>
                        {canAdministerRelative && (
                            <React.Fragment>
                                <SubmitButton
                                    type="button"
                                    variant="danger"
                                    isSubmitting={deleteMutation.isLoading}
                                    onClick={handleDelete}>
                                    {__("common.dictionary.remove")}
                                </SubmitButton>
                                <SubmitButton isSubmitting={isSubmitting}>
                                    {__("common.dictionary.save")}
                                </SubmitButton>
                            </React.Fragment>
                        )}
                    </FormActions>
                </ModalFooter>
            </form>
        </Modal>
    )
}

export default RelativeModal
