import React from "react"
import ArrowDropDown from "@mui/icons-material/ExpandMoreRounded"
import { useToggleState } from "@/utilities/useToggleState"
import classnames from "classnames"

interface IAccordionProps {
    title: string
    initiallyOpen?: boolean
}

const AccordionItem: React.FC<IAccordionProps> = ({ title, children }) => {
    const [isOpen, toggleContent] = useToggleState(false)
    const className = classnames("accordion-item", {
        "accordion-item--open": isOpen,
    })

    return (
        <div className={className}>
            <button className="accordion-item__button" onClick={() => toggleContent()}>
                {title} <ArrowDropDown className={classnames("accordion-item__icon")} />
            </button>
            {isOpen && <div className="accordion-item__content">{children}</div>}
        </div>
    )
}

export default AccordionItem
