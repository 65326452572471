import React, { useState } from "react"
import Button from "@/components/Button"
import RelativeModal from "../RelativeModal"
import Spinner from "../Spinner"
import AddIcon from "@mui/icons-material/Add"
import { useGetRelatives, Relative, RelativeForm } from "@/api/relatives"
import IconButton from "@/components/IconButton"
import { useCurrentWorkspace, useIsProactiveWorkspace } from "@/api/user"

const newRelative: RelativeForm = {
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    ssn: "",
    type: "invite",
}

const RelativeSection = () => {
    const [relative, setRelative] = useState<Relative | RelativeForm>()
    const workspace = useCurrentWorkspace()
    const { data: relatives, isFetching, isLoading } = useGetRelatives()
    const isProactiveWorkspace = useIsProactiveWorkspace()

    const workspaceState = isProactiveWorkspace ? "proactive" : "reactive"

    const users = relatives?.filter(relative => relative.type === "user") || []
    const invites = relatives?.filter(relative => relative.type === "invite") || []

    const className = isProactiveWorkspace ? "relative-section" : "dv-relative-section"

    return (
        <div className={className} id="relative-section">
            {relative && <RelativeModal relative={relative} closeModal={() => setRelative(undefined)} />}
            <h1>{__(`relative-section.${workspaceState}.title`)}</h1>
            <p className="mb-8">Dina kontaktpersoner kommer att få ett mail som talar om att du använder Fernitas tjänster och är utvalda av dig, och får tillgång till ditt innehåll när du har gått bort.</p>
            {isLoading && <Spinner />}
            {users && users.length > 0 && (
                <React.Fragment>
                    <h2>{__(`relative-section.${workspaceState}.users_title`)}</h2>
                    <div className="relatives-list mb-8">
                        {users.map((relative) => (
                            <Button className="gray-button--outlined" outlined key={relative.ssn} onClick={() => setRelative(relative)}>
                                {relative.first_name} {relative.last_name}
                            </Button>
                        ))}
                        {isFetching && <Spinner />}
                    </div>
                </React.Fragment>
            )}
            {!isLoading && (invites.length > 0 || workspace?.is_admin) && (
                <React.Fragment>
                    {!isProactiveWorkspace && <h2>{__(`relative-section.${workspaceState}.invites_title`)}</h2>}
                    <div className="relatives-list">
                        {invites.map((relative) => (
                            <Button outlined key={relative.ssn} onClick={() => setRelative(relative)}>
                                {relative.first_name} {relative.last_name}
                            </Button>
                        ))}
                        {/* {workspace?.is_admin &&*/} <IconButton className="button button--dark-green" icon={<AddIcon />} onClick={() => setRelative(newRelative)} />
                        {isFetching && <Spinner />}
                    </div>
                </React.Fragment>
            )}
        </div>
    )
}

export default RelativeSection
