import { UserResource } from "@/types/models/User"

interface IMessage {
    channel_id?: number
    created_at?: string
    id?: number
    message: string
    sent_by_user: number
    user: Pick<UserResource, "id" | "nickname" | "last_name" | "profile_picture_url">
    status: string
    temp_id?: string
}

interface IScrollRange {
    startIndex: number
    endIndex: number
}

interface IState {
    isFetchingPreviousMessages: boolean
    gotNewMessage: boolean
    nextQueryId?: number
    retrievedMessages: IMessage[]
    shouldScrollDown: boolean
    receivedNewMessage?: IMessage
    isSendingMessage: boolean
    userScrollingWhileReceivingMessage: boolean
    gotNewMessagesSinceLastLoggedIn: boolean
    lastReadMessageId?: number
    unseenMessagesAmount: number
    open: boolean
    initialized: boolean
    loading: boolean
    isUserScrolling: boolean
    range: IScrollRange
}

const initialState = {
    isFetchingPreviousMessages: false,
    gotNewMessage: false,
    nextQueryId: undefined,
    retrievedMessages: [],
    shouldScrollDown: false,
    receivedNewMessage: undefined,
    isSendingMessage: false,
    userScrollingWhileReceivingMessage: false,
    gotNewMessagesSinceLastLoggedIn: false,
    lastReadMessageId: 0,
    unseenMessagesAmount: 0,
    open: false,
    initialized: false,
    loading: true,
    isUserScrolling: false,
    range: { startIndex: 1, endIndex: 0 },
}

export {
    IState,
    initialState,
    IMessage,
    IScrollRange,
}
