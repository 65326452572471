import React from "react"
import {
    CredentialResource,
    CredentialCategory,
    CredentialFormData,
    CrudOperation
} from "@/components/Vault/Views/CredentialsServiceView/types"
import Button from "@/components/Button"
import AddBoxOutlined from "@mui/icons-material/AddBoxOutlined"
import SearchInput from "@/components/Inputs/SearchInput"
import VaultServiceHeader from "@/components/Vault/VaultServiceHeader"
import SmallCard from "@/components/SmallCard"
import CredentialFormModal from "@/components/Vault/Modals/CredentialFormModal"
import {
    defaultCredentialFields,
    predefinedCredentialCategories,
    predefinedReactiveCredentialCategories
} from "@/components/Vault/Views/CredentialsServiceView/config"
import { useGetCredential, useGetCredentials } from "@/api/credentials"
import Spinner from "@/components/Spinner"
import Placeholder from "@/components/Placeholder"
import MiscCategoryIcon from "resources/assets/svgs/category_misc.svg"
import { Search } from "@mui/icons-material"
import { useCurrentWorkspace, useIsProactiveWorkspace } from "@/api/user"
import { useVaultItemCount } from "@/api/vault"
import { GA } from "@/utilities/googleAnalytics"
//import { useVaultItemCount } from "@/api/vault"

const defaultFormData: CredentialFormData = {
    category_key: CredentialCategory.WebAccount,
    title: "",
    description: "",
    entries: defaultCredentialFields,
}

const CredentialsServiceView = () => {
    const [currentId, setCurrentId] = React.useState(0)
    const [credential, setCredential] = React.useState<CredentialFormData>(defaultFormData)
    const credentialsQuery = useGetCredentials()
    const credentialQuery = useGetCredential(currentId)
    const [filterQuery, setFilterQuery] = React.useState("")
    const [op, setOp] = React.useState<CrudOperation>()
    const isProactiveWorkspace = useIsProactiveWorkspace()


    const currentWorkspace = useCurrentWorkspace()
    const vaultItemCount = useVaultItemCount()
    const unableToUpload = currentWorkspace?.invalid_subscription && vaultItemCount >= 5


    React.useEffect(() => {
        if (!credentialQuery.data) return

        setOp(CrudOperation.Read)
        setCredential(credentialQuery.data)
    }, [credentialQuery.data])

    const openCreateCredentialModal = () => {
        setOp(CrudOperation.Create)
        setCredential(defaultFormData)
        GA("button",isProactiveWorkspace? "HEB: Password - Add" : "DV: Password - Add", "Clicked the button")
    }

    const openReadCredentialModal = (credential: CredentialResource) => {
        setCurrentId(credential.id)
    }

    const filteredCredentials = credentialsQuery.data?.filter(credential => {
        return credential.title.toLowerCase().includes(filterQuery.toLowerCase())
            || __(`vault.credentials.categories.${credential.category_key}`).toLowerCase().includes(filterQuery.toLowerCase())
    })

    console.log(filteredCredentials)

    return (
        <React.Fragment>
            <VaultServiceHeader translationKey="credentials" />
            <div className={isProactiveWorkspace ? "credentials-service-view__actions" : "credentials-service-view__actions-gray"}>
                <Button className={isProactiveWorkspace ? "button" : "button gray-button"} disabled={unableToUpload} onClick={openCreateCredentialModal}>
                    <AddBoxOutlined /> {__("vault.actions.add-credentials")}
                </Button>
                <SearchInput queryHandler={setFilterQuery} />
            </div>
            {Number(filteredCredentials?.length) > 0 && (
                <div className="credentials-service-view__grid">
                    {filteredCredentials
                        ?.sort((a, b) => a.title.localeCompare(b.title))
                        .map(credential => {
                            const { Icon } = isProactiveWorkspace ? predefinedCredentialCategories[credential.category_key] : predefinedReactiveCredentialCategories[credential.category_key]
                            return (
                                <div className="credentials-service-view__card-container" key={credential.id}>
                                    <SmallCard
                                        onClick={() => openReadCredentialModal(credential)}
                                        icon={<Icon />}
                                        title={credential.title}
                                        className={isProactiveWorkspace ? "small-card" : "small-gray-card"} />
                                    {currentId === credential.id && credentialQuery.isLoading && (
                                        <div className="credentials-service-view__spinner-wrapper">
                                            <Spinner />
                                        </div>
                                    )}
                                </div>
                            )
                        })}
                </div>
            )}
            {credentialsQuery.data?.length === 0 && (
                <Placeholder
                    title={__("vault.placeholder.no-credentials.title")}
                    description={__("vault.placeholder.no-credentials.description")}
                    icon={<MiscCategoryIcon />} />
            )}
            {filterQuery && !!credentialsQuery.data?.length && filteredCredentials?.length === 0 && (
                <Placeholder
                    title={__("vault.placeholder.no-result.title")}
                    description={__("vault.placeholder.no-result.description")}
                    icon={<Search />} />
            )}
            {op && (
                <CredentialFormModal
                    op={op}
                    setOp={setOp}
                    closeModal={() => {
                        setOp(undefined)
                        setCurrentId(0)
                        setCredential(defaultFormData)
                    }}
                    formData={credential}
                    setFormData={setCredential} />
            )}
        </React.Fragment>
    )
}

export default CredentialsServiceView
