import { CredentialResource, CredentialFormData } from "@/components/Vault/Views/CredentialsServiceView/types"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { VaultQuery } from "@/api/vault"

export enum CredentialsQuery {
    Create = "create-credential",
    ReadAll = "read-all-credentials",
    Read = "read-credential",
    Update = "update-credential",
    Delete = "delete-credential",
}

const createCredential = async (formData: CredentialFormData): Promise<CredentialResource> => {
    const { data } = await Axios.post("/api/credentials", formData)
    return data
}

export const useCreateCredential = () => {
    const queryClient = useQueryClient()

    return useMutation(
        (data: CredentialFormData) => createCredential(data),
        {
            onSuccess: (credential) => {
                queryClient.setQueryData(
                    CredentialsQuery.ReadAll,
                    (credentials) => (credentials as CredentialResource[] || []).concat(credential)
                )

                queryClient.invalidateQueries(CredentialsQuery.ReadAll)
                queryClient.invalidateQueries(VaultQuery.Folders)
            },
        }
    )
}

const getCredentials = async (): Promise<CredentialResource[]> => {
    const { data } = await Axios.get("/api/credentials")
    return data
}

export const useGetCredentials = () => {
    return useQuery(
        CredentialsQuery.ReadAll,
        getCredentials,
        {
            refetchOnWindowFocus: false,
            staleTime: Infinity,
        }
    )
}

const getCredentialById = async (id: number | string): Promise<CredentialResource> => {
    const { data } = await Axios.get("/api/credentials/" + id)
    return data
}

export const useGetCredential = (id: number | string) => {
    return useQuery(
        [CredentialsQuery.Read, id],
        () => getCredentialById(id),
        {
            refetchOnWindowFocus: false,
            staleTime: Infinity,
            enabled: Boolean(id),
        }
    )
}

const deleteCredential = async (id: number | string): Promise<void> => {
    const { data } = await Axios.delete("/api/credentials/" + id)
    return data
}

export const useDeleteCredential = () => {
    const queryClient = useQueryClient()

    return useMutation(
        deleteCredential,
        {
            onSuccess: (_, id) => {
                queryClient.setQueryData(
                    CredentialsQuery.ReadAll,
                    (credentials) => (credentials as CredentialResource[] || []).filter(credential => credential.id !== id)
                )

                queryClient.invalidateQueries(CredentialsQuery.ReadAll)
                queryClient.invalidateQueries(VaultQuery.Folders)
            },
        }
    )
}

const updateCredential = async (credential: CredentialResource): Promise<CredentialResource> => {
    const { data } = await Axios.put("/api/credentials/" + credential.id, credential)
    return data
}

export const useUpdateCredential = () => {
    const queryClient = useQueryClient()

    return useMutation(
        updateCredential,
        {
            onSuccess: (updatedCredential: CredentialResource) => {
                queryClient.setQueryData(
                    [CredentialsQuery.Read, updatedCredential.id],
                    updatedCredential
                )

                queryClient.setQueryData(
                    CredentialsQuery.ReadAll,
                    (credentials) => (credentials as CredentialResource[] || []).map(credential => credential.id === updatedCredential.id ? updatedCredential : credential)
                )

                queryClient.invalidateQueries(CredentialsQuery.ReadAll)
            },
        }
    )
}
