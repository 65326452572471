import React from "react"
import Button, { IButtonProps } from "@/components/Button"
import Spinner from "@/components/Spinner"
import classNames from "classnames"

interface ISubmitButtonProps extends IButtonProps {
    isSubmitting: boolean
    type?: "button" | "submit"
}

const SubmitButton: React.FC<ISubmitButtonProps> = ({ type = "submit", isSubmitting, disabled, children, className, ...props }) => {
    const classes = classNames(className, "button--submit", {
        "button--is-submitting": isSubmitting,
    })

    return (
        <Button {...props} type={type} disabled={isSubmitting || disabled} className={classes}>
            {isSubmitting && <Spinner />}
            {children}
        </Button>
    )
}

export default SubmitButton
