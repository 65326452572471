import React from "react"
import classnames from "classnames"

type ContainerProps = React.ComponentProps<"div">

const Container: React.FC<ContainerProps> = ({ children, className, ...props }) => {
    const classes = classnames("container", className, {})

    return (
        <div className={classes} {...props}>
            {children}
        </div>
    )

}

export default Container
