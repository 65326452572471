import React from "react"
import RelativeSection from "../RelativeSection"
import Support from "../Support"
import Statistics from "../Statistics"

const FullBenefitLayout = () => {
    return (
        <>
            <div className="walkthrough-title">
                <h1>
                    Få ut mer av ditt digitala bankvalv & Fernita
                </h1>
            </div>
            <div className="getMore-container">

                <RelativeSection />
                <Statistics />

            </div><Support />
        </>
    )
}

export default FullBenefitLayout