import React, { useEffect, useState } from "react"
import { useVaultItemCount } from "@/api/vault"
import { useCurrentWorkspace } from "@/api/user"
import ArrowForward from "@mui/icons-material/ArrowForward"
import { IVaultFolder, useVaultFolders } from "../../api/vault"

type VaultServiceTranslationKey =
  | "greetings"
  | "funeral"
  | "credentials"
  | "banking"
  | "insurances"
  | "properties"
  | "vehicles"
  | "other"
  | "generated-documents"
  | "templates"
  | null;

function getMostCommonTranslationKey(data: IVaultFolder[] | unknown): VaultServiceTranslationKey | null {
    if (!Array.isArray(data)) {
        return null
    }
    let maxFilesCount = -1
    let mostCommonTranslationKey: VaultServiceTranslationKey | null = null

    for (const item of data) {
        const { translation_key, files_count } = item

        if (files_count != null && translation_key) {
            if (files_count > maxFilesCount) {
                maxFilesCount = files_count
                mostCommonTranslationKey = translation_key
            }
        }
    }

    return mostCommonTranslationKey
}

const Statistics = () => {
    const vaultItemCount = useVaultItemCount()
    const currentWorkspace = useCurrentWorkspace()
    const vaultFolders = useVaultFolders()
    const [mostPopular, setMostPopular] = useState<VaultServiceTranslationKey>(null)
    useEffect(() => {
        const commonTranslationKey: VaultServiceTranslationKey = getMostCommonTranslationKey(vaultFolders.data)
        setMostPopular(commonTranslationKey || null)
    }, [vaultFolders])


    return (
        <div className="statistics">
            <h1>
                Statistik
            </h1>
            <div>
                Antal uppladdade filer: {vaultItemCount}
            </div>
            <div>
                Mest populära kategorin: {mostPopular ? __(`vault.${mostPopular}.title`) : ""}
            </div>
            <a href={currentWorkspace ? `/workspaces/${currentWorkspace.relation_id}/subscribe` : ""} className="button button--rounded button--dark-green">Hantera medlemskap <ArrowForward /></a>

        </div>
    )
}

export default Statistics