import React from "react"
import { v4 as uuid } from "uuid"
import { UserResource } from "@/types/models/User"
import { IApplicationContext } from "@/contexts/ApplicationContext/types"
import { applicationReducer, initialApplicationState } from "@/contexts/ApplicationContext/reducer"
import { initialiseAppAction, setExternalAuthDetailsAction } from "@/contexts/ApplicationContext/actions"
import Initialiser from "@/components/Initialiser"
import { useClearNotifications, useNotify } from "@/contexts/NotificationsContext"
import { NotificationType } from "@/contexts/NotificationsContext/types"
import { useQueryClient } from "react-query"
import { useCurrentWorkspace, UserQuery } from "@/api/user"

export const ApplicationContext = React.createContext<IApplicationContext | undefined>(undefined)

const ApplicationContextProvider: React.FC = ({ children }) => {
    const [state, dispatch] = React.useReducer(applicationReducer, initialApplicationState)
    const notify = useNotify()
    const resetNotifications = useClearNotifications()
    const queryClient = useQueryClient()
    const currentWorkspace = useCurrentWorkspace()

    React.useEffect(() => {
        Axios.interceptors.response.use(
            (response) => response,
            (error) => {
                if (error.response.status === 401) {
                    dispatch(setExternalAuthDetailsAction(undefined))
                }

                return Promise.reject(error)
            }
        )

        /**
         * INFO
         *
         * setTimeout here is required due to Safari on later versions (>15) drops socket connection when page hides.
         * We have a race condition between the redirect back to browser from BankID app and our queue worker getting
         * the successful status from BankID servers.
         *
         * To circumvent this we impose a delay to the application initialisation.
         */
        setTimeout(() => {
            void async function () {
                try {
                    const { data } = await Axios.get<UserResource>("/api/user")
                    queryClient.setQueryData<UserResource>(UserQuery.Get, data)
                    dispatch(setExternalAuthDetailsAction(undefined))
                    dispatch(initialiseAppAction())
                } catch {
                    dispatch(initialiseAppAction())
                }
            }()
        }, 1500)
    }, [])

    React.useEffect(() => {
        resetNotifications()

        currentWorkspace?.notifications.forEach(({ level, link, message }) => {
            notify({
                notificationId: uuid(),
                status: level,
                type: NotificationType.BAR,
                Content: <a href={link}>{message}</a>,
            })
        })
    }, [currentWorkspace])

    return (
        <ApplicationContext.Provider value={{ state, dispatch }}>
            {state.isInitialised ? children : <Initialiser />}
        </ApplicationContext.Provider>
    )
}

export default ApplicationContextProvider
