import React from "react"

const Prose: React.FC = ({ children }) => {
    return (
        <div className="prose prose--links-as-buttons">
            {children}
        </div>
    )
}

export default Prose
