import React from "react"
import { Link } from "react-router-dom"
import Section from "@/components/Section"
import Container from "@/components/Container"
import Card from "@/components/Card"
import GuideChapterSteppers from "@/components/Guide/GuideChapterSteppers"
import { routes } from "@/config/routes"
import { FormActions } from "@/components/Modal"
import { GA } from "@/utilities/googleAnalytics"

const GuideStatusSection = () => {
    const handleGeneratedDocumentClick = () => {
        GA("button", "HEB: Step by step help - to finished documents", "Clicked the button")
    }
    return (
        <Section className="bg-gray-75 polygon-section-gray">
            <Container>
                <Card>
                    <h1>{__("views.guide.common.title")}</h1>
                    <p className="mb-8">{__("views.guide.progress.description")}</p>
                    <GuideChapterSteppers />
                    <div className="mt-8">
                        <FormActions>
                            <Link className="font-gray-750 semi-bold" to={`${routes.vault}/${routes.vaultGeneratedDocuments}`} onClick={handleGeneratedDocumentClick}>
                                {__("vault.generated-documents.link")}
                            </Link>
                        </FormActions>
                    </div>
                </Card>
            </Container>
        </Section>
    )
}

export default GuideStatusSection
