import { useQuery } from "react-query"
import { ValueOf } from "@/utilities/types"

/**
 * This object maps vault service folder ids to their respective slug
 * so that we may acquire correct translations.
 */
export const vaultServiceIdTranslationKeyMap = {
    1: "greetings",
    2: "funeral",
    3: "credentials",
    4: "banking",
    5: "insurances",
    6: "properties",
    7: "vehicles",
    8: "other",
    9: "generated-documents",
    10: "templates",
} as const

export type VaultServiceId = keyof typeof vaultServiceIdTranslationKeyMap
export type VaultServiceTranslationKey = ValueOf<typeof vaultServiceIdTranslationKeyMap>

export enum VaultQuery {
    Folders = "vault-folders"
}

export interface IVaultFolder {
    id: number
    translation_key: VaultServiceTranslationKey
    files_count: number
}

const getVaultFolders = async (): Promise<IVaultFolder[]> => {
    const response = await Axios.get("/api/folders/vault")
    return response.data
}

export const useVaultFolders = () => {
    return useQuery(VaultQuery.Folders, getVaultFolders,
        {
            staleTime: Infinity,
            refetchOnWindowFocus: false,
        }
    )
}

export const useVaultItemCount = () => {
    const { data: folders } = useVaultFolders()
    return folders?.reduce((acc, cur) => acc + cur.files_count, 0) || 0
}
