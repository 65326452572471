import React from "react"
import { IChecklistStep } from "@/components/Guide/types"
import StepLayout from "@/components/Guide/Steps/StepLayout"
import Checkbox from "@/components/Inputs/Checkbox"
import { useGuideResponseValue } from "@/contexts/GuideContext/utils"
import { useGuideResponseMutation } from "@/api/guide"

const ChecklistStep: React.FC<IChecklistStep> = (props) => {
    const formRef = React.useRef<HTMLFormElement>(null)
    const guideResponseMutation = useGuideResponseMutation()
    const checklistValues = useGuideResponseValue(props.id)

    const handleChange: React.ChangeEventHandler<HTMLInputElement> = async () => {
        if (!formRef.current) return

        const formData = new FormData(formRef.current)
        const body: Record<string, string> = {}

        formData.forEach((value, key) => {
            if (typeof value === "string") body[key] = value
        })

        guideResponseMutation.mutate({ [props.id]: body })
    }

    return (
        <StepLayout {...props}>
            <form ref={formRef}>
                {props.entries.map(entry => (
                    <Checkbox
                        defaultChecked={!!(checklistValues && entry.id in (checklistValues as object))}
                        key={entry.id}
                        label={entry.label}
                        name={entry.id}
                        onChange={handleChange} />
                ))}
            </form>
        </StepLayout>
    )
}

export default ChecklistStep
