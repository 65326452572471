import { CredentialCategory, CredentialEntry } from "@/components/Vault/Views/CredentialsServiceView/types"
import WebAccountIcon from "resources/assets/svgs/category_web.svg"
import EmailIcon from "resources/assets/svgs/category_email.svg"
import PassportIcon from "resources/assets/svgs/category_id.svg"
import KeyIcon from "resources/assets/svgs/category_code.svg"
import BankingIcon from "resources/assets/svgs/category_banking.svg"
import InsuranceIcon from "resources/assets/svgs/category_insurance.svg"
import MembershipIcon from "resources/assets/svgs/category_membership.svg"
import LockIcon from "resources/assets/svgs/category_misc.svg"
import GrayWebAccountIcon from "resources/assets/svgs/category_gray_web.svg"
import GrayEmailIcon from "resources/assets/svgs/category_gray_email.svg"
import GrayPassportIcon from "resources/assets/svgs/category_gray_id.svg"
import GrayKeyIcon from "resources/assets/svgs/category_gray_code.svg"
import GrayBankingIcon from "resources/assets/svgs/category_gray_banking.svg"
import GrayInsuranceIcon from "resources/assets/svgs/category_gray_insurance.svg"
import GrayMembershipIcon from "resources/assets/svgs/category_gray_membership.svg"
import GrayLockIcon from "resources/assets/svgs/category_gray_misc.svg"

export const predefinedCredentialCategories = {
    [CredentialCategory.WebAccount]: {
        categoryKey: CredentialCategory.WebAccount,
        Icon: WebAccountIcon,
    },
    [CredentialCategory.Email]: {
        categoryKey: CredentialCategory.Email,
        Icon: EmailIcon,
    },
    [CredentialCategory.Passport]: {
        categoryKey: CredentialCategory.Passport,
        Icon: PassportIcon,
    },
    [CredentialCategory.Passcode]: {
        categoryKey: CredentialCategory.Passcode,
        Icon: KeyIcon,
    },
    [CredentialCategory.Banking]: {
        categoryKey: CredentialCategory.Banking,
        Icon: BankingIcon,
    },
    [CredentialCategory.Insurance]: {
        categoryKey: CredentialCategory.Insurance,
        Icon: InsuranceIcon,
    },
    [CredentialCategory.Membership]: {
        categoryKey: CredentialCategory.Membership,
        Icon: MembershipIcon,
    },
    [CredentialCategory.Other]: {
        categoryKey: CredentialCategory.Other,
        Icon: LockIcon,
    },
} as const

export const predefinedReactiveCredentialCategories = {
    [CredentialCategory.WebAccount]: {
        categoryKey: CredentialCategory.WebAccount,
        Icon: GrayWebAccountIcon,
    },
    [CredentialCategory.Email]: {
        categoryKey: CredentialCategory.Email,
        Icon: GrayEmailIcon,
    },
    [CredentialCategory.Passport]: {
        categoryKey: CredentialCategory.Passport,
        Icon: GrayPassportIcon,
    },
    [CredentialCategory.Passcode]: {
        categoryKey: CredentialCategory.Passcode,
        Icon: GrayKeyIcon,
    },
    [CredentialCategory.Banking]: {
        categoryKey: CredentialCategory.Banking,
        Icon: GrayBankingIcon,
    },
    [CredentialCategory.Insurance]: {
        categoryKey: CredentialCategory.Insurance,
        Icon: GrayInsuranceIcon,
    },
    [CredentialCategory.Membership]: {
        categoryKey: CredentialCategory.Membership,
        Icon: GrayMembershipIcon,
    },
    [CredentialCategory.Other]: {
        categoryKey: CredentialCategory.Other,
        Icon: GrayLockIcon,
    },
} as const

export const credentialOptions = Object.values(predefinedCredentialCategories).map(({ categoryKey }) => ({
    value: categoryKey,
    label: __(`vault.credentials.categories.${categoryKey}`),
}))

export const defaultCredentialFields: CredentialEntry[] = [
    {
        label: `${__("common.dictionary.username")} / ${__("common.dictionary.email")}`,
        value: "",
    },
    {
        label: __("common.dictionary.password"),
        value: "",
    }
]
