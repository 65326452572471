import { IFileResource } from "@/views/VaultServiceView/types"
import React from "react"
import CircularProgress from "@mui/material/CircularProgress"
import CheckCircle from "@mui/icons-material/CheckCircle"
import ErrorIcon from "@mui/icons-material/Error"
import parseHtml from "html-react-parser"

interface IUploadToastContentProps {
    file: File
    parentFolderId: number | string
    rootFolderId: number | string
    notificationId?: string
    handleUploadComplete: (data?: IFileResource, error?: Error) => void
}

interface IUploadError {
    uploadFailed: boolean
    message: string
}

const UploadToastContent: React.FC<IUploadToastContentProps> = ({
    file,
    parentFolderId,
    rootFolderId,
    handleUploadComplete,
}) => {
    const [progress, setProgress] = React.useState(0)
    const [error, setError] = React.useState<IUploadError>({ uploadFailed: false, message: "" })
    const [loading, setLoading] = React.useState(true)

    React.useEffect(() => {
        void async function () {
            const data = new FormData()
            data.append("file", file)
            data.append("parent_folder_id", parentFolderId.toString())
            data.append("root_folder_id", rootFolderId.toString())

            try {
                const response = await Axios.post<IFileResource>("/api/files", data, {
                    onUploadProgress: e => setProgress(Math.round(e.loaded * 100) / e.total),
                })

                if (response.status === 201) {
                    setLoading(false)
                }

                handleUploadComplete(response.data)
            } catch {
                // TODO: Add better errors for status codes 503 and 422 and file size?
                setLoading(false)
                setError({ message: __("common.errors.network.unknown"), uploadFailed: true })
                handleUploadComplete(undefined, new Error(__("common.errors.network.unknown")))
            }
        }()
    }, [])

    return (
        <div className="toast__wrapper">
            {progress < 100 || loading
                ? (
                    <CircularProgress
                        value={progress}
                        variant="determinate"
                        classes={{ circle: "font-primary-400" }}
                        size={28}
                        thickness={6} />
                )
                : error.uploadFailed ? <ErrorIcon className="font-danger-400" /> : <CheckCircle className="font-primary-400" />
            }
            {error.uploadFailed
                ? error.message
                : <p>{parseHtml(__("vault.confirmation.is-uploading", { name: file.name }))}</p>
            }
        </div>
    )
}

export default UploadToastContent
