import React from "react"
import { confirmContext } from "@/contexts/ConfirmContext"

export const useConfirm = () => {
    const context = React.useContext(confirmContext)

    if (!context) throw new Error("Cannot call useConfirm from outside a ConfirmContextProvider.")

    return context.confirm
}
