import React from "react"

import NotificationsBar from "@/components/NotificationBar"
import { useNotifications } from "@/contexts/NotificationsContext"
import { NotificationType } from "@/contexts/NotificationsContext/types"


const Notifications = () => {
    const notifications = useNotifications()

    const bars = notifications.filter(({ type }) => type === NotificationType.BAR)

    return (
        <div id="application-notifications">
            {bars.map(props => <NotificationsBar key={props.notificationId} {...props} />)}
        </div>
    )

}

export default Notifications
