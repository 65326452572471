import React from "react"
import classnames from "classnames"

import CloudUploadRounded from "@mui/icons-material/CloudUploadRounded"
import Spinner from "@/components/Spinner"

interface IDropUploadAreaProps extends React.ComponentProps<"div"> {
    overlayProps?: React.ComponentProps<"div">
    handleUpload: (files: File[]) => void
    isSubmitting?: boolean
}

enum DropUploadEnum {
    files = "Files"
}

const DropUploadArea: React.FC<IDropUploadAreaProps> = ({
    handleUpload,
    className,
    children,
    overlayProps,
    isSubmitting = false,
    ...props
}) => {
    const [isDraggingOver, setIsDraggingOver] = React.useState(false)

    /**
     * Needed in order to keep track of when cursor actually leaves
     * the drop area because enter and leave events are triggered for
     * every nested child element.
     */
    const nestedEventDepth = React.useRef(0)

    const checkForFiles = (files: DataTransfer) => {
        return files.types.some(type => type === DropUploadEnum.files)
    }

    const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
        const isFilesAvailable = checkForFiles(e.dataTransfer)
        if (!isFilesAvailable) return
        e.preventDefault()
        e.stopPropagation()

        nestedEventDepth.current++
        if (!isDraggingOver) setIsDraggingOver(true)
    }

    const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
        const isFilesAvailable = checkForFiles(e.dataTransfer)
        if (!isFilesAvailable) return
        e.preventDefault()
        e.stopPropagation()

        if (!--nestedEventDepth.current) setIsDraggingOver(false)
    }

    const cancelOpeningFileInBrowser = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault()
        e.stopPropagation()
    }

    const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
        const isFilesAvailable = checkForFiles(e.dataTransfer)
        if (!isFilesAvailable) return
        e.preventDefault()
        e.stopPropagation()

        nestedEventDepth.current--

        /* Filtering away folders for now, since you can't upload folders */
        const nonFolderFiles = Array.from(e.dataTransfer.files).filter(file => file.type !== "" && file.size%4096 !== 0)
        if (nonFolderFiles.length !== Array.from(e.dataTransfer.files).length) {
            // TODO: Trigger notification here that folders cant be uploaded
        }

        handleUpload(nonFolderFiles)
        setIsDraggingOver(false)
    }

    const classes = classnames("drop-upload-area", className, {
        "drop-upload-area--hovered": isDraggingOver,
    })

    const overlayClasses = classnames("drop-upload-area__overlay", overlayProps?.className)

    return (
        <div
            {...props}
            className={classes}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            onDragOver={cancelOpeningFileInBrowser}
            onDrop={handleDrop}>
            {children}
            {isDraggingOver && !isSubmitting && (
                <div {...overlayProps} className={overlayClasses}>
                    <p>{__("misc.dropzone")}</p>
                    <div><CloudUploadRounded /></div>
                </div>
            )}
            {isSubmitting && (
                <div {...overlayProps} className={overlayClasses}>
                    <Spinner />
                </div>
            )}
        </div>
    )

}


export default DropUploadArea
