import Modal, { ModalBody, ModalFooter, ModalHeader, IModalProps } from "@/components/Modal"
import React from "react"
import Button from "@/components/Button"

const IdleWarningModal:React.FC<IModalProps> = ({ ...modalProps }) => {

    const handleOnClick = () => {
        modalProps.closeModal()
        window.location.reload()
    }

    return (
        <Modal {...modalProps}>
            <ModalHeader>
            </ModalHeader>
            <ModalBody>
                Din session avslutas om 5 min, vill du fortsätta?
            </ModalBody>
            <ModalFooter>
                <Button type="button" onClick={handleOnClick}>
                    Fortsätt
                </Button>
            </ModalFooter>
        </Modal>
    )
}

export default IdleWarningModal