import React from "react"
import { IFormStep } from "@/components/Guide/types"
import StepLayout from "@/components/Guide/Steps/StepLayout"
import { renderGuideComponent, renderHtml } from "@/components/Guide/utils"
import SubmitButton from "@/components/SubmitButton"
import { FormActions } from "@/components/Modal"
import MoreInfo from "@/components/MoreInfo"
import Prose from "@/components/Prose"
import { useGuideResponseMutation } from "@/api/guide"
import Button from "@/components/Button"

const FormStep: React.FC<IFormStep> = (props) => {
    const formRef = React.useRef<HTMLFormElement>(null)
    const guideResponseMutation = useGuideResponseMutation()

    const saveFormData = async () => {
        if (!formRef.current) return

        const formData = new FormData(formRef.current)
        const body: Record<string, string | boolean> = {}

        formData.forEach((value, key) => {
            if (typeof value === "string" && value !== "others") body[key] = value
        })

        formRef.current.querySelectorAll("input[type=checkbox]").forEach((element) => {
            const checkbox = element as HTMLInputElement
            body[checkbox.name] = checkbox.checked
        })

        await guideResponseMutation.mutateAsync(body)
    }

    const downloadPDF = async () => {
        if (!formRef.current) return

        if (!formRef.current.reportValidity()) return

        await saveFormData()

        try {
            const { data } = await Axios.get<{ report_url: string }>(`/api/guide/reports/${props.pdf_export}/validate`)

            const tempLink = document.createElement("a")
            tempLink.href = data.report_url
            tempLink.download = props.title + ".pdf"
            tempLink.target = "_blank"
            tempLink.rel = "noreferrer"

            tempLink.click()
            tempLink.remove()
        } catch {
            // TODO Handle error
        }
    }

    return (
        <StepLayout {...props}>
            <form ref={formRef}>
                {props.sections.map(section => (
                    <section key={section.title} className="guide__form-section">
                        {section.title && (
                            <h2>
                                {section.title}
                                {section.more_info && (
                                    <MoreInfo>
                                        <Prose>
                                            {renderHtml(section.more_info)}
                                        </Prose>
                                    </MoreInfo>
                                )}
                            </h2>
                        )}
                        {section.components.map(component => React.cloneElement(
                            renderGuideComponent(component),
                            { name: `${props.id}.${component.id}`, id: `${props.id}.${component.id}` }
                        ))}
                    </section>
                ))}
                <FormActions>
                    {props.pdf_export && (
                        <Button onClick={downloadPDF} className="gray-button">
                            {__("views.guide.common.pdf_export")}
                        </Button>
                    )}
                    <SubmitButton className="gray-button" type="button" isSubmitting={guideResponseMutation.isLoading} onClick={saveFormData}>
                        {__("common.dictionary.save")}
                    </SubmitButton>
                </FormActions>
            </form>
        </StepLayout>
    )
}

export default FormStep
