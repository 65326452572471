import React from "react"
import Container from "@/components/Container/"
import Section from "@/components/Section/"
import Spinner from "@/components/Spinner"
import { ArrowForward } from "@mui/icons-material"
import { IVaultFolder, useVaultFolders, useVaultItemCount } from "@/api/vault"
import MatchMedia from "@/components/MatchMedia"
import MediumCard from "@/components/MediumCard"
import { grayIcons, icons } from "@/config/icons"
import SmallCard from "@/components/SmallCard"
import { useHistory } from "react-router-dom"
import { routes } from "@/config/routes"
import { useCurrentWorkspace, useIsProactiveWorkspace, useGetUserQuery } from "@/api/user"
import { useQuestionnaireResponse } from "@/api/questionnaire"
import { GA, GACards } from "@/utilities/googleAnalytics"

// TODO: turn into global function, and make it actually useful
const VaultSection = () => {
    const { data, isLoading } = useVaultFolders()
    const currentWorkspace = useCurrentWorkspace()
    const history = useHistory()
    const isProactiveWorkspace = useIsProactiveWorkspace()
    const vaultItemCount = useVaultItemCount()
    const { data: questionnaireResponse } = useQuestionnaireResponse()
    const { data: user } = useGetUserQuery()

    const goToVaultService = (folder: IVaultFolder) => {
        history.push(`${routes.vault}/${folder.translation_key}/${folder.id}`)
    }

    const handleTemplatesClick = () => {
        history.push(`${routes.vault}/${routes.vaultTemplates}`)
        GA("button", "DV: Free templates", "Clicked the button")
    }

    const handleAfterlifeClick = () => {
        history.push(`${routes.vault}/${routes.vaultAfterlife}`)
        GA("button", "DV: My after life", "Clicked the button")
    }

    const subscription = user && !user.current_workspace.invalid_subscription ? "Prenumeration" : "Prova på"
    const hasSubscription = !user?.current_workspace?.invalid_subscription ?? false

    const vaultServices = data?.sort(a => -(a.translation_key === "credentials")) || []

    const hasQuestionnaireResponse = Object.values(questionnaireResponse || {}).some(value => !!value)
    const shouldRenderAfterlife = isProactiveWorkspace || hasQuestionnaireResponse

    console.log(currentWorkspace)

    let description = null
    if (!isProactiveWorkspace) description = true

    const bgClassName = isProactiveWorkspace ? "section-bg-fade" : "dv-section-fade"
    const mediumCardClassName = isProactiveWorkspace ? "faux-button" : "gray-faux-button"
    const buttonClassname = isProactiveWorkspace ? "button button--rectangle button--dark-green" : "button gray-button"
    const smallCardClassName = isProactiveWorkspace ? "small-card" : "small-gray-card"
    const title = isProactiveWorkspace ? "Dina filer; ditt digitala bankvalv" : `${currentWorkspace ? currentWorkspace.nickname + "s" : ""} filer; det digitala bankvalvet`



    return (
        <div className={isProactiveWorkspace ? "polygon-section": ""}>
            <Section id="vault" className={bgClassName}>
                <Container>
                    <>
                        <header>
                            <h1>{title}</h1>
                            {!hasSubscription && isProactiveWorkspace && (
                                <a href={`/workspaces/${currentWorkspace?.relation_id}/subscribe`} onClick={() => GA("button", isProactiveWorkspace ? "HEB: Keep subscribing" : "DV: Keep subscribing", "Clicked the button")} className={buttonClassname}>
                                    {vaultItemCount <= 5 ? "Hantera medlemskap" : "Uppgradera prenumeration"} <ArrowForward />
                                </a>
                            )}
                        </header>
                        { isProactiveWorkspace && (
                            <header>
                                <p>
                                    {vaultItemCount} av 5 filer uppladdade
                                </p>
                                {!hasSubscription &&(
                                    <p>
                                    Nuvarande prenumeration: <b>{subscription}</b>
                                    </p>
                                )}
                            </header>
                        )}
                    </>
                    {description && <p>I Fernitas Digitala valv finns värdefulla dokument och livshändelser samlat. All som är inlagt här sen tidigare har du/ni nytta av i processen med Stegvisa hjälpen. Detta kan inte ändras eller tas bort. Det finns möjlighet att lägga till ytterligare saker som hittats eller behövs. Varför inte lägga upp minnesfoton eller gamla brev som alla kan ta del av?</p>}
                    {isLoading ? (
                        <Spinner />
                    ) : (
                        <div className="vault-section-cards">
                            <MatchMedia rule={MatchMedia.PhoneOnly}>
                                {vaultServices.map(folder => (
                                    <SmallCard
                                        key={folder.translation_key}
                                        onClick={() => { goToVaultService(folder); GACards(folder.translation_key) }}
                                        icon={isProactiveWorkspace ? icons[folder.translation_key] : grayIcons[folder.translation_key]}
                                        subtitle={__("misc.item_count", folder.files_count)}
                                        title={__(`vault.${folder.translation_key}.title`)}
                                        className={smallCardClassName} />
                                ))}
                                {isProactiveWorkspace && (
                                    <SmallCard
                                        onClick={handleTemplatesClick}
                                        icon={icons.templates}
                                        title={__("vault.templates.title")} />
                                )}
                                {shouldRenderAfterlife && (
                                    <SmallCard
                                        onClick={handleAfterlifeClick}
                                        icon={icons.funeral}
                                        title={__("vault.funeral.title")} />
                                )}
                            </MatchMedia>
                            <MatchMedia rule={MatchMedia.TabletAndAbove}>
                                {vaultServices.map((folder) => (
                                    <MediumCard
                                        key={folder.translation_key}
                                        onClick={() => { goToVaultService(folder); GACards(folder.translation_key) }}
                                        icon={isProactiveWorkspace ? icons[folder.translation_key] : grayIcons[folder.translation_key]}
                                        className={mediumCardClassName}
                                        title={__(`vault.${folder.translation_key}.title`)}
                                        subtitle={__("misc.item_count", folder.files_count)}
                                        label={__(`vault.${folder.translation_key}.link`)} />
                                ))}
                                {isProactiveWorkspace && (
                                    <MediumCard
                                        onClick={handleTemplatesClick}
                                        icon={icons.templates}
                                        className={mediumCardClassName}
                                        title={__("vault.templates.title")}
                                        label={__("vault.templates.link")} />
                                )}
                                {shouldRenderAfterlife && (
                                    <MediumCard
                                        onClick={handleAfterlifeClick}
                                        icon={icons.funeral}
                                        className={mediumCardClassName}
                                        title={__("vault.funeral.title")}
                                        label={__("vault.funeral.link")} />
                                )}
                            </MatchMedia>
                        </div>
                    )}
                </Container>
            </Section>

        </div>

    )
}

export default VaultSection
