import React from "react"
import { SortDirection, sortDirections, sortFunctions, SortType } from "@/config/sorting"
import { AccessorType, ITableColumn, ITableProps, ITableRow, ITableState } from "@/components/Table/types"
import { isValidDateValue } from "@/utilities"
import { useHistory } from "react-router-dom"


const Table: React.FC<ITableProps> = ({ columns, rows, firstRow, ...props }) => {
    const history = useHistory()
    const [state, setState] = React.useState<ITableState>({
        accessor: "" as AccessorType,
        sortType: SortType.NONE,
        sortDirection: SortDirection.NONE,
        sortDirectionIndex: 0,
    })

    const updateSort = React.useCallback((accessor: AccessorType) => {
        setState(prevState => {
            const sortDirectionIndex = prevState.accessor === accessor ? prevState.sortDirectionIndex + 1 : 1
            const sortDirection = sortDirections[sortDirectionIndex % sortDirections.length]
            const sortType = getOrderByTypeFromValue(rows[0]?.[accessor].sortValue)

            return { accessor, sortType, sortDirection, sortDirectionIndex }
        })
    }, [rows])

    const orderBy = (a: ITableRow, b: ITableRow) => {
        if (!state.accessor || state.sortType === SortType.NONE || state.sortDirection === SortDirection.NONE) return 0

        const sortFunction = sortFunctions[state.sortType][state.sortDirection]
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore @TODO If you can fix this ignored type error you win a prize!
        // @TODO: This is how we can solve it:
        // https://www.youtube.com/watch?v=dQw4w9WgXcQ
        return sortFunction(a[state.accessor].sortValue, b[state.accessor].sortValue)
    }

    return (
        <table {...props}>
            <thead>
                <tr>
                    {columns.map((props, index) => (
                        <TableHeader
                            key={props.accessor + "header" + index}
                            {...props}
                            updateSort={updateSort} />
                    ))}
                </tr>
            </thead>
            <tbody>
                {firstRow && (
                    <tr onClick={() => history.push(props.path)}>
                        {Object.values(firstRow).map((value, index) =>
                            <td key={index}>{value}</td>
                        )}
                    </tr>
                )}
                {[...rows].sort(orderBy).map(({ onClick, ...row }, rowIdx) => (
                    <tr key={"row" + rowIdx} onClick={onClick as unknown as React.MouseEventHandler}>
                        {Object.values(row).map(({ content }, cellIdx) =>
                            <td key={rowIdx + cellIdx}>{content}</td>
                        )}
                    </tr>
                ))}
            </tbody>
        </table>
    )
}

const getOrderByTypeFromValue = (value?: unknown): SortType => {
    if (isValidDateValue(value as never) && typeof value !== "number") return SortType.DATE

    switch (typeof value) {
    case "string":
        return SortType.STRING
    case "number":
        return SortType.NUMBER
    default:
        return SortType.NONE
    }
}


interface ITableHeaderProps extends ITableColumn {
    updateSort: (a: AccessorType) => void
}

const TableHeader: React.FC<ITableHeaderProps> = ({ updateSort, title, accessor }) => {

    return (
        <th>
            <button onClick={() => updateSort(accessor)}>{title}</button>
        </th>
    )
}

export default Table
