import React from "react"
import ReactSelect, { ActionMeta, Props as IReactSelectProps } from "react-select"
import classnames from "classnames"

export interface ISelectOption {
    value: string
    label: string
}

interface ISelectProps extends IReactSelectProps {
    error?: string
    label?: string
    required?: boolean
    options: ISelectOption[]
}

const Select: React.FC<ISelectProps> = ({ error, label, required, placeholder, ...props }) => {
    const [option, setOption] = React.useState<ISelectOption>()

    const handleChange = (option: unknown, actionMeta: ActionMeta<unknown>) => {
        const _option = option as ISelectOption
        setOption(_option)
        props.onChange?.(option, actionMeta)
    }

    const labelClassNames = classnames("input__label input__label--text", {
        "input__label--error": !!error,
    })

    const currentValue = option && option.value !== "others"
        ? option.value
        : "others"

    return (
        <div className="input__wrapper">
            {label && <label className={labelClassNames}>{label}</label>}
            <ReactSelect
                {...props}
                placeholder={placeholder || __("common.dictionary.pick") + "..."}
                onChange={handleChange}
                styles={{
                    placeholder: (provided) => ({
                        ...provided,
                        color: "var(--gray-900)",
                    }),
                    control: (provided, state) => ({
                        ...provided,
                        height: "3rem",
                        borderRadius: ".5rem",
                        borderColor: state.isFocused ? "var(--info-500)" : "var(--gray-500)",
                        boxShadow: state.isFocused ? "inset 0 2px 10px 0 hsla(206, 7%, 20%, 0.16)" : "0",
                        ":hover": {
                            borderColor: state.isFocused ? "var(--info-500)" : "var(--gray-500)",
                        },
                    }),
                }}
                theme={(theme) => ({
                    ...theme,
                    colors: {
                        ...theme.colors,
                        primary: "var(--primary-500)",
                        primary75: "var(--primary-400)",
                        primary50: "var(--primary-300)",
                        primary25: "var(--primary-200)",
                        danger: "var(--danger-500)",
                        dangerLight: "var(--danger-200)",
                        neutral90: "var(--gray-900)",
                        neutral80: "var(--gray-800)",
                        neutral70: "var(--gray-700)",
                        neutral60: "var(--gray-600)",
                        neutral50: "var(--gray-500)",
                        neutral40: "var(--gray-400)",
                        neutral30: "var(--gray-300)",
                        neutral20: "var(--gray-200)",
                        neutral10: "var(--gray-100)",
                    },
                })} />
            {!props.isDisabled && (
                <input
                    tabIndex={-1}
                    required={required}
                    onChange={() => null}
                    className="hidden-select-input"
                    value={currentValue} />
            )}
        </div>
    )
}

export default Select
