export const routes = {
    root: "/",
    overview: "/overview",
    login: "/login",
    registration: {
        proactive: "/register/proactive",
        reactive: "/register/reactive",
    },
    terms: "/terms",
    account: "/account",
    vault: "/vault",
    vaultCredentialsManager: "credentials/3",
    vaultAfterlife: "funeral/2",
    vaultGeneratedDocuments: "generated-documents/9",
    vaultTemplates: "templates/10",
    vaultFileSystem: ":serviceSlug/:serviceId/:folderSlug?/:folderId?",
    workspaces: "/workspaces",
    guide: "/guide/:chapterId",
} as const
