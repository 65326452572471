import React from "react"
import Modal, { FormActions, ModalBody, ModalFooter } from "@/components/Modal"
import Button from "@/components/Button"

interface ConfirmContext {
    confirm: (message: string) => Promise<boolean>
}

export const confirmContext = React.createContext<ConfirmContext | undefined>(undefined)

interface ConfirmState {
    message: string
    open: boolean
}

const initialState: ConfirmState = {
    message: "",
    open: false,
}

const ConfirmContextProvider: React.FC = ({ children }) => {
    const [{ open, message }, setState] = React.useState(initialState)
    const resolveRef = React.useRef<(value: boolean) => void>()

    const value: ConfirmContext = {
        confirm(message) {
            setState({ open: true, message })

            return new Promise((resolve) => {
                resolveRef.current = resolve
            })
        },
    }

    const close = () => {
        setState(initialState)
    }

    const handleAccept = () => {
        if (resolveRef.current) resolveRef.current(true)

        close()
    }

    const handleCancel = () => {
        if (resolveRef.current) resolveRef.current(false)

        close()
    }

    return (
        <confirmContext.Provider value={value}>
            {children}
            {open && (
                <Modal closeModal={close}>
                    <ModalBody>{message}</ModalBody>
                    <ModalFooter>
                        <FormActions>
                            <Button variant="primary" onClick={handleAccept}>{__("common.dictionary.ok")}</Button>
                            <Button variant="secondary" onClick={handleCancel}>{__("common.dictionary.cancel")}</Button>
                        </FormActions>
                    </ModalFooter>
                </Modal>
            )}
        </confirmContext.Provider>
    )
}

export default ConfirmContextProvider
