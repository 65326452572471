import React from "react"
import { SvgIconTypeMap } from "@mui/material"
import { OverridableComponent } from "@mui/material/OverridableComponent"
import classNames from "classnames"

export interface InputProps extends React.ComponentProps<"input"> {
    label?: string
    error?: string
    Icon?: OverridableComponent<SvgIconTypeMap<unknown, "svg">> & {
        muiName: string
    }
}

export const TextInput: React.FC<InputProps> = ({ error, Icon, label, ...props }) => {
    const inputClassNames = classNames("input input--text", { "input--has--icon": !!Icon },
        "input input--text", { "input--has--error": !!error })

    const labelClassNames = classNames("input__label input__label--text",
        { "input__label input__label--error": !!error })

    const iconClassNames = classNames("input__wrapper__icon",
        { "input__wrapper__icon--error": !!error })

    return (
        <div className="input__wrapper input__wrapper--text">
            {label && <label className={labelClassNames}>{label}</label>}
            <div className="input__wrapper--inner">
                <input {...props} className={inputClassNames} />
                {Icon && <Icon className={iconClassNames} />}
            </div>
            {error && <span className="input__wrapper--error">{error}</span>}
        </div>
    )
}
