import React from "react"
import { icons } from "@/config/icons"

interface SmallCardProps extends React.ComponentProps<"div"> {
    icon: JSX.Element
    title: string
    subtitle?: string
    onClick?: () => void
    disabled?: boolean
    className?: string
}

const SmallCard = ({ icon, title, subtitle, onClick, disabled = false, className = "small-card" }: SmallCardProps) => {
    const divProps: React.ComponentProps<"div"> = {}
    if (onClick) {
        divProps.onClick = onClick
        divProps.role = "button"
    }

    if (disabled) {
        divProps["aria-disabled"] = true
        divProps.tabIndex = -1
    }

    return (
        <div {...divProps} className={className}>
            {icon}
            <div>
                <div className="small-card__title">{title}</div>
                {subtitle && <div className="small-card__subtitle">{subtitle}</div>}
            </div>
            {icons.arrowForward}
        </div>
    )
}

export default SmallCard
