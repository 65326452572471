import { CredentialEntry } from "@/components/Vault/Views/CredentialsServiceView/types"
import React from "react"
import { Edit, RemoveCircleOutline } from "@mui/icons-material"
import { TextInput } from "@/components/Inputs/TextInput"

interface EditableCredentialEntry extends CredentialEntry {
    onLabelChange: (e: React.ChangeEvent<HTMLInputElement>) => void
    onValueChange: (e: React.ChangeEvent<HTMLInputElement>) => void
    onRemoveEntry: () => void
}

const EditableCredentialEntry = ({ label, value, onLabelChange, onRemoveEntry, onValueChange }: EditableCredentialEntry) => {
    return (
        <div className="credentials-entry__container">
            <div className="credentials-entry__inputs">
                <label>
                    <input
                        className="credentials-entry__label-input"
                        value={label}
                        onChange={onLabelChange}
                        maxLength={32}
                        required
                        size={label.length} />
                    <Edit />
                </label>
                <TextInput
                    value={value}
                    onChange={onValueChange}
                    required />
            </div>
            <button
                className="credentials-entry__delete-button"
                type="button"
                onClick={onRemoveEntry}>
                <RemoveCircleOutline />
            </button>
        </div>
    )
}

export default EditableCredentialEntry
