export enum SortDirection {
    NONE,
    ASCENDING,
    DESCENDING,
}

export enum SortType {
    DATE,
    NUMBER,
    STRING,
    NONE,
}

export const sortDirections = [SortDirection.NONE, SortDirection.ASCENDING, SortDirection.DESCENDING]

export const sortFunctions = {
    [SortType.DATE]: {
        [SortDirection.ASCENDING]: (a: string | Date | number, b: string | Date | number) => new Date(a).getTime() - new Date(b).getTime(),
        [SortDirection.DESCENDING]: (a: string | Date | number, b: string | Date | number) => new Date(b).getTime() - new Date(a).getTime(),
    },
    [SortType.NUMBER]: {
        [SortDirection.ASCENDING]: (a: number, b: number) => a - b,
        [SortDirection.DESCENDING]: (a: number, b: number) => b - a,
    },
    [SortType.STRING]: {
        [SortDirection.ASCENDING]: (a: string, b: string) => a.localeCompare(b),
        [SortDirection.DESCENDING]: (a: string, b: string) => b.localeCompare(a),
    },
}
