import React, { createContext, Dispatch, useEffect, useReducer } from "react"
import { initialState, IState } from "@/contexts/ChatContext/types"
import { Actions, setReceivedNewMessage, iGotNewMessage, resetChat } from "./actionTypes"
import { chatReducer } from "@/contexts/ChatContext/chatReducer"
import { ChatEvent } from "@/components/Chatroom/types"
import { ChatStatusTypes } from "@/components/Chatroom/types"
import Chatroom from "@/components/Chatroom"
import { useCurrentWorkspace, useGetUserQuery } from "@/api/user"

interface IChatContext {
    state: IState
    dispatch: Dispatch<Actions>
}

const ChatContext = createContext<IChatContext>({
    state: initialState,
    dispatch: () => null,
})

const ChatContextProvider: React.FC = ({ children }) => {
    const [state, dispatch] = useReducer(chatReducer, initialState)
    const { data: user } = useGetUserQuery()
    const currentWorkspace = useCurrentWorkspace()

    const hasChat = user && currentWorkspace && user.id !== currentWorkspace.deceased_user_id

    useEffect(() => {
        const socketId = Echo.socketId()
        if (!socketId || !hasChat || !currentWorkspace) return

        const channel = Echo.private("chat" + currentWorkspace.deceased_user_id)
        new Promise((resolve, reject) => {
            channel.subscribed((e: void) => {
                resolve(e)
            })
            channel.error((e: void) => {
                reject(e)
            })
        }).then(() => {
            channel.listen(".chatRoom", (e: ChatEvent) => {
                const mergeData = {
                    user: {
                        nickname: e.message.user.nickname,
                        last_name: e.message.user.last_name,
                        id: e.message.user.id,
                        profile_picture_url: e.message.user.profile_picture_url,
                    },
                    message: e.message.message,
                    temp_id: e.temp_id,
                    sent_by_user: e.message.sent_by_user,
                    id: e.message.id,

                    channel_id: e.message.channel_id,
                    created_at: e.message.created_at,
                    status: ChatStatusTypes.RECEIVED,
                }
                dispatch(iGotNewMessage(true))
                dispatch(setReceivedNewMessage(mergeData))
            })
        }).catch(error => {
            console.log(error)
        })

        return () => {
            dispatch(resetChat())
            Echo.leave("chat" + currentWorkspace.deceased_user_id)
        }
    }, [currentWorkspace])

    return (
        <ChatContext.Provider value={{ state, dispatch }}>
            {children}
            {hasChat && <Chatroom />}
        </ChatContext.Provider>
    )
}

export {
    ChatContext,
}

export default ChatContextProvider
