import React from "react"
import classnames from "classnames"
import { icons } from "@/config/icons"

interface ProfilePictureProps {
    size?: "small" | "medium" | "large" | "32" | "humongous"
    src: string | null
    isVault?: boolean
}

const ProfilePicture: React.FC<ProfilePictureProps> = ({ size = "medium", src, isVault = false }) => {
    const imageClassName = classnames(
        "profile-image",
        {
            "profile-image--small": size === "small",
            "profile-image--medium": size === "medium",
            "profile-image--large": size === "large",
            "profile-image--32": size === "32",
            "profile-image--huge": size === "humongous",
        }
    )

    return (
        <div className={imageClassName}>
            {src
                ? <img src={src} alt={__("misc.avatar_alt")} />
                : <i>{isVault ? icons["grayAvatar"] : icons["avatar"]}</i>
            }
        </div>
    )

}

export default ProfilePicture
