import React from "react"
import { IRepeaterComponent } from "@/components/Guide/types"
import { renderGuideComponent } from "@/components/Guide/utils"
import Add from "@mui/icons-material/Add"
import Remove from "@mui/icons-material/Remove"
import { useGuideResponseValue } from "@/contexts/GuideContext/utils"
import IconButton from "@/components/IconButton"
import { v4 as uuid } from "uuid"


const Repeater: React.FC<IRepeaterComponent> = (props) => {
    const componentGroupValues = useGuideResponseValue(props.id)
    const [componentGroups, setComponentGroups] = React.useState(() => componentGroupValues
        ? (componentGroupValues as []).map(() => ({ id: uuid(), components: props.components }))
        : new Array(props.min ?? 1).fill(undefined).map(() => ({ id: uuid(), components: props.components }))
    )

    const addComponentGroup = () => {
        setComponentGroups(prev => prev.concat({ id: uuid(), components: props.components }))
    }

    const removeComponentGroup = (groupId: string) => {
        setComponentGroups(prev => prev.filter((group) => group.id !== groupId))
    }

    const minGroupsAmount = props.min ?? 1
    const maxGroupsAmount = props.max ?? Infinity

    return (
        <div className="repeater__container">
            {!!componentGroups.length && (
                <div>
                    {props.label && <h3>{props.label}</h3>}
                    {componentGroups.map((group, groupIndex) => (
                        <div className="repeater__group-container" key={group.id}>
                            <div className="repeater__group-components">
                                {group.components.map(component => React.cloneElement(
                                    renderGuideComponent(component),
                                    { id: `${props.id}.${groupIndex}.${component.id}` }
                                ))}
                            </div>
                            {groupIndex >= minGroupsAmount && (
                                <div className="repeater__delete-group-container">
                                    <IconButton
                                        type="button"
                                        variant="danger"
                                        icon={<Remove />}
                                        onClick={() => removeComponentGroup(group.id)} />
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            )}
            <div>
                <IconButton
                    type="button"
                    className="ml-auto"
                    icon={<Add />}
                    onClick={addComponentGroup}
                    disabled={componentGroups.length >= maxGroupsAmount} />
            </div>
        </div>
    )
}

export default Repeater
