import { ReactElement } from "react"

export interface INotificationContext {
    notifications: INotification[]
    notify: (notification: INotification) => void
    dismiss: (notificationId: string) => void
    clear: () => void
}

export enum NotificationStatus {
    ERROR = "error",
    INFO = "info",
    SUCCESS = "success",
    WARNING = "warning",
}

export enum NotificationType {
    TOAST = "toast",
    BAR = "bar",
}

export interface INotification {
    notificationId: string
    autoClose?: boolean
    type: NotificationType
    status: NotificationStatus
    Content: ReactElement
}
