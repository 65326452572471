import React from "react"

export function useDebouncedState<T>(state: T, delay: number) {
    const [debouncedState, setDebouncedState] = React.useState<T>(state)

    React.useEffect(
        () => {
            const timeoutRef = setTimeout(() => {
                setDebouncedState(state)
            }, delay)

            return () => clearTimeout(timeoutRef)
        },
        [state, delay]
    )

    return debouncedState
}
