import React from "react"
import { vaultServiceIdTranslationKeyMap } from "@/api/vault"
import Placeholder from "@/components/Placeholder"
import VaultServiceHeader from "@/components/Vault/VaultServiceHeader"
import Spinner from "@/components/Spinner"
import { VaultServiceViewUrlParams } from "@/views/VaultServiceView/types"
import { AxiosError } from "axios"
import { icons } from "@/config/icons"
import { useParams } from "react-router-dom"
import { TranslationKeys } from "@/config/translations"
import { GA } from "@/utilities/googleAnalytics"

interface IBasicFileBase {
    url: string
}

interface IBasicFileWithTitle extends IBasicFileBase {
    title: string
}

interface IBasicFileWithTranslationKey extends IBasicFileBase {
    translation_key: TranslationKeys
}

type IBasicFile = IBasicFileWithTitle | IBasicFileWithTranslationKey

const BasicFileView = () => {
    const [basicFiles, setBasicFiles] = React.useState<IBasicFile[]>([])
    const [loading, setLoading] = React.useState(false)
    const [errorMessage, setErrorMessage] = React.useState<string>("")
    const { serviceId } = useParams<VaultServiceViewUrlParams>()

    const translationKey = vaultServiceIdTranslationKeyMap[serviceId]

    const handleGAClick = (title: string) =>{
        switch (title) {
        case "vault.templates.will.partners":
            GA("button","DV: Free templates - Testament (partner)", "Clicked the button" )
            break
        case "vault.templates.will.cohabitants":
            GA("button","DV: Free templates - Testament (married)", "Clicked the button" )
            break
        case "vault.templates.will.default":
            GA("button","DV: Free templates - Testament (standard)", "Clicked the button")
            break
        case "vault.templates.partners_agreement":
            GA("button","DV: Free templates - Partnership agreement", "Clicked the button" )
            break
        case "vault.templates.power_of_attorney":
            GA("button", "DV: Free templates - Power of attorney", "Clicked the button")
            break
        default:
            break
        }
    }

    React.useEffect(() => {
        void async function() {
            setLoading(true)

            try {
                const { data } = await Axios.get<IBasicFile[]>(`/api/folders/generated/${translationKey}`)
                setBasicFiles(data)
            } catch (error) {
                const _error = error as AxiosError
                setErrorMessage(_error.message)
            }

            setLoading(false)
        }()
    }, [])

    let content = (
        <Placeholder
            icon={icons.emptyFolder}
            title={__("views.guide.placeholders.no-files.title")}
            description={__("views.guide.placeholders.no-files.description")} />
    )
    if(loading) {
        content = <Spinner />
    } else if(errorMessage) {
        content = <p>{errorMessage}</p>
    } else if(basicFiles.length) {
        content = (
            <div className="credentials-service-view__grid">
                {basicFiles.map((basicFile, index) => {
                    const title = "title" in basicFile
                        ? basicFile.title
                        : __(basicFile.translation_key)

                    return (
                        <div className="credentials-service-view__card-container" key={index}>
                            <a href={basicFile.url} download target="_blank" className="small-card" role="button" rel="noreferrer" onClick={() =>handleGAClick(title)}>
                                <div>
                                    <div className="small-card__title">{title}</div>
                                </div>
                                {icons.download}
                            </a>
                        </div>
                    )
                })}
            </div>
        )
    }

    return (
        <>
            <VaultServiceHeader translationKey={translationKey} />
            {content}
        </>
    )
}

export default BasicFileView
