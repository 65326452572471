import { ArrowForward } from "@mui/icons-material"
import React, { useState } from "react"
import DemiseModal from "../DemiseModal"

const Support = () => {
    const [openModal, setOpenModal] = useState(false)
    return (
        <div id="support" className="support-container">
            <a href="#support" aria-hidden="true">
            </a>
            <h1>Stöd vid bortgång: Så här hjälper vi dig</h1>
            <p>Har du en anhörig som har gått bort så är Hantera en bortgång din guide som steg för steg tar dig igenom allt det praktiska som måste göras, inklusive alla dokument.</p>
            <div className="support-button">
                <button onClick={() => setOpenModal(!openModal)} className="button--support"><b>Läs mer</b><ArrowForward /></button>
            </div>
            {openModal && <DemiseModal closeModal={() => setOpenModal(!openModal)} />}
        </div>




    )
}

export default Support