import React from "react"
import { MediaRule, useMatchMedia } from "@/utilities/useMatchMedia"

interface MatchMediaProps {
    rule: MediaRule
    children: React.ReactNode
}

const MatchMedia = ({ rule, children }: MatchMediaProps) => {
    const match = useMatchMedia(rule)

    if (!match) return null

    return (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <React.Fragment>{children}</React.Fragment>
    )
}

MatchMedia.PhoneOnly = MediaRule.PhoneOnly
MatchMedia.TabletAndAbove = MediaRule.TabletAndAbove

export default MatchMedia
