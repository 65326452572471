import React, { useEffect } from "react"
import { Helmet } from "react-helmet"
import BankId from "@/components/BankId"
import { UserResource } from "@/types/models/User"
import { EmailInput } from "@/components/Inputs/EmailInput"
import SubmitButton from "@/components/SubmitButton"
import { routes } from "@/config/routes"
import { Redirect, useHistory } from "react-router-dom"
import { AxiosError } from "axios"
import OnboardingLayout from "@/components/OnboardingLayout"
import { IValidateRegistrationResponse } from "@/views/OnboardingView/ReactiveRegistrationView"
import { useGetUserQuery, useIsLoggedIn, UserQuery, useSetCurrentWorkspaceMutation, useWorkspaces } from "@/api/user"
import { useQueryClient } from "react-query"
import { pixel } from "@/utilities/pixel"
import ReactGA from "react-ga4"

const ProactiveRegistrationView = () => {
    const history = useHistory()
    const { data: user } = useGetUserQuery()
    const isLoggedIn = useIsLoggedIn()
    const workspaces = useWorkspaces()
    const setCurrentWorkspaceMutation = useSetCurrentWorkspaceMutation()
    const [showUserForm, setShowUserForm] = React.useState(false)
    const [email, setEmail] = React.useState("")
    const [isSubmitting, setIsSubmitting] = React.useState(false)
    const [error, setError] = React.useState<string>()
    const [isLoadingCheckoutProps, setIsLoadingCheckoutProps] = React.useState(false)
    const [checkoutProps, setCheckoutProps] = React.useState<IValidateRegistrationResponse>()
    const queryClient = useQueryClient()


    const ownWorkspace = workspaces.find(workspace => workspace.deceased_user_id === user?.id)

    /**
     * If we are already authenticated but do not have a proactive workspace
     * then we fetch checkout props.
     */
    useEffect(() => {
        if (!isLoggedIn && !ownWorkspace) return

        setIsLoadingCheckoutProps(true)
        void async function getCheckoutProps() {
            const { data } = await Axios.get<IValidateRegistrationResponse>("/api/register/validate")
            setCheckoutProps(data)
            setIsLoadingCheckoutProps(false)
        }()
    }, [isLoggedIn])

    useEffect(() => {
        pixel("track", "proactive_registration_started")
    }, [])

    const handleSuccess = async () => {
        pixel("track", "proactive_registration_bankid_signed")
        ReactGA.event({
            category: "Button",
            action: "Digitala valvet - Sign up success",
            label: "Sign Up",
            value: 1,
        })
        // If the user already has an account with us we simply log them in.
        await queryClient.invalidateQueries<UserResource>(UserQuery.Get)
        const user = queryClient.getQueryData<UserResource>(UserQuery.Get)

        const ownWorkspace = user?.workspaces.find(({ deceased_user_id }) => deceased_user_id === user.id)
        if (ownWorkspace) {
            setCurrentWorkspaceMutation.mutate(ownWorkspace.relation_id)
            history.push(routes.overview)
        } else {
            setIsLoadingCheckoutProps(true)

            const { data } = await Axios.get<IValidateRegistrationResponse>("/api/register/validate")

            setCheckoutProps(data)
            setIsLoadingCheckoutProps(false)
        }
    }

    const handleFailure = async (reason?: "no_user_found" | string) => {
        if (reason === "user_not_found") setShowUserForm(true)
    }

    const createUser = async (e: React.FormEvent) => {
        e.preventDefault()
        setIsSubmitting(true)

        try {
            await Axios.get("/sanctum/csrf-cookie")
            const { data: user } = await Axios.post<UserResource>("/api/register/", { email })

            pixel("track", "proactive_registration_email_given")

            queryClient.setQueryData<UserResource>(UserQuery.Get, user)
            await queryClient.invalidateQueries<UserResource>(UserQuery.Get)

            setShowUserForm(false)
        } catch (error) {
            const e = error as AxiosError<{message: string}>
            if (e.response?.data.message) setError(e.response.data.message)
        }

        setIsSubmitting(false)
    }

    const createWorkspace = async (e: React.FormEvent) => {
        e.preventDefault()
        setIsSubmitting(true)

        try {
            const { data: user } = await Axios.post<UserResource>("/api/register/create")

            pixel("track", "trial_started")

            queryClient.setQueryData<UserResource>(UserQuery.Get, user)
            await queryClient.invalidateQueries<UserResource>(UserQuery.Get)
        } catch (error) {
            const e = error as AxiosError<{message: string}>
            if (e.response?.data.message) setError(e.response.data.message)
            setIsSubmitting(false)
        }
    }

    const showBankID = !isLoggedIn && !(showUserForm || checkoutProps)

    if (ownWorkspace) {
        setCurrentWorkspaceMutation.mutate(ownWorkspace.relation_id)
        return <Redirect to={routes.overview} />
    }

    return (
        <OnboardingLayout
            hasBacklink={!checkoutProps}
            isLoading={isLoadingCheckoutProps || !checkoutProps && isLoggedIn}>
            <Helmet>
                <title>{__("onboarding.registration.meta_title")}</title>
            </Helmet>
            <h1>{__("onboarding.registration.proactive.title")}</h1>
            <ul className="onboarding__usps font-gray-700 pb-4">
                {/* <li className="mx-auto">{__("onboarding.registration.proactive.usp.free_trial")}</li>
                <li className="mx-auto">{__("onboarding.registration.proactive.usp.terminate_anytime")}</li>
                <li className="mx-auto">{__("onboarding.registration.proactive.usp.no_creditcard")}</li> */}
            </ul>
            {showBankID && <BankId onSuccess={handleSuccess} onFailure={handleFailure} />}
            {showUserForm && (
                <form className="onboarding__form" onSubmit={createUser}>
                    <EmailInput
                        name="email"
                        label={__("common.dictionary.email")}
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        required />
                    <SubmitButton wide disabled={!!error} isSubmitting={isSubmitting}>
                        {__("common.dictionary.proceed")}
                    </SubmitButton>
                </form>
            )}
            {checkoutProps && (
                <form className="onboarding__form" onSubmit={createWorkspace}>
                    <label className="checkbox-radio checkbox-radio__label">
                        <input type="checkbox" className="checkbox-radio__input" required />
                        <span className="checkbox-radio__custom-checkbox" />
                        <span className="checkbox-radio__label-text prose">
                            <a href="https://fernita.se/allmanna-villkor/" target="_blank" rel="noreferrer">
                                {__("onboarding.registration.actions.agree")}
                            </a>
                        </span>
                    </label>
                    <SubmitButton wide disabled={!!error} isSubmitting={isSubmitting}>
                        {__("onboarding.registration.actions.create")}
                    </SubmitButton>
                </form>
            )}
        </OnboardingLayout>
    )
}

export default ProactiveRegistrationView
