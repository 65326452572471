import React from "react"
import ProfilePicture from "@/components/ProfilePicture"
import CheckIcon from "@mui/icons-material/Check"
import classnames from "classnames"
import { icons } from "@/config/icons"

interface IWorkspaceButtonProps {
    selected?: boolean
    profilePictureSrc: string | null
    workspaceTitle: string
    notMine: boolean
    className?: string | null
    onClick: () => void
}

const WorkspaceButton: React.FC<IWorkspaceButtonProps> = ({
    selected = false,
    profilePictureSrc,
    workspaceTitle,
    notMine,
    className= null,
    onClick,
}) => {
    let buttonClasses = ""
    if (className !== null){
        buttonClasses = className
    }
    else {
        buttonClasses = notMine ? classnames("workspace-button", {
            "workspace-button--current": selected,
        }) : classnames("gray-workspace-button", {
            "gray-workspace-button--current": selected,
        })
    }


    return (
        <button className={buttonClasses} onClick={onClick}>
            <ProfilePicture src={profilePictureSrc} size="medium" isVault={!notMine} />
            <span>{workspaceTitle}</span>
            <div className={notMine ? "workspace-button__icon" : "gray-workspace-button__icon"}>
                {selected ? <CheckIcon /> : icons.arrowForward}
            </div>
        </button>
    )
}

export default WorkspaceButton
