import React from "react"
import Section from "@/components/Section/"

const FooterFAQ = () => {
    return (
        <div className="polygon-section">
            <Section className="section-bg-fade">
                <div className="footer-faq-container">
                    <div>
                        <h2>Har du några frågor?</h2>
                    </div>
                    <div>
                        <p>
                            <a href="https://fernita.se/faq/"><u>Här hittar du de vanligaste frågorna</u></a> kring Fernita och Digitala Valvet. Har du andra funderingar kan du skicka ett mail på support@fernita.se
                        </p>
                    </div>

                </div>

            </Section>
        </div>
    )
}

export default FooterFAQ