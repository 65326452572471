import React, { useEffect } from "react"
import VaultSection from "@/components/VaultSection"
import GuideStatusSection from "@/components/Guide/GuideStatusSection"
import { Helmet } from "react-helmet"
import ApplicationLayout from "@/components/ApplicationLayout"
import Section from "@/components/Section"
import Container from "@/components/Container"
import { useIsProactiveWorkspace } from "@/api/user"
import { Userpilot } from "userpilot"
import Walkthrough from "../../components/walkthrough"
import FullBenefitLayout from "../../components/FullBenefitLayout"
import ReactTo from "../../components/ReactTo"
import FooterFAQ from "../../components/FooterFAQ"
import RelativeSection from "../../components/RelativeSection"
import DvSupport from "../../components/SupportDV"
import DvWalkthrough from "../../components/DvWalkthrough"

const OverviewView = () => {
    const isProactiveWorkspace = useIsProactiveWorkspace()

    useEffect(() => {
        Userpilot.reload()
    }, [])

    return (
        <ApplicationLayout>
            <Helmet>
                <title>{__("views.overview.meta_title")}</title>
            </Helmet>
            <Section className="bg-white-400">
                {isProactiveWorkspace ? <DvWalkthrough />  : <Walkthrough /> }
            </Section>
            {!isProactiveWorkspace && <GuideStatusSection />}
            {!isProactiveWorkspace && (
                <Section className="bg-gray-75">
                    <RelativeSection />
                </Section>

            )}
            <VaultSection />
            {isProactiveWorkspace && (
                <Section className="bg-white-400">
                    <Container>
                        <FullBenefitLayout />
                    </Container>
                </Section>
            )}
            {!isProactiveWorkspace && (
                <Section>
                    <DvSupport />
                </Section>
            )}
            <Section className="bg-white-400">
                <ReactTo />
            </Section>
            {/* <FAQSection /> */}
            <FooterFAQ />
        </ApplicationLayout>
    )
}

export default OverviewView
