import React from "react"
import { RouteProps } from "react-router"
import { Redirect, Route } from "react-router-dom"
import { routes } from "@/config/routes"

interface GuardedRouteProps extends RouteProps {
    guarded: boolean

    // TODO Create stricter type definition
    redirect?: string
}

const GuardedRoute = ({ guarded, redirect = routes.root, ...props }: GuardedRouteProps) => {
    return guarded
        ? <Route {...props} />
        : <Redirect to={redirect} />
}

export default GuardedRoute
