import React from "react"
import { ISelectOption } from "@/components/Inputs/Select"
import { CredentialCategory, CredentialResource } from "@/components/Vault/Views/CredentialsServiceView/types"
import { predefinedCredentialCategories } from "@/components/Vault/Views/CredentialsServiceView/config"

export const formatCredentialOptionLabel = (props: unknown) => {
    const { value, label } = props as ISelectOption
    const { Icon } = predefinedCredentialCategories[value as CredentialCategory]

    return (
        <div className="credentials-service-view__category-option">
            <Icon />
            {label}
        </div>
    )
}

export const mockedCredentials: CredentialResource[] = Array.from(Array(13)).map(() => {
    const category_key = Object.values(CredentialCategory)[Math.floor(Math.random() * Object.values(CredentialCategory).length)]
    return {
        id: Math.ceil(100000000000 * Math.random()),
        category_key,
        title: "Lorem title yo",
        description: Math.random() < 0.5 ? undefined : "lorem lorem stuff stuff description yarrr matey u wot m8ey?",
        entries: Array.from(Array(Math.ceil(Math.random() * 3))).map(() => ({
            label: "Le Label",
            value: "SupersecretpasswordI~llNEVERtellANYONEabout!11",
        })),
    }
})
