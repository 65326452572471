import React from "react"
import { Helmet } from "react-helmet"
import { Link, useHistory, useParams } from "react-router-dom"
import ApplicationLayout from "@/components/ApplicationLayout"
import Spinner from "@/components/Spinner"
import { useGetChapterTemplate, useGetGuideStatus, useGuideResponse } from "@/api/guide"
import BugReport from "@mui/icons-material/BugReport"
import { renderStep } from "@/components/Guide/utils"
import Section from "@/components/Section"
import GuideChapterSteppers from "@/components/Guide/GuideChapterSteppers"
import Card from "@/components/Card"
import Container from "@/components/Container"
import parseHtml from "html-react-parser"
import Button from "@/components/Button"
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft"
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight"
import { routes } from "@/config/routes"
import MatchMedia from "@/components/MatchMedia"
import IconButton from "@/components/IconButton"
import Prose from "@/components/Prose"
import { StepIcon } from "@mui/material"
import { FormActions } from "@/components/Modal"
import OverviewLink from "@/components/OverviewLink"


const GuideView = () => {
    const { chapterId } = useParams<{ chapterId: string }>()
    const templateQuery = useGetChapterTemplate(chapterId)
    const responseQuery = useGuideResponse()
    const statusQuery = useGetGuideStatus()
    const history = useHistory()
    let content = null
    if (templateQuery.isLoading || responseQuery.isLoading) {
        content = (
            <Section>
                <Container>
                    <Spinner />
                </Container>
            </Section>
        )
    } else if (templateQuery.isError || responseQuery.isError) {
        content = (
            <div className="guide__no-content-container">
                <div className="guide__no-content-inner">
                    <p><BugReport /></p>
                    <p>{__("views.guide.errors.service_unavailable")}</p>
                </div>
            </div>
        )
    } else if (templateQuery.data) {
        content = templateQuery.data.steps.map(renderStep)
    }

    const nextChapterId = +chapterId + 1
    const previousChapterId = +chapterId - 1
    const totalChapters = statusQuery.data?.chapters.length || 0

    const navigateToChapter = (chapterId: number) => {
        history.push(`/guide/${chapterId}`)
        window.scrollTo(0, 0)
    }

    const stepIconStyle = {
        color: "var(--gray-750)",

        ".MuiStepIcon-text": {
            fontFamily: "inherit",
            fill: "white",
            stroke: "var(--gray-800)",
            strokeWidth: "1.5px",
            strokeLinejoin: "round",
            paintOrder: "stroke",
        },
    }

    return (
        <ApplicationLayout>
            <Helmet>
                <title>{__("views.guide.meta_title")}</title>
            </Helmet>
            <Section className="bg-gray-550">
                <Container>
                    <OverviewLink className="mb-4" />
                    <Card>
                        <GuideChapterSteppers />
                        <div className="mt-8">
                            <FormActions>
                                <Link className="font-gray-750 semi-bold" to={`${routes.vault}/${routes.vaultGeneratedDocuments}`}>
                                    {__("vault.generated-documents.link")}
                                </Link>
                            </FormActions>
                        </div>
                    </Card>
                    {templateQuery.data && (
                        <Card>
                            <div className="guide__intro-container">
                                <div>
                                    <h1 className="guide__intro-title--mobile"><StepIcon icon={templateQuery.data.index} sx={stepIconStyle} /> <span>{templateQuery.data.title}</span></h1>
                                </div>
                                <Prose>
                                    <h1 className="guide__intro-title--desktop">{templateQuery.data.title}</h1>
                                    {templateQuery.data.description && parseHtml(templateQuery.data.description)}
                                </Prose>
                            </div>
                        </Card>
                    )}
                </Container>
            </Section>
            {content}
            <Section className="bg-white-400">
                <Container className="guide__footer-navigation">
                    <MatchMedia rule={MatchMedia.PhoneOnly}>
                        <IconButton
                            icon={<KeyboardArrowLeft />}
                            onClick={() => navigateToChapter(previousChapterId)}
                            disabled={previousChapterId < 1} />
                        {__("views.guide.common.step_count", { current: chapterId, total: totalChapters })}
                        <IconButton
                            icon={<KeyboardArrowRight />}
                            onClick={() => navigateToChapter(nextChapterId)}
                            disabled={nextChapterId > totalChapters} />
                    </MatchMedia>
                    <MatchMedia rule={MatchMedia.TabletAndAbove}>
                        <Button
                            onClick={() => navigateToChapter(previousChapterId)}
                            disabled={previousChapterId < 1}
                            className="gray-button">
                            <KeyboardArrowLeft /> {__("views.guide.common.previous_step")}

                        </Button>
                        {__("views.guide.common.step_count", { current: chapterId, total: totalChapters })}
                        <Button
                            onClick={() => navigateToChapter(nextChapterId)}
                            disabled={nextChapterId > totalChapters}
                            className="gray-button">
                            {__("views.guide.common.next_step")} <KeyboardArrowRight />
                        </Button>
                    </MatchMedia>
                </Container>
            </Section>
        </ApplicationLayout>
    )
}

export default GuideView
