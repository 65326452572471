import React from "react"

import { INotification, INotificationContext, NotificationType } from "@/contexts/NotificationsContext/types"
import Toaster from "@/components/Toaster"

const NotificationContext = React.createContext<INotificationContext>({
    notifications: [],
    notify: () => null,
    dismiss: () => null,
    clear: () => null,
})

export const useNotifications = () => React.useContext(NotificationContext).notifications
export const useNotify = () => React.useContext(NotificationContext).notify
export const useDismissNotification = () => React.useContext(NotificationContext).dismiss
export const useClearNotifications = () => React.useContext(NotificationContext).clear

const NotificationContextProvider: React.FC = ({ children }) => {
    const [notifications, setNotifications] = React.useState<INotification[]>([])

    const notify = React.useCallback(
        (notification: INotification) => setNotifications(prev => prev.concat(notification)),
        []
    )

    const dismiss = React.useCallback(
        (id: string) => setNotifications(p => p.filter(({ notificationId }) => notificationId !== id)),
        []
    )

    const clear = React.useCallback(
        () => setNotifications([]),
        []
    )

    const context = {
        notifications,
        notify,
        dismiss,
        clear,
    }

    const toasts = notifications.filter(({ type }) => type === NotificationType.TOAST)

    return (
        <NotificationContext.Provider value={context}>
            {children}
            {!!toasts.length && <Toaster toasts={toasts} />}
        </NotificationContext.Provider>
    )

}

export default NotificationContextProvider
