import React from "react"
import { Helmet } from "react-helmet"
import BankId from "@/components/BankId"
import { EmailInput } from "@/components/Inputs/EmailInput"
import { AxiosError } from "axios"
import SubmitButton from "@/components/SubmitButton"
import { NumericTextInput } from "@/components/Inputs/NumericTextInput"
import OnboardingLayout from "@/components/OnboardingLayout"
import { UserResource } from "@/types/models/User"
import Personnummer from "personnummer"
import { useQueryParams } from "@/utilities/useQueryParams"
import { useQueryClient } from "react-query"
import { useIsLoggedIn, UserQuery } from "@/api/user"
import ReactGA from "react-ga4"

export interface IValidateRegistrationResponse {
    name?: string
    lifespan?: string
    description: string
}

export interface ICheckoutResponse {
    redirect: string
}

const ReactiveRegistrationView = () => {
    const params = useQueryParams()
    const [showUserForm, setShowUserForm] = React.useState(false)
    const isLoggedIn = useIsLoggedIn()
    const [ssn, setSSN] = React.useState(params.get("_ssn") || "")
    const [ssnError, setSSNError] = React.useState("")
    const [isValidSSN, setIsValidSSN] = React.useState(false)
    const [isSubmitting, setIsSubmitting] = React.useState(false)
    const [email, setEmail] = React.useState("")
    const [checkoutProps, setCheckoutProps] = React.useState<IValidateRegistrationResponse>()
    const queryClient = useQueryClient()

    const handleBankIDSuccess = (user: UserResource) => {
        ReactGA.event({
            category: "SSN",
            action: "Hantera Bortgång - BankID success",
            label: "Sign Up",
            value: 1,
        })
        queryClient.setQueryData(UserQuery.Get, user)
    }

    const handleBankIDFailure = (reason: "user_not_found" | string) => {
        if (reason === "user_not_found") {
            setShowUserForm(true)
        }
        ReactGA.event({
            category: "SSN",
            action: "Hantera Bortgång - BankID failed",
            label: "Sign Up",
            value: 1,
        })
    }

    const registerUser = async (e: React.FormEvent) => {
        e.preventDefault()
        setIsSubmitting(true)

        try {
            await Axios.get("/sanctum/csrf-cookie")
            const { data: user } = await Axios.post<UserResource>("/api/register/", { email })
            queryClient.setQueryData(UserQuery.Get, user)
            setShowUserForm(false)
        } catch (error) {
            const e = error as AxiosError<{message: string}>
            if (e.response?.data.message) console.error(e.response)
        }

        setIsSubmitting(false)
    }

    const validateDeceasedSSN = async (e: React.FormEvent) => {
        e.preventDefault()
        setSSNError("")

        if (!Personnummer.valid(ssn)) {
            return setSSNError(__("validation.custom.ssn.format"))
        }
        setIsSubmitting(true)

        try {
            const { data } = await Axios.get<IValidateRegistrationResponse>(`/api/register/validate?ssn=${Personnummer.parse(ssn).format(true)}`)
            setSSN(Personnummer.parse(ssn).format(true))
            setIsValidSSN(true)
            ReactGA.event({
                category: "SSN",
                action: "Valid SSN for deceased",
                label: "Sign in",
                value: 1,
            })
            setCheckoutProps(data)
        } catch (error) {
            ReactGA.event({
                category: "SSN",
                action: "Invalid SSN for deceased",
                label: "Sign in",
                value: 1,
            })
            setSSNError(__("subscriptions.errors.validation.generic"))
        }

        setIsSubmitting(false)
    }

    const checkout = async (e: React.FormEvent) => {
        e.preventDefault()

        setIsSubmitting(true)

        try {
            const { data } = await Axios.post<ICheckoutResponse>("/api/register/create", { ssn })
            window.location.href = data.redirect
        } catch {
            console.log("Something is wong. So very very wong.")
        }

        setIsSubmitting(false)
    }

    return (
        <OnboardingLayout hasBacklink={!isLoggedIn}>
            <Helmet>
                <title>{__("onboarding.registration.meta_title")}</title>
            </Helmet>
            <h1>{__("onboarding.registration.reactive.title")}</h1>
            {!isLoggedIn && !showUserForm && <BankId onSuccess={handleBankIDSuccess} onFailure={handleBankIDFailure} />}
            {showUserForm && (
                <form className="onboarding__form" onSubmit={registerUser}>
                    <EmailInput
                        label={__("common.dictionary.email")}
                        value={email}
                        required
                        onChange={e => setEmail(e.target.value)} />
                    <SubmitButton wide isSubmitting={isSubmitting}>{__("common.dictionary.proceed")}</SubmitButton>
                </form>
            )}
            {isLoggedIn && (
                <React.Fragment>
                    <h2>{__("onboarding.registration.reactive.subtitle")}</h2>
                    <form className="onboarding__form" onSubmit={validateDeceasedSSN}>
                        <NumericTextInput
                            isValid={isValidSSN}
                            placeholder={__("common.dictionary.ssn-format")}
                            label={__("common.dictionary.ssn")}
                            value={ssn}
                            required
                            onChange={e => setSSN(e.target.value)}
                            error={ssnError} />
                        {!checkoutProps && <SubmitButton wide isSubmitting={isSubmitting}>{__("common.dictionary.proceed")}</SubmitButton>}
                    </form>
                </React.Fragment>
            )}
            {checkoutProps && (
                <React.Fragment>
                    <div className="onboarding__validated-person-description">
                        <span>{checkoutProps.name}</span>
                        <span>{checkoutProps.lifespan}</span>
                    </div>
                    <form className="onboarding__form" onSubmit={checkout}>
                        <label className="checkbox-radio checkbox-radio__label">
                            <input type="checkbox" className="checkbox-radio__input" required />
                            <span className="checkbox-radio__custom-checkbox" />
                            <span className="checkbox-radio__label-text prose">
                                <a href="https://fernita.se/allmanna-villkor/" target="_blank" rel="noreferrer">
                                    {__("onboarding.registration.actions.agree")}
                                </a>
                            </span>
                        </label>
                        <SubmitButton wide isSubmitting={isSubmitting}>{__("common.dictionary.proceed")}</SubmitButton>
                        {/* <p className="checkout-description">{checkoutProps.description}</p> */}
                    </form>
                </React.Fragment>
            )}
        </OnboardingLayout>
    )
}

export default ReactiveRegistrationView
