import { useQuery } from "react-query"

export enum FoldersQuery {
    GetAllSubfolders = "get-all-subfolders",
}

export interface SimpleFolderResource {
    id: number
    title: string
}

const getAllSubfolders = async (folderId: number | string) => {
    const { data } = await Axios.get<SimpleFolderResource[]>(`/api/folders/${folderId}/subfolders`)
    return data
}

export const useGetAllSubfolders = (folderId: number | string) => {
    return useQuery(
        [FoldersQuery.GetAllSubfolders, folderId],
        () => getAllSubfolders(folderId),
        { refetchOnWindowFocus: false }
    )
}
