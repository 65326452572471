/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from "react"
import { Link, Redirect } from "react-router-dom"
import { Helmet } from "react-helmet"
import { routes } from "@/config/routes"
import Add from "@mui/icons-material/Add"
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined"
import OnboardingLayout from "@/components/OnboardingLayout"
import { useCurrentWorkspace, useWorkspaces } from "@/api/user"
import LoginModal from "./LoginModal"

const OnboardingView: React.FC = () => {
    const workspaces = useWorkspaces()
    const currentWorkspace = useCurrentWorkspace()
    const [bortgangModal, setBortgangModal] = useState(false)
    const [digitalModal, setDigitalModal] = useState(false)

    if (currentWorkspace) {
        const redirectUrl =
            workspaces && workspaces.length > 1
                ? routes.workspaces
                : routes.overview

        return <Redirect to={redirectUrl} />
    }

    return (
        <OnboardingLayout>
            <Helmet>
                <title>{__("onboarding.root.meta_title")}</title>
            </Helmet>
            {/* <h1>{__("onboarding.root.title")}</h1>
            <h2>{__("onboarding.root.subtitle")}</h2> */}
            <h1>Skapa Konto</h1>
            <span>
                <p>
                    Fernita innehåller två tjänster och{" "}
                    <span
                        className="onboarding__underline"
                        onClick={() => setDigitalModal(!digitalModal)}>
                        Digitala valvet
                    </span>{" "}
                    och{" "}
                    <span
                        className="onboarding__underline"
                        onClick={() => setBortgangModal(!bortgangModal)}>
                        Hantera en bortgång
                    </span>
                </p>
            </span>
            {/* {!isLoggedIn && (
                <Link className="onboarding__link" to={routes.login}>
                    <span className="onboarding__link-icon onboarding__link-icon--left"><LoginIcon /></span>
                    <span className="onboarding__link-label">{__("onboarding.root.actions.login")}</span>
                    <span className="onboarding__link-icon onboarding__link-icon--right"><ArrowForward /></span>
                </Link>
            )} */}
            {/* <Link className="onboarding__link onboarding__link--gray" to={routes.registration.reactive}>
                <span className="onboarding__link-label onboarding__link--group">Hantera en bortgång <h4 className="onboarding__link--group--small">Personlig och stegvis vägledning</h4></span>
                <span className="onboarding__link-icon onboarding__link-icon--right"><Add /></span>

            </Link> */}
            <Link
                className="onboarding__link onboarding__link--green"
                to={routes.registration.proactive}>
                <span className="onboarding__link-label onboarding__link--group">
                    Skapa konto till Fernita
                </span>
                <span className="onboarding__link-icon onboarding__link-icon--right">
                    <Add />
                </span>
            </Link>
            <p className="onboarding__text--account">Har du redan ett konto?</p>
            <Link to={routes.login}>
                <p className="onboarding__text--login">
                    {" "}
                    Logga in <ArrowForwardOutlinedIcon />
                </p>
            </Link>
            {bortgangModal ? (
                <LoginModal
                    closeModal={() => setBortgangModal(false)}
                    content="bortgang" />
            ) : (
                <></>
            )}
            {digitalModal ? (
                <LoginModal
                    closeModal={() => setDigitalModal(false)}
                    content="digital" />
            ) : (
                <></>
            )}
        </OnboardingLayout>
    )
}

export default OnboardingView