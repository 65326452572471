import React, { useRef, useState } from "react"
import { Link, useParams } from "react-router-dom"
import { IBreadcrumb, VaultServiceViewUrlParams } from "@/views/VaultServiceView/types"
import Closeable from "@/components/Closeable"
import classnames from "classnames"
import { vaultServiceIdTranslationKeyMap } from "@/api/vault"
import { routes } from "@/config/routes"

interface BreadcrumbProps {
    breadcrumbs: IBreadcrumb[]
}

const Breadcrumbs: React.FC<BreadcrumbProps> = ({ breadcrumbs }) => {
    const [isDropDownOpen, setIsDropDownOpen] = useState(false)
    const { serviceId, serviceSlug } = useParams<VaultServiceViewUrlParams>()
    const triggerRef = useRef<HTMLButtonElement>(null)
    const translationKey = vaultServiceIdTranslationKeyMap[serviceId]
    const rootLinkPath = `/vault/${serviceSlug}/${serviceId}`

    const links = [...breadcrumbs]
    const current = links.pop()

    const fullscreen = classnames({ "breadcrumbs-dropdown__fullscreen": links.length < 3 } )

    return (
        <ul className="breadcrumbs">
            <li>
                <Link to={routes.overview} className="breadcrumbs__digital-vault-text">{__("vault.common.title")}</Link>
            </li>
            <li>
                {!current ? <span className="breadcrumbs__current">{__(`vault.${translationKey}.title`)}</span> : (
                    <Link
                        className="breadcrumbs__list-item-link"
                        to={rootLinkPath}>{__(`vault.${translationKey}.title`)}
                    </Link>
                )}
            </li>
            {links.length > 0 && (
                <li className={`breadcrumbs-dropdown breadcrumbs__separator ${fullscreen} `}>
                    <button
                        ref={triggerRef} className="breadcrumbs-dropdown__button"
                        onClick={() => setIsDropDownOpen(!isDropDownOpen)}>...
                    </button>
                    {isDropDownOpen && (
                        <Closeable triggerRef={triggerRef} close={() => setIsDropDownOpen(false)}>
                            <ul className={`breadcrumbs-dropdown__list ${fullscreen}`}>
                                {links.map((link) => (
                                    <li className="breadcrumbs-dropdown__list-item" key={link.id}>
                                        <Link
                                            className="breadcrumbs-dropdown__list-item-link"
                                            to={`${rootLinkPath}/${link.slug}/${link.id}`}>{link.title}
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </Closeable>
                    )}
                </li>
            )}
            {links?.filter((breadcrumb, index) => index >= links.length - 2).map((link) => {
                return (
                    <li key={link.id} className="breadcrumbs__list-item breadcrumbs__separator">
                        <Link
                            className="breadcrumbs__list-item-link"
                            to={`${rootLinkPath}/${link.slug}/${link.id}`}>{link.title}
                        </Link>
                    </li>
                )})}
            { current && (
                <li className="breadcrumbs__separator">
                    <span className="breadcrumbs__current">{current?.title}</span>
                </li>
            )}
        </ul>
    )
}

export default Breadcrumbs
