import React from "react"

// vault services
import VehiclesIcon from "resources/assets/svgs/icn car.svg"
import GrayVehiclesIcon from "resources/assets/svgs/icn_gray_car.svg"
import BankingIcon from "resources/assets/svgs/icn bank.svg"
import GrayBankingIcon from "resources/assets/svgs/gray_icn_bank.svg"
import FuneralIcon from "resources/assets/svgs/icn_funeral.svg"
import InsuranceIcon from "resources/assets/svgs/icn insurance.svg"
import GrayInsuranceIcon from "resources/assets/svgs/icn_gray_insurance.svg"
import DocumentIcon from "resources/assets/svgs/icn documents.svg"
import GrayDocumentIcon from "resources/assets/svgs/icn_gray_document.svg"
import CredentialsIcon from "resources/assets/svgs/icn_login.svg"
import GrayCredentialsIcon from "resources/assets/svgs/gray_icn_login.svg"
import GreetingsIcon from "resources/assets/svgs/icn_video.svg"
import GrayGreetingIcon from "resources/assets/svgs/icn_gray_video.svg"
import PropertiesIcon from "resources/assets/svgs/icn house.svg"
import GrayHouseIcon from "resources/assets/svgs/icn_gray_house.svg"
import AvatarIcon from "resources/assets/svgs/icn_avatar.svg"
import GrayAvatarIcon from "resources/assets/svgs/icn_gray_avatar.svg"
import FolderSvgIcon from "resources/assets/svgs/icn_folder.svg"
import GrayFolderSvgIcon from "resources/assets/svgs/icn_gray_folder.svg"
import TemplatesIcon from "resources/assets/svgs/icn_contract.svg"

import ChatroomIcon from "resources/assets/svgs/chatroom.svg"
import ImageIcon from "@mui/icons-material/ImageOutlined"
import PdfIcon from "@mui/icons-material/PictureAsPdfOutlined"
import TextIcon from "@mui/icons-material/DescriptionOutlined"
import VideoIcon from "@mui/icons-material/OndemandVideoOutlined"
import CreateNewFolder from "@mui/icons-material/CreateNewFolder"
import FolderIcon from "@mui/icons-material/FolderOpen"
import DownloadIcon from "@mui/icons-material/FileDownloadOutlined"
import FileIcon from "@mui/icons-material/InsertDriveFileOutlined"
import DeleteIcon from "@mui/icons-material/DeleteOutlineOutlined"
import MoveIcon from "@mui/icons-material/MoveToInboxOutlined"
import CloseIcon from "@mui/icons-material/Close"
import ArrowForwardIcon from "@mui/icons-material/ArrowForward"
import SearchIcon from "@mui/icons-material/SearchOutlined"
import EditOutlinedIcon from "@mui/icons-material/EditOutlined"
import ExpandLessIcon from "@mui/icons-material/ExpandLess"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import EditIcon from "@mui/icons-material/Edit"
import House from "@mui/icons-material/House"

// Statuses
import CheckCircle from "@mui/icons-material/CheckCircle"
import InfoIcon from "@mui/icons-material/Info"
import WarningIcon from "@mui/icons-material/Warning"
import ErrorIcon from "@mui/icons-material/Error"

export const icons = {
    vehicles: <VehiclesIcon />,
    properties: <PropertiesIcon />,
    greetings: <GreetingsIcon />,
    funeral: <FuneralIcon />,
    insurances: <InsuranceIcon />,
    credentials: <CredentialsIcon />,
    other: <DocumentIcon />,
    banking: <BankingIcon />,
    "generated-documents": <TemplatesIcon />,
    templates: <TemplatesIcon />,
    emptyFolder: <FolderSvgIcon />,
    jpg: <ImageIcon />,
    jpeg: <ImageIcon />,
    png: <ImageIcon />,
    mp4: <VideoIcon />,
    pdf: <PdfIcon />,
    txt: <TextIcon />,
    docx: <TextIcon />,
    doc: <TextIcon />,
    success: <CheckCircle />,
    info: <InfoIcon />,
    warning: <WarningIcon />,
    error: <ErrorIcon />,
    chat: <ChatroomIcon />,
    close: <CloseIcon />,
    createNewFolder: <CreateNewFolder />,
    arrowForward: <ArrowForwardIcon />,
    expandLess: <ExpandLessIcon />,
    expandMore: <ExpandMoreIcon />,
    search: <SearchIcon />,
    folder: <FolderIcon />,
    download: <DownloadIcon />,
    file: <FileIcon />,
    delete: <DeleteIcon />,
    edit: <EditOutlinedIcon />,
    editItem: <EditIcon />,
    move: <MoveIcon />,
    avatar: <AvatarIcon />,
    grayAvatar: <GrayAvatarIcon />,
    home: <House />,

} as const

export const grayIcons = {
    banking: <GrayBankingIcon />,
    credentials: <GrayCredentialsIcon />,
    greetings: <GrayGreetingIcon />,
    insurances: <GrayInsuranceIcon />,
    properties: <GrayHouseIcon />,
    vehicles: <GrayVehiclesIcon />,
    other: <GrayDocumentIcon />,
    funeral: <FuneralIcon />,
    "generated-documents": <TemplatesIcon />,
    templates: <TemplatesIcon />,
    emptyFolder: <GrayFolderSvgIcon />,
    jpg: <ImageIcon />,
    jpeg: <ImageIcon />,
    png: <ImageIcon />,
    mp4: <VideoIcon />,
    pdf: <PdfIcon />,
    txt: <TextIcon />,
    docx: <TextIcon />,
    doc: <TextIcon />,
    success: <CheckCircle />,
    info: <InfoIcon />,
    warning: <WarningIcon />,
    error: <ErrorIcon />,
    chat: <ChatroomIcon />,
    close: <CloseIcon />,
    createNewFolder: <CreateNewFolder />,
    arrowForward: <ArrowForwardIcon />,
    expandLess: <ExpandLessIcon />,
    expandMore: <ExpandMoreIcon />,
    search: <SearchIcon />,
    folder: <FolderIcon />,
    download: <DownloadIcon />,
    file: <FileIcon />,
    delete: <DeleteIcon />,
    edit: <EditOutlinedIcon />,
    editItem: <EditIcon />,
    move: <MoveIcon />,
    avatar: <AvatarIcon />,
    grayAvatar: <GrayAvatarIcon />,
    home: <House />,
} as const

export type IconType = keyof typeof icons
export type GrayIconType = keyof typeof grayIcons
