import { UserResource } from "@/types/models/User"

export enum ChatStatusTypes {
    FAILED = "FAILED",
    SENT = "SENT",
    RECEIVED = "RECEIVED",
}

interface ChatRequest {
    message: {
        sent_by_user: number
        user: UserResource
        message: string
        id: number
        channel_id: number
        created_at: string
    }
    temp_id: string
}

export type ChatEvent = ChatRequest
