import React from "react"
import { IRadioGroupComponent } from "@/components/Guide/types"
import Radio from "@/components/Inputs/Radio"
import { useGuideResponseValue } from "@/contexts/GuideContext/utils"

const RadioGroup: React.FC<IRadioGroupComponent> = (props) => {
    const groupValue = useGuideResponseValue(props.id)

    return (
        <div className="guide__radio-group">
            <h3>{props.label}</h3>
            {Object.entries(props.options).map(([value, label]) => (
                <Radio
                    key={props.id + value}
                    name={props.id}
                    label={label}
                    value={value}
                    defaultChecked={groupValue === value} />
            ))}
        </div>
    )
}

export default RadioGroup
