import { useMutation, useQuery, useQueryClient } from "react-query"

export enum QuestionnaireQuery {
    Get = "questionnaire/get",
    Put = "questionnaire/put"
}

interface QuestionnaireResponse { [key: string]: string }

const getQuestionnaire = async (): Promise<QuestionnaireResponse> => {
    const { data } = await Axios.get("/api/questionnaire")
    return data
}

export const useQuestionnaireResponse = () => {
    return useQuery(QuestionnaireQuery.Get, getQuestionnaire)
}

const putQuestionnaireResponse = async (questionnaireResponse: QuestionnaireResponse) => {
    await Axios.put("/api/questionnaire", questionnaireResponse)
}

export const useQuestionnaireResponseMutation = () => {
    const queryClient = useQueryClient()

    return useMutation(putQuestionnaireResponse, {
        onSuccess(_, questionnaireResponse) {
            queryClient.setQueryData(QuestionnaireQuery.Get, questionnaireResponse)
        },
    })
}
