import classNames from "classnames"
import React from "react"
import { InputProps } from "../TextInput"
import { Check } from "@mui/icons-material"

type NumericTextInputProps = InputProps & React.ComponentProps<"input"> & { isValid?: boolean; isWhite?:boolean }

export const NumericTextInput: React.FC<NumericTextInputProps> = ({ name, error, Icon, label, placeholder, type = "text", onChange, value, isValid, isWhite, ...props }) => {
    const inputClassNames = classNames("input", {
        "input--has--icon": !!Icon,
        "input--has--error": !!error,
        "input--valid": !!isValid,
    })

    const labelClassNames = classNames("input__label", {
        "input__label--error": !!error,
        "input__label--valid": isValid,
        "input__label--white": isWhite,
    })

    const iconClassNames = classNames("input__icon input__icon--left", {
        "input__icon--error": !!error,
    })

    const rightIconClassNames = classNames("input__icon input__icon--right", {
        "input__icon--valid": isValid,
    })

    return (
        <div className="input__wrapper input__wrapper--numeric">
            {label && <label className={labelClassNames}>{label}</label>}
            <div className="input__wrapper--inner">
                <input
                    {...props}
                    className={inputClassNames}
                    name={name}
                    type={type}
                    inputMode="numeric"
                    onChange={onChange}
                    placeholder={placeholder}
                    value={value} />
                {Icon && <Icon className={iconClassNames} />}
                {isValid && <Check className={rightIconClassNames} />}
            </div>
            {error && <span className="input__wrapper--error">{error}</span>}
        </div>
    )
}
