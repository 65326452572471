import { CredentialEntry } from "@/components/Vault/Views/CredentialsServiceView/types"
import React from "react"
import { ContentCopy, Visibility, VisibilityOff } from "@mui/icons-material"
import { copyToClipboard } from "@/utilities"

const ReadCredentialEntry = ({ label, value }: CredentialEntry) => {
    const [visible, setVisible] = React.useState(false)

    return (
        <div className="credentials-entry__container">
            <div className="credentials-entry__data">
                <div className="credentials-entry__label">{label}</div>
                <div className="credentials-entry__value">{visible ? value : new Array(value.length + 1).join("●")}</div>
            </div>
            <button
                className="credentials-entry__copy-button"
                title={visible ? __("common.actions.hide") : __("common.actions.show")}
                type="button"
                onClick={() => setVisible(!visible)}>
                {visible ? <VisibilityOff /> : <Visibility />}
            </button>
            <button
                className="credentials-entry__copy-button"
                title={__("common.actions.copy_to_clipboard")}
                type="button"
                onClick={() => copyToClipboard(value)}>
                <ContentCopy />
            </button>
        </div>
    )
}

export default ReadCredentialEntry
