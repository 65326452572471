import React, { useEffect } from "react"
import ApplicationLayout from "@/components/ApplicationLayout"
import { Helmet } from "react-helmet"
import OverviewLink from "@/components/OverviewLink"
import Card from "@/components/Card"
import Container from "@/components/Container"
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom"
import { routes } from "@/config/routes"
import CredentialsServiceView from "@/components/Vault/Views/CredentialsServiceView"
import DefaultServiceView from "@/components/Vault/Views/DefaultServiceView"
import BasicFileView from "@/components/Vault/Views/BasicFileView"
import AfterlifeServiceView from "@/components/Vault/Views/AfterlifeServiceView"
import { useIsProactiveWorkspace } from "@/api/user"
import { Userpilot } from "userpilot"

const VaultServiceView = () => {
    const { path } = useRouteMatch()
    const isProactiveWorkspace = useIsProactiveWorkspace()
    const bgColor = isProactiveWorkspace ? "bg-primary-100" : "bg-gray-550"

    useEffect(() => {
        Userpilot.reload()
    }, [])

    return (
        <ApplicationLayout>
            <Helmet>
                <title>{__("vault.common.meta-title")}</title>
            </Helmet>
            <div className={"vault-service-view" + " " + bgColor}>
                <Container>
                    <OverviewLink className="mb-4" />
                    <Card>
                        <Switch>
                            <Route exact path={`${path}/${routes.vaultCredentialsManager}`} component={CredentialsServiceView} />
                            <Route exact path={`${path}/${routes.vaultAfterlife}`} component={AfterlifeServiceView} />
                            <Route exact path={`${path}/generated-documents/:serviceId`} component={BasicFileView} />
                            <Route exact path={`${path}/templates/:serviceId`} component={BasicFileView} />
                            <Route path={`${path}/${routes.vaultFileSystem}`} component={DefaultServiceView} />
                            <Redirect to={routes.overview} />
                        </Switch>
                    </Card>
                </Container>
            </div>
        </ApplicationLayout>
    )
}

export default VaultServiceView
