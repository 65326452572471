import React from "react"
import { ApplicationContext } from "@/contexts/ApplicationContext"
import { useHistory } from "react-router-dom"
import { useMutation, useQueryClient } from "react-query"
import { UserQuery } from "@/api/user"
import { routes } from "@/config/routes"


const logout = async () => {
    await Axios.post("/api/auth/logout")
}

export function useLogoutMutation() {
    const context = React.useContext(ApplicationContext)
    const history = useHistory()
    const queryClient = useQueryClient()

    if (context === undefined) {
        throw new Error("useLogout must be used within a ApplicationContextProvider")
    }

    return useMutation(logout, {
        onSuccess: () => {
            queryClient.resetQueries(UserQuery.Get)
            history.push(routes.login)
        },
    })
}
