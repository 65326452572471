import { routes } from "@/config/routes"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import { Link } from "react-router-dom"
import React from "react"
import cx from "classnames"
import { useCurrentWorkspace, useGetUserQuery } from "@/api/user"

const OverviewLink = ({ className }: { className?: string }) => {
    const workspace = useCurrentWorkspace()
    const { data: user } = useGetUserQuery()
    const notMyProfile = workspace && !workspace.has_sole_actor && workspace.deceased_user_id !== (user && user.id)
    const classes = notMyProfile ? cx("button button--small gray-button--outlined", className) : cx("button button--small button--outlined", className)

    return (
        <Link to={routes.overview} className={classes}>
            <ArrowBackIcon />
            {__("common.dictionary.overview")}
        </Link>
    )
}

export default OverviewLink
