import React from "react"
import { Redirect, Route, Switch } from "react-router-dom"
import OverviewView from "@/views/OverviewView"
import AccountManagementView from "@/views/AccountManagementView"
import VaultServiceView from "@/views/VaultServiceView"
import OnboardingView from "@/views/OnboardingView"
import LoginView from "@/views/OnboardingView/LoginView"
import ProactiveRegistrationView from "@/views/OnboardingView/ProactiveRegistrationView"
import ReactiveRegistrationView from "@/views/OnboardingView/ReactiveRegistrationView"
import WorkspacePickerView from "@/views/WorkspacePickerView"
import GuideView from "@/views/GuideView"
import { routes } from "@/config/routes"
import GuardedRoute from "@/components/GuardedRoute"
import { useCurrentWorkspace, useIsLoggedIn } from "@/api/user"

export default function Router() {
    const isLoggedIn = useIsLoggedIn()
    const currentWorkspace = useCurrentWorkspace()

    return (
        <Switch>
            <Route exact path={routes.root} component={OnboardingView} />
            <Route exact path={routes.login} component={LoginView} />
            <Route exact path={routes.registration.proactive} component={ProactiveRegistrationView} />
            <Route exact path={routes.registration.reactive} component={ReactiveRegistrationView} />
            <GuardedRoute guarded={isLoggedIn} exact path={routes.workspaces} component={WorkspacePickerView} />
            <GuardedRoute guarded={isLoggedIn && !!currentWorkspace} redirect={routes.workspaces} exact path={routes.overview} component={OverviewView} />
            <GuardedRoute guarded={isLoggedIn} exact path={routes.account} component={AccountManagementView} />
            <GuardedRoute guarded={isLoggedIn && !!currentWorkspace} path={routes.vault} component={VaultServiceView} />
            <GuardedRoute guarded={isLoggedIn && !!currentWorkspace} path={routes.guide} component={GuideView} />
            <Redirect to={routes.root} />
        </Switch>
    )
}
