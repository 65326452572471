import { IApplicationState } from "@/contexts/ApplicationContext/types"
import {
    ApplicationContextActionType as ActionType,
    ApplicationReducerAction
} from "@/contexts/ApplicationContext/actions"

export const initialApplicationState: IApplicationState = {
    externalProfile: undefined,
    isInitialised: false,
}

export const applicationReducer = (state: IApplicationState, action: ApplicationReducerAction): IApplicationState => {
    switch (action.type) {
    case ActionType.SetExternalAuthDetails:
        return { ...state, externalProfile: action.payload }

    case ActionType.Initialise:
        return { ...state, isInitialised: true }

    default:
        return state
    }
}
