import React from "react"
import NavigationBar from "@/components/NavigationBar"
import View from "@/components/View"

const ApplicationLayout: React.FC = ({ children }) => (
    <div className="application-layout">
        <div className="application-header">
            <NavigationBar />
        </div>
        <View>
            {children}
        </View>
    </div>
)


export default ApplicationLayout
