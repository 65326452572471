import React from "react"
import ReactDOM from "react-dom"
import CloseIcon from "@mui/icons-material/Close"
import classnames from "classnames"
import FocusTrapWrapper from "@/components/FocusTrapWrapper"

export const ModalCloseButton: React.FC<React.ComponentProps<"button">> = (props) => (
    <button className="modal__close-button" {...props}>
        <CloseIcon />
    </button>
)

export const ModalHeader: React.FC = ({ children }) => (
    <div className="modal__header">
        {children}
    </div>
)

export const ModalBody: React.FC = ({ children }) => (
    <div className="modal__body">
        {children}
    </div>
)

export const ModalFooter: React.FC = ({ children }) => (
    <div className="modal__footer">
        {children}
    </div>
)

export const FormActions: React.FC = ({ children }) => (
    <div className="form-actions">
        {children}
    </div>
)

export interface IModalProps {
    closeModal: () => void
    showCloseModal?: boolean
    animated?: boolean
}

const NonClosableModal: React.FC<IModalProps> = ({
    children,
    closeModal,
    showCloseModal = true,
    animated = true,
}) => {

    const overlayClassName = classnames("modal__overlay", {
        "modal__overlay--animated": animated,
    })

    const contentClassName = classnames("modal__content", {
        "modal__content--animated": animated,
    })

    return ReactDOM.createPortal(
        <div className="modal__root">
            <div className={overlayClassName} aria-hidden />
            <div className="modal__content-wrapper">
                <FocusTrapWrapper>
                    <div className={contentClassName}>
                        {showCloseModal && <ModalCloseButton onClick={closeModal} />}
                        {children}
                    </div>
                </FocusTrapWrapper>
            </div>
        </div>,
        document.querySelector("#root") as Element
    )
}

export default NonClosableModal
