import React from "react"
import { grayIcons, icons } from "@/config/icons"
import { VaultServiceTranslationKey } from "@/api/vault"
import { useIsProactiveWorkspace } from "@/api/user"

interface IVaultServiceHeader {
    translationKey: VaultServiceTranslationKey
}

const VaultServiceHeader: React.FC<IVaultServiceHeader> = ({ translationKey }) => {
    const isProactiveWorkspace = useIsProactiveWorkspace()
    return (
        <header className="vault-service-view__description-wrapper">
            {React.cloneElement(isProactiveWorkspace ? icons[translationKey] : grayIcons[translationKey], { className: "vault-service-view__icon" })}
            <div>
                <h1>{__(`vault.${translationKey}.title`)}</h1>
                <p>{__(`vault.${translationKey}.description`)}</p>
            </div>
        </header>
    )
}

export default VaultServiceHeader
