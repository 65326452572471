import React, { FC } from "react"
import CheckIcon from "@mui/icons-material/Check"
import AccessTimeIcon from "@mui/icons-material/AccessTime"
import RefreshIcon from "@mui/icons-material/Refresh"
import ProfilePicture from "@/components/ProfilePicture"
import { format } from "date-fns"
import { ChatStatusTypes } from "@/components/Chatroom/types"
import classnames from "classnames"

interface IMessageProps {
    user: {
        nickname: string | null
        last_name: string | null
        profile_picture_url: string | null
    }
    created?: string
    self: boolean
    status: string
    message: string
    id?: number
    retry: () => void
}

const Message: FC<IMessageProps> = (props) => {
    const containerClasses = classnames("chat__message", {
        "chat__message--self": props.self,
    })

    const userClasses = classnames("chat__user-details", {
        "chat__user-details--self": props.self,
    })

    const textClasses = classnames("chat__text", {
        "chat__text--self": props.self,
    })

    const created = props.created ? new Date(props.created) : new Date(Date.now())
    return (
        <div className={containerClasses}>
            <div className={userClasses}>
                <ProfilePicture
                    src={props.user.profile_picture_url} size="32" isVault />
                <strong>{props.user.nickname} {props.user.last_name?.charAt(0)}.</strong>
                <p className="chat__timestamp">{format(created, __("misc.chat_date"))}</p>
            </div>
            <div className={textClasses}>
                <p className="chat__message-details">{props.message}</p>
                {props.self && props.status === ChatStatusTypes.SENT && (
                    <div className="chat__message-status">
                        <AccessTimeIcon fontSize="inherit" />
                        <p>{__("chat.status.sending-message")}</p>
                    </div>
                )}
                {props.self && props.status === ChatStatusTypes.RECEIVED && (
                    <div className="chat__message-status">
                        <CheckIcon className="chat__message-status chat__message--green" fontSize="inherit" />
                        <p className="chat__message-status chat__message--green">{__("chat.status.message-sent")}</p>
                    </div>
                )}
                {props.self && props.status === ChatStatusTypes.FAILED && (
                    <button className="chat__message-status" onClick={props.retry}>
                        <RefreshIcon className="chat__message-status chat__message--red" fontSize="inherit" />
                        <p className="chat__message-status chat__message--red">{__("chat.status.retry-message")}</p>
                    </button>
                )}
            </div>
        </div>
    )
}

export default Message

