import classNames from "classnames"
import React from "react"
import { InputProps } from "../TextInput"

export const EmailInput: React.FC<InputProps> = ({ error, Icon, label = __("common.dictionary.email"), ...props }) => {

    const inputClassNames = classNames("input input--email", { "input--has--icon": !!Icon },
        "input input--email", { "input--has--error": !!error })

    const labelClassNames = classNames("input__label input__label--email",
        { "input__label input__label--error": !!error })

    const iconClassNames = classNames("input__wrapper__icon",
        { "input__wrapper__icon--error": !!error })

    return (
        <div className="input__wrapper input__wrapper--email">
            {label && <label className={labelClassNames}>{label}</label>}
            <div className="input__wrapper--inner">
                <input className={inputClassNames} type="email" {...props} />
                {Icon && <Icon className={iconClassNames} />}
            </div>
            {error && <span className="input__wrapper--error">{error}</span>}
        </div>
    )
}
