import React from "react"
import Modal, { FormActions, ModalBody, ModalFooter, ModalHeader } from "@/components/Modal"
import { icons } from "@/config/icons"
import { TextInput } from "@/components/Inputs/TextInput"
import Button from "@/components/Button"
import SubmitButton from "@/components/SubmitButton"
import { AxiosError } from "axios"
import { VaultModalProps } from "@/views/VaultServiceView/types"
import { useVaultContext } from "@/components/Vault/Views/DefaultServiceView"
import Heading from "@/components/Heading"

const EditVaultItemModal: React.FC<VaultModalProps> = ({ closeModal, id, title, type }) => {
    const { editItem } = useVaultContext()
    const nameOfFile = title.includes(".") ? title.substring(0, title.indexOf(".")) : title
    const [name, setName] = React.useState(nameOfFile)
    const [error, setError] = React.useState("")
    const [isSubmitting, setIsSubmitting] = React.useState(false)

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault()
        setError("")
        setIsSubmitting(true)

        try {
            const newTitle = type === "folder"
                ? name
                : `${name}.${type}`

            await editItem(id, newTitle, type)
            closeModal()
        } catch (e) {
            const error = e as AxiosError
            const errorMessage = error.code === "422"
                ? error.message
                : __("common.errors.network.unknown")

            setError(errorMessage)
            setIsSubmitting(false)
        }
    }

    return (
        <Modal closeModal={closeModal}>
            <form onSubmit={handleSubmit}>
                <ModalHeader>
                    <Heading level={2} icon={icons.editItem} title={__("common.dictionary.change_name")} />
                </ModalHeader>
                <ModalBody>
                    <TextInput
                        value={name}
                        label={__("common.dictionary.name")}
                        onChange={(e) => setName(e.target.value)}
                        error={error}
                        placeholder={__("vault.placeholder.folder-name")} />
                </ModalBody>
                <ModalFooter>
                    <FormActions>
                        <Button type="button" variant="secondary" onClick={closeModal}>
                            {__("common.dictionary.cancel")}
                        </Button>
                        <SubmitButton isSubmitting={isSubmitting}>
                            {__("common.dictionary.save")}
                        </SubmitButton>
                    </FormActions>
                </ModalFooter>
            </form>
        </Modal>
    )
}

export default EditVaultItemModal
