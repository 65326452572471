import { IExternalProfile } from "@/contexts/ApplicationContext/types"

export enum ApplicationContextActionType {
    SetExternalAuthDetails = "auth/external",
    Initialise = "system/init",
}

interface ISetExternalAuthDetailsAction {
    type: ApplicationContextActionType.SetExternalAuthDetails
    payload?: IExternalProfile
}

export const setExternalAuthDetailsAction = (payload?: IExternalProfile): ISetExternalAuthDetailsAction => ({
    type: ApplicationContextActionType.SetExternalAuthDetails,
    payload,
})

interface IInitialiseAction {
    type: ApplicationContextActionType.Initialise
}

export const initialiseAppAction = (): IInitialiseAction => ({
    type: ApplicationContextActionType.Initialise,
})

export type ApplicationReducerAction =
    | ISetExternalAuthDetailsAction
    | IInitialiseAction
