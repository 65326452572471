import React from "react"
import classnames from "classnames"

type ISectionProps = React.ComponentProps<"section">

const Section: React.FC<ISectionProps> = ({ children, className, ...props }) => {
    const classes = classnames("section", className, {})

    return (
        <section className={classes} {...props}>
            {children}
        </section>
    )

}

export default Section
