import axios, { AxiosResponse, AxiosStatic } from "axios"
import Echo from "laravel-echo"
import Pusher, { Channel } from "pusher-js"
import * as Sentry from "@sentry/react"
import { BrowserTracing } from "@sentry/tracing"
import I18n from "./vendor/I18n"
import { TranslationKeys } from "./config/translations"

type I18NWrapperFn = (key: TranslationKeys, count?: number | Record<string, string | number>, o?: Record<string, string | number>) => string

declare global {
    interface Window {
        Axios: AxiosStatic
        Pusher: Pusher
        Echo: Echo
        I18n: I18n
        __: I18NWrapperFn
        pusher_app_key: string
        pusher_app_cluster: string
        translations: unknown
        upload_limit: number
        site_url: string
        sentry_dsn: string
        sentry_environment: string
        sentry_traces_sample_rate: number
        fbq: undefined | ((eventType: string, eventName: string) => void)
    }

    const Axios: AxiosStatic
    const Pusher: Pusher
    const Echo: Echo
    const I18n: I18n
    const __: I18NWrapperFn
}

/**
 * Sentry
 */
Sentry.init({
    dsn: window.sentry_dsn,
    environment: window.sentry_environment,
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: window.sentry_traces_sample_rate,
})

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.Axios = axios
window.Axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest"

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

window.Pusher = require("pusher-js")

// @TODO probably lift this into App as a context
window.Echo = new Echo({
    broadcaster: "pusher",
    key: window.pusher_app_key,
    cluster: window.pusher_app_cluster,
    wsHost: window.location.hostname,
    wssHost: window.location.hostname,
    enabledTransports: ["ws", "wss"],
    wsPort: 6001,
    wssPort: 6001,
    forceTLS: true,
    disableStats: true,
    authorizer: (channel: Channel) => {
        return {
            authorize: (socketId: string, callback: (a: boolean, b: AxiosResponse) => void) => {
                axios.post("/broadcasting/auth", {
                    socket_id: socketId,
                    channel_name: channel.name,
                })
                    .then(response => {
                        callback(false, response.data)
                    })
                    .catch(error => {
                        callback(true, error)
                    })
            },
        }
    },
})

/**
 * Translation service
 */
window.I18n = new I18n()
window.__ = (key: TranslationKeys, countOrOption?: Record<string, string | number> | number, options?: Record<string, string | number>) => {
    const translation = key.split(".").reduce((o:never, i: string) => o && o[i], window["translations"] as never)

    if (!translation) {
        let extra = ""

        if (countOrOption) {
            let args: Record<string, string | number>
            if (typeof countOrOption === "number") {
                args = options ? options : {}
                args["count"] = countOrOption
            } else {
                args = countOrOption
            }

            extra = "\xa0with:\xa0" + Object.keys(args).join(",\xa0")
        }

        return `{\xa0${key}${extra}\xa0}`
    }

    if (typeof countOrOption === "number") {
        if (!options) {
            options = {}
        }
        options["count"] = countOrOption
        return window.I18n.trans_choice(key, countOrOption, options)
    } else {
        return window.I18n.trans(key, countOrOption)
    }
}
