import React from "react"

interface NumberCircleProps {
  currentStep: number
  width: number
  height: number
}

const NumberCircle: React.FC<NumberCircleProps> = ({ currentStep, width, height }) => {
    const textLength = currentStep.toString().length

    const xCoordinate = 16 - textLength
    const yCoordinate = 16 + textLength*2
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 32 32" fill="none">
            <circle cx="16" cy="15.9998" r="15.3196" fill="black" />
            <text x={xCoordinate} y={yCoordinate} textAnchor="middle" alignmentBaseline="middle" fill="white" fontSize="24">
                {currentStep}
            </text>
        </svg>
    )}

export default NumberCircle