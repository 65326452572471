import React from "react"
import classnames from "classnames"
import { StepIcon } from "@mui/material"
import CircularProgress from "@mui/material/CircularProgress"
import CheckIcon from "@mui/icons-material/CheckRounded"

interface StepButtonProps {
    title: string
    index: number
    progress: number
    active?: boolean
    compact?: boolean
}

const StepButton: React.FC<StepButtonProps> = ({ title, index, progress, active = false, compact = false }) => {

    let cn = classnames("step-button", {
        "step-button--active": active,
        "step-button--compact": compact,
    })

    const statusSize = compact ? 32 : 40
    const strokeWidth = 3 / statusSize * 44

    const stepIconSx = {
        width: "auto",
        height: "auto",
        fill: "none",
        color: "inherit",

        ".MuiStepIcon-text": {
            fill: "currentColor",
            fontFamily: "inherit",
            fontWeight: 600,
        },
    }

    let status = null
    if (progress == 0) {
        // Not started
        const stepIconInvertedSx = stepIconSx
        stepIconInvertedSx.fill = "currentColor"
        stepIconInvertedSx[".MuiStepIcon-text"] = {
            ...stepIconSx[".MuiStepIcon-text"],
            ...{
                fill: "white",
                stroke: "var(--gray-800)",
                strokeWidth: "2px",
                strokeLinejoin: "round",
                paintOrder: "stroke",
            },
        }

        status = (
            <span className="step-button__status">
                <StepIcon icon={index} sx={stepIconInvertedSx} />
            </span>
        )
    } else if (progress < 1) {
        // Underway
        status = (
            <span className="step-button__status">
                <CircularProgress
                    value={100}
                    variant="determinate"
                    classes={{ circle: "gray" }}
                    size={statusSize}
                    thickness={strokeWidth} />
                <CircularProgress
                    value={progress * 100}
                    variant="determinate"
                    sx={{ color: "inherit" }}
                    size={statusSize}
                    thickness={strokeWidth} />
                <StepIcon icon={index} sx={stepIconSx} />
            </span>
        )
        cn += " step-button--in-progress"
    } else {
        // Done
        status = (
            <span className="step-button__status"><CheckIcon /></span>
        )
        cn += " step-button--done"
    }

    return (
        <div className={cn}>
            {status}
            <span className="step-button__name">{title}</span>
        </div>
    )
}

export default StepButton
