import React from "react"
import { IInformationStep } from "@/components/Guide/types"
import StepLayout from "@/components/Guide/Steps/StepLayout"
import AccordionItem from "@/components/AccordionItem"
import Checkbox from "@/components/Inputs/Checkbox"
import { useGuideResponseMutation } from "@/api/guide"
import { useGuideResponseValue } from "@/contexts/GuideContext/utils"
import Prose from "@/components/Prose"
import Accordion from "@/components/Accordion"
import { renderHtml } from "@/components/Guide/utils"

const InformationStep: React.FC<IInformationStep> = (props) => {
    const guideResponseMutation = useGuideResponseMutation()
    const defaultChecked = useGuideResponseValue(`${props.id}.confirm`)
    const [checked, setChecked] = React.useState(!!defaultChecked)

    React.useEffect(() => {
        if (defaultChecked === checked) return
        guideResponseMutation.mutate({ [props.id]: { confirm: checked } })
    }, [checked])

    const hasAction = !!props.confirmation_label || !!props.cta

    return (
        <StepLayout {...props}>
            <Accordion>
                {props.entries.map((entry, index) => (
                    <AccordionItem key={index} title={entry.title}>
                        <Prose>
                            {renderHtml(entry.text)}
                        </Prose>
                    </AccordionItem>
                ))}
            </Accordion>
            {hasAction && (
                <div className="step__actions mt-15">
                    {props.confirmation_label && (
                        <Checkbox
                            label={props.confirmation_label}
                            onChange={() => setChecked(!checked)}
                            checked={checked} />
                    )}
                    {props.cta && (
                        <a
                            className="button button--filled"
                            href={props.cta.url}
                            target="_blank"
                            rel="noreferrer">
                            {props.cta.title}
                        </a>
                    )}
                </div>
            )}
        </StepLayout>
    )
}

export default InformationStep
