/** CHAT REDUCER **/

import { Actions, ChatActionTypes } from "@/contexts/ChatContext/actionTypes"
import { initialState, IState } from "@/contexts/ChatContext/types"

const chatReducer = (state: IState, action: Actions) => {
    switch (action.type) {

    case ChatActionTypes.IS_FETCHING_PREVIOUS_MESSAGES:
        return {
            ...state, isFetchingPreviousMessages: action.payload,
        }

    case ChatActionTypes.GOT_NEW_MESSAGE:
        return {
            ...state, gotNewMessage: action.payload,
        }

    case ChatActionTypes.NEXT_QUERY_ID:
        return {
            ...state, nextQueryId: action.payload,
        }

    case ChatActionTypes.RETRIEVED_MESSAGES:
        return {
            ...state, retrievedMessages: [...action.payload, ...state.retrievedMessages],
        }

    case ChatActionTypes.ADD_SINGLE_MESSAGE:
        return {
            ...state, retrievedMessages: [...state.retrievedMessages, action.payload],
        }

    case ChatActionTypes.FILTER_RETRIEVED_MESSAGES:
        return {
            ...state, retrievedMessages: action.payload,
        }

    case ChatActionTypes.SHOULD_SCROLL_DOWN:
        return {
            ...state, shouldScrollDown: action.payload,
        }

    case ChatActionTypes.RECEIVED_NEW_MESSAGE: {
        return {
            ...state, receivedNewMessage: action.payload,
        }
    }

    case ChatActionTypes.IS_SENDING_NEW_MESSAGE: {
        return {
            ...state, isSendingMessage: action.payload,
        }
    }

    case ChatActionTypes.USER_SCROLLING_WHILE_RECEIVING_MESSAGE: {
        return {
            ...state, userScrollingWhileReceivingMessage: action.payload,
        }
    }

    case ChatActionTypes.GOT_NEW_MESSAGES_SINCE_LAST_LOGGED_IN: {
        return {
            ...state, gotNewMessagesSinceLastLoggedIn: action.payload,
        }
    }

    case ChatActionTypes.LAST_READ_MESSAGE_ID: {
        return {
            ...state, lastReadMessageId: action.payload,
        }
    }

    case ChatActionTypes.UNSEEN_MESSAGES_AMOUNT: {
        return {
            ...state, unseenMessagesAmount: action.payload,
        }
    }

    case ChatActionTypes.ADD_UNSEEN_MESSAGE: {
        return {
            ...state, unseenMessagesAmount: state.unseenMessagesAmount + action.payload,
        }
    }

    case ChatActionTypes.TOGGLE_CHAT: {
        return {
            ...state, open: !state.open,
        }
    }

    case ChatActionTypes.INITIALIZED: {
        return {
            ...state, initialized: action.payload,
        }
    }

    case ChatActionTypes.LOADING_MESSAGES: {
        return {
            ...state, loading: action.payload,
        }
    }

    case ChatActionTypes.CHAT_USER_SCROLLING: {
        return {
            ...state, isUserScrolling: action.payload,
        }
    }

    case ChatActionTypes.CHAT_UPDATE_SCROLL_INDEX_RANGE: {
        return {
            ...state, range: action.payload,
        }
    }

    case ChatActionTypes.CHAT_RESET: {
        return { ...initialState }
    }

    default:
        return state
    }
}

export {
    chatReducer,
}

