import { useIsProactiveWorkspace } from "@/api/user"
import ReactGA from "react-ga4"

export const GA = (category:string, action:string, label:string) => {
    ReactGA.event({
        category: category,
        action: action,
        label: label,
        value: 1,
    })
}

export const GACards = (cardType: string) => {
    const isProactiveWorkspace = useIsProactiveWorkspace()
    switch (cardType) {
    case "credentials":
        ReactGA.event({
            category: "button",
            action: "Clicked the button",
            label: isProactiveWorkspace ? "HEB: Password" : "DV: Password",
            value: 1,
        })
        break
    case "greeting":
        ReactGA.event({
            category: "button",
            action: "Clicked the button",
            label: isProactiveWorkspace ? "HEB: Moment of life" :"DV: Moment of life",
            value: 1,
        })
        break
    case "banking":
        ReactGA.event({
            category: "button",
            action: "Clicked the button",
            label: isProactiveWorkspace ? "HEB: Bank papper" : "DV: Bank papper",
            value: 1,
        })
        break
    case "insurances":
        ReactGA.event({
            category: "button",
            action: "Clicked the button",
            label: isProactiveWorkspace ? "HEB: Insurances" : "DV: Insurances",
            value: 1,
        })
        break
    case "properties":
        ReactGA.event({
            category: "button",
            action: "Clicked the button",
            label: isProactiveWorkspace ? "HEB: Housing documents" :"DV: Housing documents",
            value: 1,
        })
        break
    case "vehicles":
        ReactGA.event({
            category: "button",
            action: "Clicked the button",
            label: isProactiveWorkspace ? "HEB: Vehicle" :"DV: Vehicle",
            value: 1,
        })
        break
    case "other":
        ReactGA.event({
            category: "button",
            action: "Clicked the button",
            label: isProactiveWorkspace ? "HEB: Other documents" :"DV: Other documents",
            value: 1,
        })
        break
    default:
        break
    }
}