import React from "react"
import classnames from "classnames"

import Container from "@/components/Container"
import { INotification, NotificationStatus } from "@/contexts/NotificationsContext/types"
import { useDismissNotification } from "@/contexts/NotificationsContext"
import { icons } from "@/config/icons"
import { Close } from "@mui/icons-material"
import { useVaultItemCount } from "@/api/vault"
import ReactGA from "react-ga4"
import { useIsProactiveWorkspace } from "@/api/user"


const NotificationBar: React.FC<INotification> = ({ status,Content,  notificationId }) => {
    const dismiss = useDismissNotification()
    const itemCount = useVaultItemCount()
    const isProactiveWorkspace = useIsProactiveWorkspace()
    const classes = classnames("notification-bar", {
        "notification-bar--error": status === NotificationStatus.ERROR,
        "notification-bar--warning": status === NotificationStatus.WARNING,
        "notification-bar--info": status === NotificationStatus.INFO,
        "notification-bar--success": status === NotificationStatus.SUCCESS,
    })

    return (
        <div className={classes}>
            <Container>
                <div className="notification-bar__inner-wrapper">
                    {icons[status]}
                    <div className="notification-bar__content">
                        <p><a href={Content.props.href}>{itemCount + " / 5 Dokument uppladdade. Uppgradera till Premium nu! "}</a></p>
                    </div>
                    <button
                        className="notification-bar__close-button"
                        onClick={() => {
                            dismiss(notificationId)
                            ReactGA.event({
                                category: "button",
                                action: "Clicked the button",
                                label: isProactiveWorkspace ? "HEB: Close ingen prenumeration i notification-bar" :"DV: Close ingen prenumeration i notification-bar",
                                value: 1,
                            })

                        }}>
                        <Close />
                    </button>
                </div>
            </Container>
        </div>
    )

}

export default NotificationBar
