import { useMutation, useQuery, useQueryClient } from "react-query"
import { IChapter } from "@/components/Guide/types"

export enum GuideQuery {
    GetStepTemplate = "get-step-template",
    GetGuideStatus = "get-guide-status",
    GetGuideResponse = "get-guide-response",
    PatchGuideResponse = "patch-guide-response",
}


/**
 * Get a single chapter JSON-template
 * GET /api/guide/chapterId/{chapterId}/template
 */

const getChapterTemplate = async (chapterId: string | number): Promise<IChapter> => {
    const { data } = await Axios.get<IChapter>(`/api/guide/chapters/${chapterId}/template`)
    return data
}

export const useGetChapterTemplate = (chapterId: string | number) => {
    return useQuery(
        [GuideQuery.GetStepTemplate, chapterId],
        () => getChapterTemplate(chapterId),
        {
            refetchOnWindowFocus: false,
            staleTime: Infinity,
        }
    )
}


/**
 * Get current guide chapters progress.
 * GET /api/guide/status
 */

export interface IChapterStatus {
    title: string
    progress: number
    index: number
}

export interface IGuideStatusData {
    chapters: IChapterStatus[]
}

const getGuideStatus = async (): Promise<IGuideStatusData> => {
    const { data } = await Axios.get("/api/guide/status")
    return data
}

export const useGetGuideStatus = () => {
    return useQuery(
        GuideQuery.GetGuideStatus,
        getGuideStatus,
        {
            refetchOnWindowFocus: false,
            staleTime: 1000 * 60 * 5,
        }
    )
}


/**
 * Get current workspace guide responses
 * GET /api/guide/response
 */

export interface IGuideResponseData {
    [key: string]: never
}

const getGuideResponse = async (): Promise<IGuideResponseData> => {
    const { data } = await Axios.get("/api/guide/response")
    return data
}

export const useGuideResponse = () => {
    return useQuery(
        GuideQuery.GetGuideResponse,
        getGuideResponse,
        {
            refetchOnWindowFocus: false,
            staleTime: 1000 * 60 * 5,
        }
    )
}


/**
 * Patch current workspace guide responses
 * PATCH /api/guide/response
 */

interface IPatchGuideRequestBody {
    [key: string]: unknown
}

const patchGuideResponse = (body: IPatchGuideRequestBody) => {
    return Axios.patch("/api/guide/response", body)
}

export const useGuideResponseMutation = () => {
    const queryClient = useQueryClient()

    return useMutation(
        GuideQuery.PatchGuideResponse,
        (body: IPatchGuideRequestBody) => patchGuideResponse(body),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(GuideQuery.GetGuideResponse)
                queryClient.invalidateQueries(GuideQuery.GetGuideStatus)
            },
        }
    )
}
