import React from "react"
import classnames from "classnames"

export interface HeadingProps {
    icon?: JSX.Element
    title: string
    level?: 1 | 2 | 3
    className?: string
}

const Heading = ({ icon, title, level = 1, className="heading" }: HeadingProps) => {
    const headingClassName = classnames(className, {
        "heading--large": level === 1,
        "heading--medium": level === 2,
        "heading--small": level === 3,
    })

    return (
        <div className={headingClassName}>{icon}{title}</div>
    )
}

export default Heading
