import { MailOutline } from "@mui/icons-material"
import React from "react"

const ReactTo = () => {
    return (
        <div className="react-to">
            <h1>Tipsa vidare & tyck till Fernita</h1>
            <div className="react-to-button-grid">
                <a className="button button--rounded button--dark-green" href="mailto:?subject=Tips!"><MailOutline /> TIPSA EN VÄN </a>
                <a className="button button--rounded button--white button--outlined" href="mailto:support@fernita.se?subject=Hej Fernita!"><MailOutline /> HÖR AV DIG TILL OSS </a>
            </div>
        </div>
    )
}

export default ReactTo