import React from "react"
import { ISelectComponent } from "@/components/Guide/types"
import ReactSelect, { ISelectOption } from "@/components/Inputs/Select"
import { useToggleState } from "@/utilities/useToggleState"
import { renderGuideComponent } from "@/components/Guide/utils"
import { useGuideResponseValue } from "@/contexts/GuideContext/utils"

const Select: React.FC<ISelectComponent> = (props) => {
    const responseValue = useGuideResponseValue(props.id)
    const options = Object.entries(props.options).map(([value, label]) => ({ value, label }))

    if (props.other_choice) {
        options.push({ value: "others", label: props.other_choice.label })
    }

    const hasValue = !!responseValue
    const isStringValue = typeof responseValue === "string"
    const defaultValue = hasValue
        ? isStringValue ? options.find(option => option.value === responseValue) : options[options.length - 1]
        : undefined

    const [showAlternativeInputs, toggleAlternativeInputs] = useToggleState(hasValue && !isStringValue)

    const handleChange = (option: unknown) => {
        if (!option) {
            toggleAlternativeInputs(false)
        } else {
            const shouldShowInputs = "others" === (option as ISelectOption).value
            toggleAlternativeInputs(shouldShowInputs)
        }
    }

    return (
        <React.Fragment>
            <ReactSelect
                isClearable
                options={options}
                onChange={handleChange}
                defaultValue={defaultValue}
                name={props.id}
                label={props.label}
                required={props.required || !showAlternativeInputs} />
            {showAlternativeInputs && props.other_choice?.components.map(component =>
                React.cloneElement(
                    renderGuideComponent(component),
                    {
                        name: `${props.id}.${component.id.split(".").pop()}`,
                        id: `${props.id}.${component.id.split(".").pop()}`,
                    }
                )
            )}
        </React.Fragment>
    )
}

export default Select
