import { ICollectRequestData, ILoginRequestData, LoginFailedReason } from "@/components/BankId/types"
import Button from "@/components/Button"
import Spinner from "@/components/Spinner"
import { IExternalProfile } from "@/contexts/ApplicationContext/types"
import { UserResource } from "@/types/models/User"
import React, { useCallback, useEffect, useRef, useState } from "react"
import ReactGA from "react-ga4"
import QRCode from "react-qr-code"
import { UAParser } from "ua-parser-js"
import BankIDLogo from "resources/assets/svgs/BankID_logo_white.svg"

export interface IBankIdProps {
    onSuccess: (data: UserResource) => void
    onFailure: (reason: "no_user_found" | string, payload?: IExternalProfile) => void
    onUpdate?: (statusMessage: string) => void
}

interface GetSessionResponseData {
    user?: UserResource
    error?: LoginFailedReason
}

const BANK_ID_QR_CODE_PLACEHOLDER = "bankid.67df3917-fa0d-44e5-b327-edcc928297f8.0.dc69358e712458a66a7525beef148ae8526b1c71610eff2c16cdffb4cdac9bf8"

const BankId: React.FC<IBankIdProps> = ({ onSuccess, onFailure }) => {
    const parser = new UAParser()
    const isMobile = parser.getOS().name === "iOS" || parser.getOS().name === "Android"

    const [onSameDevice, setOnSameDevice] = useState<boolean | null>(null)
    const [message, setMessage] = useState("")
    const [bankIdOrderRef, setBankIdOrderRef] = useState("")
    const [bankIdQrCode, setBankIdQrCode] = useState("")
    const [authenticationStatus, setAuthenticationStatus] = useState<"idle" | "started" | "completed" | "failed">("idle")
    const [bankIdHref, setBankIdHref] = useState("")

    const collectIntervalRef = useRef<NodeJS.Timer | null>(null)
    const qrCodeIntervalRef = useRef<NodeJS.Timer | null>(null)

    const collect = useCallback(async () => {
        if (!bankIdOrderRef) return

        const data: ICollectRequestData = {
            legal_system_code: "SE",
            login_method_type: "bankid",
            order_ref: bankIdOrderRef,
            on_same_device: onSameDevice ?? false,
            is_mobile: isMobile,
        }

        try {
            await Axios.get("/sanctum/csrf-cookie")
            const response = await Axios.post("/api/auth/bankid/collect", data).then((res) => res.data)

            if ("message" in response ) {
                setMessage(response.message)
            }
            if ("status" in response) {
                if (response.status === "complete") {
                    if (response?.reason === "login_success") {
                        onSuccess(response?.payload)
                        ReactGA.event({
                            category: "Bank Id",
                            action: "Authentication with bankId successful",
                            label: "Sign Up",
                            value: 1,
                        })
                        setAuthenticationStatus("completed")
                    } else {
                        onFailure(response?.reason || "", response?.payload)
                        ReactGA.event({
                            category: "Bank Id",
                            action: "Authentication with bankId failed",
                            label: "Sign Up",
                            value: 1,
                        })
                        setAuthenticationStatus("failed")
                    }
                } else if (response.status === "failed") {
                    setAuthenticationStatus("failed")
                }
            }
        } catch (e) {
            ReactGA.event({
                category: "Bank Id",
                action: "Received error from bankId collect",
                label: "Sign Up",
                value: 1,
            })
            console.error(e)
            setAuthenticationStatus("failed")
        }
    }, [bankIdOrderRef, onSameDevice, isMobile])

    const getQrCode = useCallback(async (orderRef?: string) => {
        if (!(orderRef ?? bankIdOrderRef)) return

        const data = {
            order_ref : orderRef ?? bankIdOrderRef,
        }
        try {
            await Axios.get("/sanctum/csrf-cookie")
            const response = await Axios.post("/api/auth/bankid/qr", data)
            setBankIdQrCode(response.data.qr_code_data)
            ReactGA.event({
                category: "Bank Id",
                action: "Generated new BankId qr-code",
                label: "Sign Up",
                value: 1,
            })
        } catch (error) {
            ReactGA.event({
                category: "Bank Id",
                action: "Failed to generate new BankId qr-code",
                label: "Sign Up",
                value: 1,
            })
            console.error(error)
            setAuthenticationStatus("failed")
        }
    }, [bankIdOrderRef])

    useEffect(() => {
        softReset()
        if (onSameDevice !== null) startBankIDAuthentication()
    }, [onSameDevice])


    useEffect(() => {
        return handleInterval(collectIntervalRef, collect, 2000)
    }, [authenticationStatus, collect])

    useEffect(() => {
        if (onSameDevice) return

        return handleInterval(qrCodeIntervalRef, getQrCode, 1000)
    }, [authenticationStatus, getQrCode])

    useEffect(() => {
        const onVisibilityChange = async () => {
            if (document.hidden) {
                return
            }

            const { data } = await window.Axios.get<GetSessionResponseData>("/api/auth/session")

            if (data.user) {
                onSuccess(data.user)
            } else if (data.error) {
                onFailure(data.error)
            }
        }

        document.addEventListener("visibilitychange", onVisibilityChange)

        return () => {
            document.removeEventListener("visibilitychange", onVisibilityChange)
        }
    }, [])

    const softReset = () => {
        bankIdOrderRef && cancel(bankIdOrderRef)
        setMessage("")
        setBankIdOrderRef("")
        setBankIdQrCode("")
    }

    const hardReset = () => {
        softReset()
        setAuthenticationStatus("idle")
        collectIntervalRef.current && clearTimeout(collectIntervalRef.current)
        collectIntervalRef.current = null
        qrCodeIntervalRef.current && clearTimeout(qrCodeIntervalRef.current)
        qrCodeIntervalRef.current = null
    }

    const handleInterval = (ref: React.MutableRefObject<NodeJS.Timer | null>, fn: () => void, delay: number) => {
        if (authenticationStatus === "started") {
            if (!ref.current) {
                ref.current = setInterval(() => {
                    fn()
                }, delay)
            }
        } else {
            ref.current && clearTimeout(ref.current)
            ref.current = null
        }
        return () => {
            ref.current && clearTimeout(ref.current)
            ref.current = null
        }
    }


    const startBankIDAuthentication = async () => {
        const data: ILoginRequestData = {
            legal_system_code: "SE",
            login_method_type: "bankid",
        }

        try {
            await Axios.get("/sanctum/csrf-cookie")
            const response = await Axios.post("/api/auth/bankid/init", data)
            setBankIdOrderRef(response.data.orderRef)
            setAuthenticationStatus("started")
            await getQrCode(response.data.orderRef)

            if (onSameDevice) {
                const redirect = encodeURIComponent(window.location.href + "#redirect")

                const link = document.createElement("a")

                const href = `bankid:///?autostarttoken=${response.data.autoStartToken}&redirect=${redirect}`

                link.href = href
                link.referrerPolicy = "origin"
                link.click()
                link.remove()
                console.log("da href", href)
                setBankIdHref(href)

                setMessage(__("bankid.RFA13"))

                ReactGA.event({
                    category: "Bank Id",
                    action: "BankId authentication started",
                    label: "Sign Up",
                    value: 1,
                })
            } else {
                setMessage(__("bankid.RFA18"))
            }


        } catch (error) {
            setAuthenticationStatus("failed")
            ReactGA.event({
                category: "Bank Id",
                action: "Unable to start authentication with bankId",
                label: "Sign Up",
                value: 1,
            })
        }

    }

    const cancel = async (orderRef: string) => {
        if (!orderRef) return
        const data = {
            order_ref : orderRef,
        }
        try {
            await Axios.get("/sanctum/csrf-cookie")
            await Axios.post("/api/auth/bankid/cancel", data)
        } catch (error) {
            console.error(error)
        }
    }

    const handleCurrentDevice = (current: boolean) => {
        setOnSameDevice(current)
        if (current) {
            ReactGA.event({
                category: "Current device",
                action: "Clicked bankId on current device",
                label: "Sign Up",
                value: 1,
            })
        } else {
            ReactGA.event({
                category: "Other device",
                action: "Clicked bankId on other device",
                label: "Sign Up",
                value: 1,
            })
        }
    }


    const renderCurrentDeviceView = () => {
        return (
            <React.Fragment>
                {authenticationStatus === "started" && <Spinner />}
                <p>{message}</p>
                {bankIdHref && (
                    <a className="button button--rectangle" href={bankIdHref} referrerPolicy="origin">
                        Öppna BankID manuellt
                    </a>
                )}
                <Button wide className="button--rectangle button--white" onClick={() => handleCurrentDevice(false)}>
                    Använd Mobilt BankID på en annan enhet
                </Button>
            </React.Fragment>
        )
    }

    const renderOtherDeviceView = () => {
        return (
            <React.Fragment>
                <p>
                    Öppna BankID-appen och scanna QR-koden nedan.
                </p>
                <div className="bankid__qr-wrapper">
                    <QRCode
                        size={200}
                        style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                        value={bankIdQrCode || BANK_ID_QR_CODE_PLACEHOLDER}
                        viewBox="0 0 250 250" />
                </div>
                <p>{message || "Laddar..."}</p>
                <Button wide className="button--rectangle" onClick={() => handleCurrentDevice(true)}>
                    Använd BankID på den här enheten
                </Button>
            </React.Fragment>
        )
    }

    const renderMobileLandingContent = () => {
        return (
            <React.Fragment>
                <BankIDLogo className="bankid__logo" />
                <p>Vill du identifiera dig eller skriva under med ett BankID på den här enheten eller med ett BankID på en annan enhet?</p>
                <Button wide className="button--rectangle" onClick={() => handleCurrentDevice(true)}>
                    BankID på den här enheten
                </Button>
                <Button wide className="button--rectangle" onClick={() => handleCurrentDevice(false)}>
                    Mobilt BankID på en annan enhet
                </Button>
            </React.Fragment>
        )
    }

    const renderDesktopLandingContent = () => {
        return (
            <React.Fragment>
                <BankIDLogo className="bankid__logo" />
                <p>Vill du identifiera dig eller skriva under med BankID på den här datorn eller med ett Mobilt BankID?</p>
                <Button wide className="button--rectangle" onClick={() => handleCurrentDevice(false)}>
                    Mobilt BankID på en annan enhet
                </Button>
                <Button wide className="button--rectangle button--white" onClick={() => handleCurrentDevice(true)}>
                    BankID på den här enheten
                </Button>
            </React.Fragment>
        )
    }

    const renderFailedContent = () => {
        return (
            <React.Fragment>
                <BankIDLogo className="bankid__logo" />
                <p>Sessionen har gått ut, eller så har ett fel inträffat.</p>
                <p>{message}</p>
                <Button
                    wide className="button--rectangle" onClick={() => {
                        setOnSameDevice(null)
                        hardReset()
                    }}>
                    Börja om
                </Button>
            </React.Fragment>
        )
    }

    const renderSuccessContent = () => {
        return (
            <React.Fragment>
                <BankIDLogo className="bankid__logo" />
                <Spinner />
                <p>Identifiering lyckades</p>
            </React.Fragment>
        )
    }

    const renderContents = () => {
        if (authenticationStatus === "started") {
            if (onSameDevice) {
                return renderCurrentDeviceView()
            } else {
                return renderOtherDeviceView()
            }
        } else if (authenticationStatus === "failed") {
            return renderFailedContent()
        } else if (authenticationStatus === "completed") {
            return renderSuccessContent()
        } else if (isMobile) {
            return renderMobileLandingContent()
        } else {
            return renderDesktopLandingContent()
        }
    }

    return (
        <div className="bankid">
            {renderContents()}
        </div>
    )
}

export default BankId
