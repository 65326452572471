import React, { forwardRef, JSXElementConstructor, useEffect, useRef } from "react"

interface Props {
    children: React.ReactElement<unknown, string | JSXElementConstructor<unknown>>
}

const FocusTrapWrapper: React.FC<Props> = forwardRef(({ children }, ref) => {

    const _ref = useRef<HTMLElement>()
    const realRef = ref ?? _ref


    useEffect(() => {
        if (!_ref.current) return
        const modal = _ref.current

        const focusableElements =
            "button, [href], input, select, CloseIcon, textarea, [tabindex]:not([tabindex=\"-1\"])"


        const focusableContent = modal.querySelectorAll<HTMLElement>(focusableElements)
        if (!focusableContent.length) return

        const firstFocusableElement = focusableContent[0]
        const lastFocusableElement = focusableContent[focusableContent.length - 1]

        const trapFocus = function (e: KeyboardEvent): void {
            const isTabPressed = e.key === "Tab" || e.keyCode === 9

            if (!isTabPressed) {
                return
            }

            if (e.shiftKey) {
                if (document.activeElement === firstFocusableElement) {
                    lastFocusableElement.focus()
                    e.preventDefault()
                }
            } else {
                if (document.activeElement === lastFocusableElement) {
                    firstFocusableElement.focus()
                    e.preventDefault()
                }
            }
        }
        document.addEventListener("keydown", trapFocus)

        return () => {
            document.removeEventListener("keydown", trapFocus)
        }
    }, [])

    return React.cloneElement(children, { ref: realRef })


})
FocusTrapWrapper.displayName = "FocusTrapWrapper"
export default FocusTrapWrapper


