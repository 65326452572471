/** ACTION TYPES **/
import { IMessage, IScrollRange } from "./types"

enum ChatActionTypes {
    IS_FETCHING_PREVIOUS_MESSAGES = "IS_FETCHING_PREVIOUS_MESSAGES",
    GOT_NEW_MESSAGE = "GOT_NEW_MESSAGE",
    NEXT_QUERY_ID = "NEXT_QUERY_ID",
    RETRIEVED_MESSAGES = "RETRIEVED_MESSAGES",
    ADD_SINGLE_MESSAGE = "ADD_SINGLE_MESSAGE",
    FILTER_RETRIEVED_MESSAGES = "FILTER_RETRIEVED_MESSAGES",
    SHOULD_SCROLL_DOWN = "SHOULD_SCROLL_DOWN",
    RECEIVED_NEW_MESSAGE = "RECEIVED_NEW_MESSAGE",
    IS_SENDING_NEW_MESSAGE = "IS_SENDING_NEW_MESSAGE",
    USER_SCROLLING_WHILE_RECEIVING_MESSAGE = "USER_SCROLLING_WHILE_RECEIVING_MESSAGE",
    GOT_NEW_MESSAGES_SINCE_LAST_LOGGED_IN = "GOT_NEW_MESSAGES_SINCE_LAST_LOGGED_IN",
    LAST_READ_MESSAGE_ID = "LAST_READ_MESSAGE_ID",
    UNSEEN_MESSAGES_AMOUNT = "UNSEEN_MESSAGES_AMOUNT",
    ADD_UNSEEN_MESSAGE = "ADD_UNSEEN_MESSAGE",
    TOGGLE_CHAT = "TOGGLE_CHAT",
    LOADING_MESSAGES = "LOADING_MESSAGES",
    CHAT_RESET = "CHAT_RESET",
    INITIALIZED = "INITIALIZED",
    CHAT_USER_SCROLLING = "CHAT_USER_SCROLLING",
    CHAT_UPDATE_SCROLL_INDEX_RANGE = "CHAT_UPDATE_SCROLL_INDEX_RANGE"
}

interface IIsFetchingNewMessages {
    type: ChatActionTypes.IS_FETCHING_PREVIOUS_MESSAGES
    payload: boolean
}

const isFetchingNewMessages = (value: boolean): IIsFetchingNewMessages => ({
    type: ChatActionTypes.IS_FETCHING_PREVIOUS_MESSAGES,
    payload: value,
})

interface IUpdateScrollRange {
    type: ChatActionTypes.CHAT_UPDATE_SCROLL_INDEX_RANGE
    payload: IScrollRange
}

const setNewScrollRange = (value: IScrollRange): IUpdateScrollRange => ({
    type: ChatActionTypes.CHAT_UPDATE_SCROLL_INDEX_RANGE,
    payload: value,
})

interface IGotNewMessage {
    type: ChatActionTypes.GOT_NEW_MESSAGE
    payload: boolean
}

const iGotNewMessage = (value: boolean): IGotNewMessage => ({
    type: ChatActionTypes.GOT_NEW_MESSAGE,
    payload: value,
})

interface INextQueryId {
    type: ChatActionTypes.NEXT_QUERY_ID
    payload: number | undefined
}

const setNextQueryId = (value: number | undefined): INextQueryId => ({
    type: ChatActionTypes.NEXT_QUERY_ID,
    payload: value,
})

interface IRetrievedMessages {
    type: ChatActionTypes.RETRIEVED_MESSAGES
    payload: IMessage[]
}

const setRetrievedMessages = (value: IMessage[]): IRetrievedMessages => ({
    type: ChatActionTypes.RETRIEVED_MESSAGES,
    payload: value,
})

interface IAddSingleMessage {
    type: ChatActionTypes.ADD_SINGLE_MESSAGE
    payload: IMessage
}

const setSingleMessageToRetrievedMessages = (value: IMessage): IAddSingleMessage => ({
    type: ChatActionTypes.ADD_SINGLE_MESSAGE,
    payload: value,
})

interface IFilterRetrievedMessages {
    type: ChatActionTypes.FILTER_RETRIEVED_MESSAGES
    payload: IMessage[]
}

const setRetrievedMessagesWithoutTempData = (value: IMessage[]): IFilterRetrievedMessages => ({
    type: ChatActionTypes.FILTER_RETRIEVED_MESSAGES,
    payload: value,
})

interface IShouldScrollDown {
    type: ChatActionTypes.SHOULD_SCROLL_DOWN
    payload: boolean
}

const shouldScrollDown = (value: boolean): IShouldScrollDown => ({
    type: ChatActionTypes.SHOULD_SCROLL_DOWN,
    payload: value,
})

interface IReceivedNewMessage {
    type: ChatActionTypes.RECEIVED_NEW_MESSAGE
    payload?: IMessage
}

const setReceivedNewMessage = (value?: IMessage): IReceivedNewMessage => ({
    type: ChatActionTypes.RECEIVED_NEW_MESSAGE,
    payload: value,
})

interface IISendingNewMessage {
    type: ChatActionTypes.IS_SENDING_NEW_MESSAGE
    payload: boolean
}

const isSendingNewMessage = (value: boolean): IISendingNewMessage => ({
    type: ChatActionTypes.IS_SENDING_NEW_MESSAGE,
    payload: value,
})

interface IUserScrollingWhileReceivingMessage {
    type: ChatActionTypes.USER_SCROLLING_WHILE_RECEIVING_MESSAGE
    payload: boolean
}

const setUserScrollingWhileReceivingMessage = (value: boolean): IUserScrollingWhileReceivingMessage => ({
    type: ChatActionTypes.USER_SCROLLING_WHILE_RECEIVING_MESSAGE,
    payload: value,
})

interface IGotNewMessagesSinceLastLoggedIn {
    type: ChatActionTypes.GOT_NEW_MESSAGES_SINCE_LAST_LOGGED_IN
    payload: boolean
}

const gotNewMessagesSinceLastLoggedIn = (value: boolean): IGotNewMessagesSinceLastLoggedIn => ({
    type: ChatActionTypes.GOT_NEW_MESSAGES_SINCE_LAST_LOGGED_IN,
    payload: value,
})

interface ILastReadMessageId {
    type: ChatActionTypes.LAST_READ_MESSAGE_ID
    payload: number | undefined
}

const setLastReadMessageId = (value: number | undefined): ILastReadMessageId => ({
    type: ChatActionTypes.LAST_READ_MESSAGE_ID,
    payload: value,
})

interface IUnseenMessagesAmount {
    type: ChatActionTypes.UNSEEN_MESSAGES_AMOUNT
    payload: number
}

const setUnseenMessagesAmount = (value: number): IUnseenMessagesAmount => ({
    type: ChatActionTypes.UNSEEN_MESSAGES_AMOUNT,
    payload: value,
})

interface IAddUnseenMessage {
    type: ChatActionTypes.ADD_UNSEEN_MESSAGE
    payload: number
}

const addUnseenMessage = (value: number): IAddUnseenMessage => ({
    type: ChatActionTypes.ADD_UNSEEN_MESSAGE,
    payload: value,
})

interface IToggleChat {
    type: ChatActionTypes.TOGGLE_CHAT
}

const toggleChat = (): IToggleChat => ({
    type: ChatActionTypes.TOGGLE_CHAT,
})

interface IChatInitialized {
    type: ChatActionTypes.INITIALIZED
    payload: boolean
}

const chatInitialized = (value: boolean): IChatInitialized => ({
    type: ChatActionTypes.INITIALIZED,
    payload: value,
})

interface ILoadingMessages {
    type: ChatActionTypes.LOADING_MESSAGES
    payload: boolean
}

const setLoadingMessages = (value: boolean): ILoadingMessages => ({
    type: ChatActionTypes.LOADING_MESSAGES,
    payload: value,
})

interface IUserScrolling {
    type: ChatActionTypes.CHAT_USER_SCROLLING
    payload: boolean
}

const setUserIsScrolling = (value: boolean): IUserScrolling => ({
    type: ChatActionTypes.CHAT_USER_SCROLLING,
    payload: value,
})

interface IResetChatState {
    type: ChatActionTypes.CHAT_RESET
}

const resetChat = (): IResetChatState => ({
    type: ChatActionTypes.CHAT_RESET,
})

type Actions =
    | IIsFetchingNewMessages
    | IUpdateScrollRange
    | IGotNewMessage
    | INextQueryId
    | IRetrievedMessages
    | IAddSingleMessage
    | IFilterRetrievedMessages
    | IShouldScrollDown
    | IReceivedNewMessage
    | IISendingNewMessage
    | IUserScrollingWhileReceivingMessage
    | IGotNewMessagesSinceLastLoggedIn
    | ILastReadMessageId
    | IUnseenMessagesAmount
    | IAddUnseenMessage
    | IToggleChat
    | IChatInitialized
    | ILoadingMessages
    | IUserScrolling
    | IResetChatState;

export {
    Actions,
    ChatActionTypes,
    isFetchingNewMessages,
    iGotNewMessage,
    setNewScrollRange,
    setNextQueryId,
    setRetrievedMessages,
    setSingleMessageToRetrievedMessages,
    setRetrievedMessagesWithoutTempData,
    shouldScrollDown,
    setReceivedNewMessage,
    isSendingNewMessage,
    setUserScrollingWhileReceivingMessage,
    gotNewMessagesSinceLastLoggedIn,
    setLastReadMessageId,
    setUnseenMessagesAmount,
    addUnseenMessage,
    toggleChat,
    chatInitialized,
    setLoadingMessages,
    setUserIsScrolling,
    resetChat,
}
