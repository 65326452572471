import React from "react"
import { IConfirmationStep } from "@/components/Guide/types"
import StepLayout from "@/components/Guide/Steps/StepLayout"
import { useGuideResponseValue } from "@/contexts/GuideContext/utils"
import { useGuideResponseMutation } from "@/api/guide"
import Checkbox from "@/components/Inputs/Checkbox"
import { useDebouncedState } from "@/utilities/useDebouncedState"

const ConfirmationStep: React.FC<IConfirmationStep> = (props) => {
    const guideResponseMutation = useGuideResponseMutation()
    const defaultChecked = useGuideResponseValue(`${props.id}.confirm`)
    const [checked, setChecked] = React.useState(!!defaultChecked)
    const debouncedChecked = useDebouncedState(checked, 300)

    React.useEffect(() => {
        if (debouncedChecked === defaultChecked) {
            return
        }

        guideResponseMutation.mutate({ [props.id]: { confirm: debouncedChecked } })
    }, [debouncedChecked])

    const hasAction = !!props.confirmation_label || !!props.cta

    return (
        <StepLayout {...props}>
            {hasAction && (
                <div className="step__actions">
                    {props.confirmation_label && (
                        <Checkbox
                            checked={checked}
                            onChange={() => setChecked(!checked)}
                            label={props.confirmation_label} />
                    )}
                    {props.cta && (
                        <a
                            className="button button--filled"
                            href={props.cta.url}
                            target="_blank"
                            rel="noreferrer">
                            {props.cta.title}
                        </a>
                    )}
                </div>
            )}
        </StepLayout>
    )
}

export default ConfirmationStep
