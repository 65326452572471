import { ModalBody, ModalFooter, ModalHeader, IModalProps } from "@/components/Modal"
import React from "react"
import Button from "@/components/Button"
import { useLogoutMutation } from "@/api/auth"
import NonClosableModal from "../../../Modal/nonClosableModal"

const IdleLogoutModal:React.FC<IModalProps> = ({ ...modalProps }) => {
    const logout = useLogoutMutation()

    const handleLogout = async () => {
        modalProps.closeModal()
        logout.mutate()
    }

    return (
        <NonClosableModal {...modalProps}>
            <ModalHeader>
            </ModalHeader>
            <ModalBody>
                Sessionen är avslutad och du behöver logga in igen.
            </ModalBody>
            <ModalFooter>
                <Button type="button" onClick={handleLogout}>
                    Logga in
                </Button>
            </ModalFooter>
        </NonClosableModal>
    )
}

export default IdleLogoutModal