import React, { useState } from "react"
import Button from "@/components/Button"
import { TextInput } from "@/components/Inputs/TextInput"
import CreateNewFolder from "@mui/icons-material/CreateNewFolder"
import SubmitButton from "@/components/SubmitButton"
import Modal, { FormActions, IModalProps, ModalBody, ModalFooter, ModalHeader } from "@/components/Modal"
import Heading from "@/components/Heading"
import { useIsProactiveWorkspace } from "@/api/user"
import { GA } from "@/utilities/googleAnalytics"

interface CreateFolderModalProps extends IModalProps {
    handleSubmit: (newFolderTitle: string) => Promise<boolean>
    folderTitle?: string
}

const CreateFolderModal: React.FC<CreateFolderModalProps> = ({ handleSubmit, ...modalProps }) => {
    const [title, setTitle] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState("")
    const isProactiveWorkspace = useIsProactiveWorkspace()
    const file = modalProps.folderTitle

    const _handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault()
        setIsLoading(true)
        setError("")

        try {
            await handleSubmit(title)
            modalProps.closeModal()
        } catch {
            // TODO: use error message based on API response
            setError(__("misc.invalid_folder_name"))
        }
    }

    const handleClose = () => {
        modalProps.closeModal
        switch (file) {
        case "greetings":
            GA("button",isProactiveWorkspace ? "HEB: Moment of life - Create folder - Cancel" :"DV: Moment of life - Create folder - Cancel ", "Clicked the button")
            break
        case "banking":
            GA("button",isProactiveWorkspace? "HEB: Bank papper - Create folder - Cancel" : "DV: Bank papper - Create folder - Cancel", "Clicked the button")
            break
        case "insurances":
            GA("button", isProactiveWorkspace ? "HEB: Insurances - Create folder - Cancel " :"DV: Insurances - Create folder - Cancel", "Clicked the button")
            break
        case "properties":
            GA("button",isProactiveWorkspace ? "HEB: Housing documents - Create folder - Cancel " :"DV: Housing documents - Create folder - Cancel", "Clicked the button" )
            break
        case "vehicles":
            GA("button",isProactiveWorkspace ? "HEB: Vehicle - Create folder - Cancel" : "DV: Vehicle - Create folder - Cancel" ,"Clicked the button" )
            break
        case "other":
            GA("button",isProactiveWorkspace ? "HEB: Other documents - Create folder - Cancel" : "DV: Other documents - Create folder - Cancel", "Clicked the button")
            break
        default:
            break
        }
    }

    const handleGAClick = () => {
        switch (file) {
        case "greetings":
            GA("button",isProactiveWorkspace ? "HEB: Moment of life - Create folder - Save" :"DV: Moment of life - Create folder - Save", "Clicked the button")
            break
        case "banking":
            GA("button",isProactiveWorkspace? "HEB: Bank papper - Create folder - Save" : "DV: Bank papper - Create folder - Save", "Clicked the button")
            break
        case "insurances":
            GA("button",isProactiveWorkspace ? "HEB: Insurances - Create folder - Save " :"DV: Insurances - Create folder - Save", "Clicked the button" )
            break
        case "properties":
            GA("button",isProactiveWorkspace ? "HEB: Housing documents - Create folder - Save " :"DV: Housing documents - Create folder - Save", "Clicked the button" )
            break
        case "vehicles":
            GA("button",isProactiveWorkspace ? "HEB: Vehicle - Create folder - Save" : "DV: Vehicle - Create folder - Save", "Clicked the button" )
            break
        case "other":
            GA("button", isProactiveWorkspace ? "HEB: Other documents - Create folder - Save" : "DV: Other documents - Create folder - Save", "Clicked the button")
            break
        default:
            break
        }
    }

    return (
        <Modal {...modalProps}>
            <ModalHeader>
                <Heading className={isProactiveWorkspace ? "" : "gray-heading"} level={2} icon={<CreateNewFolder />} title={__("vault.actions.create-folder")} />
            </ModalHeader>
            <form onSubmit={_handleSubmit}>
                <ModalBody>
                    <TextInput
                        label={__("common.dictionary.name")}
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        placeholder={__("vault.placeholder.folder-name")}
                        error={error} />
                </ModalBody>
                <ModalFooter>
                    <FormActions>
                        <Button
                            type="button"
                            variant="secondary"
                            onClick={handleClose}>
                            {__("common.dictionary.cancel")}
                        </Button>
                        <SubmitButton className={isProactiveWorkspace ? "button" : "gray-button"} isSubmitting={isLoading} onClick={handleGAClick}>
                            {__("common.dictionary.save")}
                        </SubmitButton>
                    </FormActions>
                </ModalFooter>
            </form>
        </Modal>
    )
}

export default CreateFolderModal
