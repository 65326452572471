import React from "react"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import { WorkspaceResource, UserResource } from "@/types/models/User"
import { nicknameToWorkspaceTitle } from "@/utilities"

interface UserProfileProps {
    compact?: boolean
    user: UserResource
    workspace?: WorkspaceResource | null
}

const UserProfile: React.FC<UserProfileProps> = ({ user, workspace, compact = false }) => {
    let currentWorkspaceTitle = null
    if (workspace) {
        currentWorkspaceTitle = user.id === workspace.deceased_user_id
            ? __("misc.my_workspace")
            : nicknameToWorkspaceTitle(workspace.nickname)
    }

    return (
        <div className="user-profile">
            {/* <ProfilePicture src={user.profile_picture_url} size={compact ? "medium" : "large"} isVault={a && !a.has_sole_actor && a.deceased_user_id !== (user && user.id)} /> */}
            {!compact && (
                <div className="user-profile__details">
                    {/* TODO: add method to user for getting their short name */}
                    <span className="user-profile__name"> {currentWorkspaceTitle} : {user.nickname} {user.last_name?.charAt(0)}.</span>
                    {/* {currentWorkspaceTitle && <span className="user-profile__type"></span>} */}
                </div>
            )}
            <KeyboardArrowDownIcon />
        </div>
    )
}

export default UserProfile
