import React, { useState } from "react"
import Button from "@/components/Button"
import SendOutlinedIcon from "@mui/icons-material/SendOutlined"

interface ChatMessageInputProps {
    onSubmit: (message: string) => void
}

const ChatMessageInput: React.FC<ChatMessageInputProps> = ({ onSubmit }) => {
    const [newMessage, setNewMessage] = useState("")

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault()
        if (!newMessage.length) return
        onSubmit(newMessage)
        setNewMessage("")
    }

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") handleSubmit(e)
    }

    return (
        <div className="chat__action-wrapper">
            <form className="chat__actions" onSubmit={handleSubmit}>
                <input
                    placeholder={__("chat.common.input-placeholder")}
                    onChange={(e) => setNewMessage(e.target.value)}
                    value={newMessage}
                    onKeyDown={handleKeyDown} />
                <Button type="submit" className="chat__actions__submit gray-button">
                    <SendOutlinedIcon />
                </Button>
            </form>
        </div>
    )
}

export default ChatMessageInput
