import React from "react"
import Select, { ISelectOption } from "@/components/Inputs/Select"
import { formatCredentialOptionLabel } from "@/components/Vault/Views/CredentialsServiceView/utils"
import { TextInput } from "@/components/Inputs/TextInput"
import Button from "@/components/Button"
import Modal, { FormActions, IModalProps, ModalBody, ModalFooter, ModalHeader } from "@/components/Modal"
import Heading, { HeadingProps } from "@/components/Heading"
import { TextAreaInput } from "@/components/Inputs/TextAreaInput"
import SubmitButton from "@/components/SubmitButton"
import {
    CredentialCategory,
    CredentialEntry,
    CredentialFormData, CredentialResource,
    CrudOperation
} from "@/components/Vault/Views/CredentialsServiceView/types"
import {
    credentialOptions,
    predefinedCredentialCategories,
    predefinedReactiveCredentialCategories
} from "@/components/Vault/Views/CredentialsServiceView/config"
import { useCreateCredential, useDeleteCredential, useUpdateCredential } from "@/api/credentials"
import ReadCredentialEntry from "@/components/Vault/Modals/ReadCredentialEntry"
import EditableCredentialEntry from "@/components/Vault/Modals/EditableCredentialEntry"
import { useConfirm } from "@/contexts/ConfirmContext/hooks"
import { useIsProactiveWorkspace } from "@/api/user"
import { GA } from "@/utilities/googleAnalytics"

interface CredentialFormModalProps extends IModalProps {
    op: CrudOperation
    setOp: React.Dispatch<React.SetStateAction<CrudOperation | undefined>>
    formData: CredentialFormData
    setFormData: React.Dispatch<React.SetStateAction<CredentialFormData>>
    closeModal: () => void
}

const CredentialFormModal = ({ op, setOp, formData, setFormData, closeModal }: CredentialFormModalProps) => {
    const createCredentialMutation = useCreateCredential()
    const updateCredentialMutation = useUpdateCredential()
    const deleteCredentialMutation = useDeleteCredential()
    const isProactiveWorkspace = useIsProactiveWorkspace()
    const confirm = useConfirm()

    const handleCategoryChange = (_option: unknown) => {
        const option = _option as ISelectOption
        setFormData(p => ({ ...p, category_key: option.value as CredentialCategory }))
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setFormData(p => ({ ...p, [e.target.name]: e.target.value }))
    }

    const addEntry = () => setFormData(p => ({
        ...p,
        entries: p.entries.concat({
            label: __("vault.credentials.form.new_entry_label"),
            value: "",
        }),
    }))

    const removeEntry = (entryIndex: number) => setFormData(p => ({
        ...p,
        entries: p.entries.filter((_, i) => i !== entryIndex),
    }))

    const handleEntryChange = (entryIndex: number, entry: Partial<CredentialEntry>) => setFormData(p => ({
        ...p,
        entries: p.entries.map((prevEntry, i) => i !== entryIndex ? prevEntry : { ...prevEntry, ...entry }),
    }))

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault()
        // TODO: Validate at least one entry in formData.entries
        switch (op) {
        case CrudOperation.Create:
            createCredentialMutation.mutate(formData, {
                onSuccess: () => closeModal(),
            })
            break
        case CrudOperation.Update:
            updateCredentialMutation.mutate(formData as CredentialResource, {
                onSuccess: () => closeModal(),
            })
            break
        }
    }

    const handleDelete = async () => {
        if (!formData.id) return

        const confirmed = await confirm(__("misc.confirmations.delete_item_specific", { name: formData.title }))
        if (!confirmed) return

        deleteCredentialMutation.mutate(formData.id, {
            onSuccess: () => closeModal(),
        })
    }

    const getModalTitle = React.useCallback(() => ({
        [CrudOperation.Create]: __("vault.actions.add-credentials"),
        [CrudOperation.Read]: formData.title,
        [CrudOperation.Update]: formData.title,
    }[op as CrudOperation]), [op])

    const headingProps: Partial<HeadingProps> = {}
    if (op !== CrudOperation.Create) {
        const { Icon } = isProactiveWorkspace ? predefinedCredentialCategories[formData.category_key] : predefinedReactiveCredentialCategories[formData.category_key]
        headingProps.icon = <Icon />
    }

    return (
        <Modal closeModal={closeModal}>
            <ModalHeader>
                <Heading {...headingProps} title={getModalTitle()} />
            </ModalHeader>
            <form onSubmit={handleSubmit} autoComplete="off">
                <ModalBody>
                    {op === CrudOperation.Read && <p className="mb-6">{formData.description}</p>}
                    {op !== CrudOperation.Read && (
                        <React.Fragment>
                            <Select
                                formatOptionLabel={formatCredentialOptionLabel}
                                label={__("vault.credentials.form.category_label")}
                                options={credentialOptions}
                                onChange={handleCategoryChange}
                                value={{ value: formData.category_key, label: __(`vault.credentials.categories.${formData.category_key}`) }} />
                            <TextInput
                                required
                                name="title"
                                value={formData.title}
                                label={__("vault.credentials.form.title_label")}
                                onChange={handleChange} />
                            <TextAreaInput
                                maxLength={200}
                                name="description"
                                label={__("vault.credentials.form.description_label")}
                                value={formData.description || ""}
                                onChange={handleChange} />
                        </React.Fragment>
                    )}
                    {formData.entries.map((props, idx) => op === CrudOperation.Read
                        ? (
                            <ReadCredentialEntry {...props} key={idx} />
                        ) : (
                            <EditableCredentialEntry
                                {...props}
                                key={idx}
                                onRemoveEntry={() => removeEntry(idx)}
                                onLabelChange={e => handleEntryChange(idx, { label: e.target.value })}
                                onValueChange={e => handleEntryChange(idx, { value: e.target.value })} />
                        )
                    )}
                    {op !== CrudOperation.Read && (
                        <React.Fragment>
                            <Button className={isProactiveWorkspace ? "mb-6" : "button gray-button--outlined"} size="small" outlined onClick={addEntry}>
                                {__("misc.actions.add_new_field")}
                            </Button>
                            <hr className="border border-gray-100" />
                        </React.Fragment>
                    )}
                </ModalBody>
                <ModalFooter>
                    <FormActions>
                        {op !== CrudOperation.Create && (
                            <SubmitButton className={isProactiveWorkspace ? "" : "button gray-button"} type="button" variant="danger" isSubmitting={deleteCredentialMutation.isLoading} onClick={handleDelete}>
                                {__("common.dictionary.delete")}
                            </SubmitButton>
                        )}
                        {op === CrudOperation.Read && (
                            <Button className={isProactiveWorkspace ? "" : "button gray-button"} onClick={() => setOp(CrudOperation.Update)}>
                                {__("common.actions.edit")}
                            </Button>
                        )}
                        {op !== CrudOperation.Read && (
                            <SubmitButton className={isProactiveWorkspace ? "" : "button gray-button"} isSubmitting={createCredentialMutation.isLoading || updateCredentialMutation.isLoading} onClick={() => GA("button",isProactiveWorkspace ?"HEB: Password - Add - Save" :"DV: Password - Add - Save", "Clicked the button" )}>
                                {__("common.dictionary.save")}
                            </SubmitButton>
                        )}
                    </FormActions>
                </ModalFooter>
            </form>
        </Modal>
    )
}





export default CredentialFormModal
