import React from "react"
import FernitaDefaultLogo from "resources/assets/svgs/fernita-logo-default.svg"

const FooterNavigation = () => {

    return (
        <div className="onboarding__footer-navigation">
            <div className="onboarding__footer-elements">
                <a href="https://fernita.se/tjanster/digitala-valvet/">Varför fernita?</a>
                <a href="https://fernita.se/faq/">Frågot och svar</a>
                <a href="https://fernita.se/prislista/">Prislista</a>
                <a href="https://fernita.se/om-oss-och-kontakt/">Kontakta oss</a>
            </div>
            <div className="onboarding__footer-elements onboarding__footer-elements--logo">
                <FernitaDefaultLogo />
            </div>
        </div>
    )
}

export default FooterNavigation