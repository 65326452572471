import React from "react"
import classnames from "classnames"

import CancelIcon from "@mui/icons-material/Cancel"

import { INotification } from "@/contexts/NotificationsContext/types"
import { useDismissNotification } from "@/contexts/NotificationsContext"

interface IToasterProps {
    toasts: INotification[]
}

const Toaster: React.FC<IToasterProps> = ({ toasts }) => {
    return (
        <div className="toaster">
            {toasts.map(toast => <Toast key={toast.notificationId} {...toast} />)}
        </div>
    )
}



interface IToastProps extends React.ComponentProps<"div">, INotification {
    contentProps?: Record<string, unknown>
}

const Toast: React.FC<IToastProps> = ({ Content, notificationId, className, autoClose = false, contentProps, ...props }) => {
    const [isClosing, setIsClosing] = React.useState(false)
    const dismiss = useDismissNotification()
    const classes = classnames("toast", className, { "toast--closing": isClosing })

    React.useEffect(() => {
        if (!autoClose) return
        const ref = setTimeout(() => setIsClosing(true), 5000)

        return () => clearTimeout(ref)
    }, [])

    React.useEffect(() => {
        if (!isClosing) return
        const ref = setTimeout(() => dismiss(notificationId), 350)

        return () => clearTimeout(ref)
    }, [isClosing])

    return (
        <div {...props} className={classes}>
            <div className="toast__content">
                {React.cloneElement(Content, contentProps)}
                <button className="toast__close-button" onClick={() => dismiss(notificationId)} title={__("misc.action_close")}><CancelIcon /></button>
            </div>
        </div>
    )
}

export default Toaster
