/* eslint-disable react/forbid-dom-props */

import React from "react"
import { Helmet } from "react-helmet"
import { useGetRelatives } from "@/api/relatives"
import ApplicationLayout from "@/components/ApplicationLayout"
import RelativeSection from "@/components/RelativeSection"
import Container from "@/components/Container"
import OverviewLink from "@/components/OverviewLink"
import Card from "@/components/Card"
import { EmailInput } from "@/components/Inputs/EmailInput"
import { NumericTextInput } from "@/components/Inputs/NumericTextInput"
import { TextInput } from "@/components/Inputs/TextInput"
import SubmitButton from "@/components/SubmitButton"
import ProfilePicture from "@/components/ProfilePicture"
import ActionIcon from "@mui/icons-material/MoreVertOutlined"
import FocusTrapWrapper from "@/components/FocusTrapWrapper"
import Closeable from "@/components/Closeable"
import {
    useCurrentWorkspace, useDeleteUserMutation,
    useGetUserQuery, UserQuery,
    useUpdateProfilePictureMutation,
    useUpdateUserMutation, useWorkspaces
} from "@/api/user"
import Button from "@/components/Button"
import IconButton from "@/components/IconButton"
import { icons } from "@/config/icons"
import DropUploadArea from "@/components/DropUploadArea"
import { useQueryClient } from "react-query"
import { useConfirm } from "@/contexts/ConfirmContext/hooks"
import { WorkspaceResource } from "@/types/models/User"
import classnames from "classnames"


const AccountManagementView = () => {
    const { data: user } = useGetUserQuery()
    const workspace = useCurrentWorkspace()
    const { data: relatives } = useGetRelatives()
    const workspaces = useWorkspaces()
    const updateUserMutation = useUpdateUserMutation()
    const imageInputRef = React.useRef<HTMLInputElement>(null)
    const updateProfilePictureMutation = useUpdateProfilePictureMutation()
    const deleteUserMutation = useDeleteUserMutation()
    const confirm = useConfirm()
    const queryClient = useQueryClient()

    const [details, setDetails] = React.useState({
        email: user?.email || "",
        phone: user?.phone || "",
        care_of: user?.address.care_of || "",
        street: user?.address.street || "",
        city: user?.address.city || "",
        zipcode: user?.address.zipcode || "",
    })

    const handleDetailsChange = (e: React.ChangeEvent<HTMLInputElement>) => setDetails({
        ...details,
        [e.target.name]: e.target.value,
    })

    const handleSubmitDetails = (e: React.FormEvent) => {
        e.preventDefault()
        updateUserMutation.mutate(details)
    }

    const handleProfilePictureInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) uploadProfilePicture(Array.from(e.target.files))
    }

    const uploadProfilePicture = (files: File[] | null) => {
        const image = files?.pop() || null
        updateProfilePictureMutation.mutate(image)
    }

    const leaveWorkspace = async (id: number) => {
        const confirmed = await confirm(__("misc.confirmations.leaving_workspace"))
        if (!confirmed) return

        await Axios.post(`/api/workspaces/${id}/leave`)
        await queryClient.invalidateQueries(UserQuery.Get)
    }

    const unsubscribeWorkspace = async (id: number) => {
        const confirmed = await confirm(__("misc.confirmations.unsubscribe"))
        if (!confirmed) return

        await Axios.post(`/api/workspaces/${id}/unsubscribe`)
        await queryClient.invalidateQueries(UserQuery.Get)
    }

    const deleteWorkspace = async (id: number) => {
        const confirmed = await confirm(__("misc.confirmations.deleting_workspace"))
        if (!confirmed) return

        await Axios.post(`/api/workspaces/${id}/delete`)
        await queryClient.invalidateQueries(UserQuery.Get)
    }

    const terminateAccount = async () => {
        const confirmed = await confirm(__("misc.confirmations.terminate_account"))
        if (!confirmed) return

        deleteUserMutation.mutate()
    }

    const hasRegisteredRelatives = relatives && relatives.length > 0
    const isProactiveWorkspace = workspace && user && workspace.deceased_user_id === user.id
    const showRelativeSection = hasRegisteredRelatives && isProactiveWorkspace

    return (
        <ApplicationLayout>
            <Helmet>
                <title>{__("views.account_management.meta_title")}</title>
            </Helmet>
            <Container id="account-management">
                <div className="py-6">
                    <OverviewLink />
                </div>
                <Card>
                    <h1 className="mb-12">{__("views.account_management.title")}</h1>
                    <div className="grid grid-cols-3">
                        <form className="col-span-3 m:col-span-2" onSubmit={handleSubmitDetails}>
                            <div className="grid gap-6 grid-cols-6">
                                <div className="col-span-6">
                                    <TextInput
                                        label={__("common.dictionary.name")}
                                        value={user ? user.nickname + " " + user.last_name : ""}
                                        disabled />
                                </div>
                                <div className="col-span-6">
                                    <EmailInput
                                        name="email"
                                        value={details.email}
                                        onChange={handleDetailsChange} />
                                </div>
                                <div className="col-span-6">
                                    <TextInput
                                        name="phone"
                                        type="tel"
                                        label={__("common.user.phone")}
                                        value={details.phone}
                                        onChange={handleDetailsChange} />
                                </div>
                                <div className="col-span-6">
                                    <TextInput
                                        name="care_of"
                                        label="Address"
                                        placeholder={__("common.user.care_of")}
                                        value={details.care_of}
                                        onChange={handleDetailsChange} />
                                </div>
                                <div className="col-span-6">
                                    <TextInput
                                        name="street"
                                        placeholder={__("common.user.address")}
                                        value={details.street}
                                        onChange={handleDetailsChange} />
                                </div>
                                <div className="col-span-6 m:col-span-2">
                                    <NumericTextInput
                                        name="zipcode"
                                        placeholder={__("common.user.zipcode")}
                                        value={details.zipcode}
                                        onChange={handleDetailsChange} />
                                </div>
                                <div className="col-span-6 m:col-span-4">
                                    <TextInput
                                        name="city"
                                        placeholder={__("common.user.city")}
                                        value={details.city}
                                        onChange={handleDetailsChange} />
                                </div>
                            </div>
                            <SubmitButton className="mt-8" isSubmitting={updateUserMutation.isLoading}>
                                {__("common.dictionary.save")}
                            </SubmitButton>
                        </form>
                        <div
                            className="col-span-3 m:col-span-1"
                            style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
                            <div className="mb-8" style={{ width: "fit-content", position: "relative" }}>
                                <DropUploadArea
                                    handleUpload={uploadProfilePicture}
                                    isSubmitting={updateProfilePictureMutation.isLoading}>
                                    <ProfilePicture size="humongous" src={user?.profile_picture_url || null} isVault={false} />
                                </DropUploadArea>
                                <IconButton
                                    style={{ position: "absolute", right: 0, bottom: 0 }} icon={icons.delete}
                                    onClick={() => uploadProfilePicture(null)} />
                            </div>
                            <div>
                                <input
                                    ref={imageInputRef} type="file" accept="image/*" className="hidden"
                                    onChange={handleProfilePictureInputChange} />
                                <Button
                                    outlined
                                    onClick={() => imageInputRef.current?.click()}>{__("views.account_management.actions.choose_image")}
                                </Button>
                            </div>
                        </div>
                    </div>
                    <hr className="my-12 border-gray-100 border" />
                    {showRelativeSection && (
                        <React.Fragment>
                            <RelativeSection />
                            <hr className="my-12 border-gray-100 border" />
                        </React.Fragment>
                    )}
                    <h2 id="workspace">{__("views.account_management.workspaces.title")}</h2>
                    <p className="mb-8">{__("views.account_management.workspaces.description")}</p>
                    <div className="mb-12 grid gap-8 grid-cols-1 m:grid-cols-2">
                        {workspaces.map(workspace => (
                            <div
                                key={workspace.relation_id}
                                className="border border-gray-100 p-4 "
                                style={{ display: "grid", gridTemplateColumns: "auto 1fr auto", gap: "1rem" }}>
                                <ProfilePicture size="small" src={workspace.profile_picture_url} />
                                <div>
                                    <div>{workspace.nickname}</div>
                                    <Fineprint workspace={workspace} />
                                </div>
                                <Action className="grid content-center">
                                    <div className="action__list">
                                        {workspace.can_leave && (
                                            <div className="action__list-item">
                                                <button onClick={() => leaveWorkspace(workspace.relation_id)}>
                                                    {__("subscriptions.actions.leave_workspace")}
                                                </button>
                                            </div>
                                        )}
                                        {workspace.can_unsubscribe && (
                                            <div className="action__list-item">
                                                <button onClick={() => unsubscribeWorkspace(workspace.relation_id)}>
                                                    {__("subscriptions.actions.unsubscribe")}
                                                </button>
                                            </div>
                                        )}
                                        {workspace.can_subscribe && (
                                            <div className="action__list-item">
                                                <a href={`/workspaces/${workspace.relation_id}/subscribe`}>
                                                    {__("subscriptions.actions.start_subscription")}
                                                </a>
                                            </div>
                                        )}
                                        {workspace.can_delete && (
                                            <div className="action__list-item">
                                                <button onClick={() => deleteWorkspace(workspace.relation_id)}>
                                                    {__("subscriptions.actions.delete_workspace")}
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                </Action>
                            </div>
                        ))}
                    </div>
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <SubmitButton
                            className="mb-4"
                            variant="danger"
                            isSubmitting={deleteUserMutation.isLoading}
                            onClick={terminateAccount}
                            disabled={workspaces && workspaces?.length !== 0}>
                            {__("views.account_management.actions.delete_account")}
                        </SubmitButton>
                        <p style={{ textAlign: "center", maxWidth: "72ch" }}>
                            {__("views.account_management.account_deletion_disabled_description")}
                        </p>
                    </div>
                </Card>
            </Container>
        </ApplicationLayout>
    )
}

const Action: React.FC<{ className?: string }> = ({ children, className }) => {
    const [open, setOpen] = React.useState(false)
    const triggerRef = React.useRef<HTMLButtonElement>(null)

    return (
        <div className={`action ${className}`}>
            <button ref={triggerRef} className="action__button" onClick={() => setOpen(!open)}>
                <ActionIcon />
            </button>
            {open && (
                <FocusTrapWrapper>
                    <Closeable close={() => setOpen(false)} triggerRef={triggerRef}>
                        <div>{children}</div>
                    </Closeable>
                </FocusTrapWrapper>
            )}
        </div>
    )
}

const Fineprint: React.FC<{ workspace: WorkspaceResource }> = ({ workspace }) => {
    const { is_admin, invalid_subscription, failed_payment, subscription_cancelled } = workspace
    let text = ""

    const className = classnames({
        "font-danger-400": subscription_cancelled || failed_payment || invalid_subscription,
    })

    if (subscription_cancelled) {
        text = __("subscriptions.status.cancelled")
    } else if (is_admin && failed_payment) {
        text = __("subscriptions.status.payment_failed")
    } else if (is_admin) {
        text = __("misc.you_are_admin")
    } else if (invalid_subscription) {
        text = __("subscriptions.status.no_subscription")
    } else {
        return null
    }

    return <small className={className}>{text}</small>
}

export default AccountManagementView
