import { IStepLead } from "@/components/Guide/types"
import { useGetLeadStatus, useRequestLead } from "@/api/leads"
import Prose from "@/components/Prose"
import { renderHtml } from "@/components/Guide/utils"
import SubmitButton from "@/components/SubmitButton"
import Modal from "@/components/Modal"
import React from "react"
import { CheckCircle } from "@mui/icons-material"

interface StepLeadProps {
    lead: IStepLead
}

const StepLead = ({ lead }: StepLeadProps) => {
    const status = useGetLeadStatus(lead.type)
    const requestLead = useRequestLead(lead.type)

    if (status.isLoading || status.isError) {
        return null
    }

    return (
        <div>
            <div className="mb-6">
                <Prose>{renderHtml(lead.description)}</Prose>
            </div>
            <div>
                {status.data?.requested === false && (
                    <SubmitButton
                        isSubmitting={requestLead.isLoading} onClick={() => requestLead.mutate()}
                        type="button">{lead.label.default}
                    </SubmitButton>
                )}
                {status.data?.requested === true && (
                    // eslint-disable-next-line react/forbid-dom-props
                    <p style={{ display: "flex", alignItems: "center" }}>
                        <span className="font-primary-500 mr-2"><CheckCircle fontSize="large" /></span>
                        <em>{lead.label.requested}</em>
                    </p>
                )}
            </div>
            {requestLead.isError && (
                <Modal closeModal={() => requestLead.reset()}>
                    <div className="px-8 pb-8">
                        <Prose>
                            <p>{requestLead.error.response?.data.message || __("common.errors.oopsie")}</p>
                        </Prose>
                    </div>
                </Modal>
            )}
        </div>
    )
}
export default StepLead
