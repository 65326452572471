import React, { FC } from "react"

interface IPlaceholder {
    title: string
    description: string
    icon: JSX.Element
}

const Placeholder: FC<IPlaceholder> = ({ title, description, icon }) => {
    return (
        <div className="placeholder">
            {icon}
            <div className="placeholder__title">{title}</div>
            <div className="placeholder__text">{description}</div>
        </div>
    )
}

export default Placeholder
