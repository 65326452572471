/* eslint-disable react/jsx-no-useless-fragment */
import React from "react"
import Modal, { IModalProps } from "@/components/Modal"
import "react-pdf/dist/esm/Page/TextLayer.css"
import "react-pdf/dist/esm/Page/AnnotationLayer.css"
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined"

interface LoginModalProps extends IModalProps {
    content?: string
}

const LoginModal: React.FC<LoginModalProps> = ({ content, ...modalProps }) => {
    switch (content) {
    case "bortgang":
        return (
            <Modal {...modalProps}>
                <div className="onboarding__modal">
                    <div className="onboarding__close">Stäng</div>
                    <h1 className="onboarding__modalHeader">
                        <span className="onboarding__marginIcon">
                            {" "}
                            <InfoOutlinedIcon />{" "}
                        </span>
                        <span>{__("onboarding.root.actions.create-reactive")}</span>
                    </h1>
                    <div className="onboarding__modal--list">
                        <li>
                                Hantera <strong>allt det praktiska</strong> när
                                en anhörig gått bort.
                        </li>
                        <li>
                                Steg för steg inklusive{" "}
                            <strong>checklistor</strong> och{" "}
                            <strong>nödvändiga dokument.</strong>
                        </li>
                        <li>
                            <strong>Anhöriga kan hjälpa till</strong> och ni
                                håller kontakt via en chattfunktion.
                        </li>
                    </div>
                    <p>
                            Detta ingår ett <strong>digitalt valv</strong> där
                            ni sparar dokument, upphittade minnen eller lägger
                            saker för transparensens skull. Sidan går sen att
                            behålla som ett minnesrum.
                    </p>
                    <p>
                            Detta för endast <strong>495kr</strong> inklusive
                            alla blanketter
                    </p>
                </div>
            </Modal>
        )
    case "digital":
        return (
            <Modal {...modalProps}>
                <div className="onboarding__modal">
                    <div className="onboarding__close">Stäng</div>
                    <h1 className="onboarding__modalHeader">
                        <span className="onboarding__marginIcon">
                            {" "}
                            <InfoOutlinedIcon />{" "}
                        </span>
                        <span>
                            <strong>Digitala Valvet - GRATIS!</strong>
                        </span>
                    </h1>
                    <div className="onboarding__modal--list">
                        <li>
                                Smart och trygg e-tjänst för att{" "}
                            <strong>lagra och hitta allt viktigt</strong>{" "}
                                när du behöver det. Här skapar du ordning och
                                reda i din vardag.
                        </li>
                        <li>
                                Du kan spara <strong>testamente</strong>, viktig
                                information, <strong>papper på huset</strong>,
                                dina <strong>lösenord</strong> osv.
                        </li>
                        <li>
                                Men även saker av personligt värde.{" "}
                            <strong>Familjebilder</strong>, kordinater till
                                ditt hemliga <strong>svampställe</strong>, ditt
                                unika <strong>recept</strong> på köttbullar
                                eller en <strong>videohälsning</strong> för
                                framtiden.
                        </li>
                    </div>
                    <p>
                            Spara allt i ditt eget digitala valv. Dina anhöriga
                            får automatiskt tillgång den dagen du inte längre
                            finns.
                    </p>
                    <p>
                        <strong>Digitala Valvet 0kr/år</strong> inklusive 5 dokument, bilder och lösenord att lägga in. Fria mallar och fullmakter.
                    </p>
                    <p>
                        <strong>Premium 240kr/år</strong> inklusive 1000 dokument, bilder och lösenord att lägga in. Fria mallar och fullmakter. Hantera en bortgång ingår för efterlevande.
                    </p>
                </div>
            </Modal>
        )
    default:
        return <></>
    }
}

export default LoginModal
