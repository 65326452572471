import React, { useEffect, useMemo, useRef, useState } from "react"
import CreateFolderModal from "@/components/Vault/Modals/CreateFolderModal"
import PreviewFileModal from "@/components/Vault/Modals/PreviewFileModal"
import EditVaultItemModal from "@/components/Vault/Modals/EditVaultItemModal"
import MoveVaultItemModal from "@/components/Vault/Modals/MoveVaultItemModal"
import DeleteVaultItemModal from "@/components/Vault/Modals/DeleteVaultItemModal"
import Spinner from "@/components/Spinner"
import DropUploadArea from "@/components/DropUploadArea"
import Breadcrumbs from "@/components/Breadcrumbs"
import Table from "@/components/Table"
import UploadToastContent from "@/components/DropUploadArea/UploadToastContent"
import Button from "@/components/Button"
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolderOutlined"
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined"
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined"
import SearchInput from "@/components/Inputs/SearchInput"
import { ITableColumn, ITableRow } from "@/components/Table/types"
import Placeholder from "@/components/Placeholder"
import { grayIcons, icons, IconType } from "@/config/icons"
import {
    FolderContentType,
    IBreadcrumb,
    IFileResource,
    IFolderResource,
    IReadFolderResponse,
    VaultServiceViewUrlParams
} from "@/views/VaultServiceView/types"
import { useQueryClient } from "react-query"
import { AxiosError, AxiosResponse, Canceler } from "axios"
import { useHistory, useParams } from "react-router-dom"
import { useDismissNotification, useNotify } from "@/contexts/NotificationsContext"
import { useVaultItemCount, VaultQuery, vaultServiceIdTranslationKeyMap } from "@/api/vault"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import { columnKeys, folderOrFile, toColumns } from "@/views/VaultServiceView/utils"
import { format } from "date-fns"
import { formatBytes } from "@/utilities"
import { v4 as uuid } from "uuid"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import ErrorIcon from "@mui/icons-material/Error"
import { NotificationStatus, NotificationType } from "@/contexts/NotificationsContext/types"
import ActionIcon from "@mui/icons-material/MoreVertOutlined"
import FocusTrapWrapper from "@/components/FocusTrapWrapper"
import Closeable from "@/components/Closeable"
import VaultServiceHeader from "@/components/Vault/VaultServiceHeader"
import MatchMedia from "@/components/MatchMedia"
import { MediaRule } from "@/utilities/useMatchMedia"
import IconButton from "@/components/IconButton"
import { useGetUserQuery, useCurrentWorkspace, useIsProactiveWorkspace } from "@/api/user"
import { GA } from "@/utilities/googleAnalytics"
interface IVaultViewContext {
    removeItem: (x: number) => void
    editItem: (x: number, y: string, z: string) => void
    moveItem: (x: number, y: number, z: string) => void
}


const VaultViewContext = React.createContext<IVaultViewContext | undefined>(undefined)

export const useVaultContext = () => {
    const context = React.useContext(VaultViewContext)
    if (!context) {
        throw new Error("You cannot use VaultViewContext outside of a VaultViewContext.Provider!")
    }

    return context
}

const DefaultServiceView = () => {
    const [folder, setFolder] = useState<IFolderResource>()
    const [isFolderModalOpen, setIsFolderModalOpen] = useState(false)
    const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false)
    const [previewContent, setPreviewContent] = useState<IFileResource>()
    const [isLoading, setIsLoading] = useState(true)
    const [breadcrumbs, setBreadcrumbs] = useState<IBreadcrumb[]>([])
    const [queryResult, setQueryResult] = useState<FolderContentType[]>()
    const queryClient = useQueryClient()
    const cancelRequestRef = useRef<Canceler>()
    const history = useHistory()
    const isProactiveWorkspace = useIsProactiveWorkspace()
    const { serviceId, serviceSlug, folderId } = useParams<VaultServiceViewUrlParams>()
    const notify = useNotify()
    const dismiss = useDismissNotification()

    const currentWorkspace = useCurrentWorkspace()
    const { data: currentUser } = useGetUserQuery()

    const rootLinkPath = `/vault/${serviceSlug}/${serviceId}`
    const vaultItemCount = useVaultItemCount()

    const activeFolderId = folderId || serviceId
    const translationKey = vaultServiceIdTranslationKeyMap[serviceId]

    const unableToUpload = currentWorkspace?.invalid_subscription && vaultItemCount >= 5
    useEffect(() => {
        void async function () {
            setIsLoading(true)
            const { data } = await Axios.get<IReadFolderResponse>(`/api/folders/${activeFolderId}`)
            setFolder(data.data)
            data.data.id === Number(serviceId)
                ? setBreadcrumbs(data.meta.breadcrumbs)
                : setBreadcrumbs([...data.meta.breadcrumbs, { title: data.data.title, id: data.data.id }])
            setIsLoading(false)
        }()
    }, [folderId])

    const queryByTitle = async (query: string) => {
        if (!query) return setQueryResult(undefined)
        cancelRequestRef.current?.()

        setIsLoading(true)

        const { data } = await Axios.get<FolderContentType[]>("/api/folders", {
            cancelToken: new Axios.CancelToken((canceler) => void (cancelRequestRef.current = canceler)),
            params: { id: serviceId, title: query },
        }).catch(() => ({ data: [] }))

        setQueryResult(data)
        setIsLoading(false)
    }

    let linkPath, label
    if (breadcrumbs?.length > 1) {
        const parentFolder = breadcrumbs.slice(-2, -1)
        linkPath = `${rootLinkPath}/${parentFolder[0].slug}/${parentFolder[0].id}`
        label = __("misc.back_to", { name: parentFolder[0].title })
    } else {
        linkPath = rootLinkPath
        label = __("misc.back_to", { name: __(`vault.${translationKey}.title`) })
    }

    const RowToParentFolder = !queryResult && breadcrumbs?.length > 0 ? {
        icon: <ArrowBackIcon className="parent-back-icon" />,
        title: <LinkToParentFolder label={label} />,
        type: "",
        date: "",
        size: "",
        user: "",
        action: "",
    } : undefined

    const columns = useMemo(() => columnKeys.map(toColumns), [])
    const rows = useMemo(() => {
        // TODO: fix type for row to be maybe IFileResource?
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const toRows = (row: any) => ({
            onClick: () => {
                if (row.type === "folder" && "slug" in row) {
                    history.push(`/vault/${serviceSlug}/${serviceId}/${row.slug}/${row.id}`)
                } else {
                    setIsPreviewModalOpen(true)
                    setPreviewContent(row)
                    // const a = window.document.createElement("a")
                    // a.href = `/api/files/download/${row.id}`
                    // a.download = row.title
                    // a.click()
                }
            },
            icon: {
                sortValue: null,
                content: icons[row.type as IconType] || icons["file" as IconType],
            },
            title: {
                sortValue: row.title,
                content: <TableTitleCell {...row} />,
            },
            type: {
                sortValue: row.type,
                content: folderOrFile(row.type) ? __("common.dictionary.folder") : row.type,
            },
            date: {
                sortValue: row.updated_at,
                content: format(new Date(row.updated_at), __("misc.vault_date")),
            },
            user: {
                sortValue: row.created_by || null,
                content: row.created_by || null,
            },
            size: {
                sortValue: "size" in row ? row.size : 0,
                content: "size" in row ? formatBytes(row.size) : null,
            },
            action: {
                sortValue: null,
                content: <TableActionCell {...row} />,
            },
        })
        if (queryResult) return queryResult.map(toRows)

        if (!folder) return []
        const _rows: FolderContentType[] = []

        if (folder.folders) _rows.push(...folder.folders)
        if (folder.files) _rows.push(...folder.files)

        return _rows.map(toRows)
    }, [folder, queryResult])

    const handleUpload = (files: File[]) => {
        files.forEach(file => {
            const notificationId = uuid()

            if (file.size > window.upload_limit) {
                return notify({
                    Content: <div className="toast__wrapper"><ErrorIcon className="font-danger-400" /><p>{__("validation.lte.file", { attribute: file.name, value: formatBytes(window.upload_limit) })}</p></div>,
                    notificationId: notificationId,
                    type: NotificationType.TOAST,
                    status: NotificationStatus.ERROR,
                    autoClose: false,
                })
            }

            const handleUploadComplete = (data?: IFileResource, error?: Error) => {
                if (error) return setTimeout(() => dismiss(notificationId), 8500)
                if (!data) return

                setFolder(prev => prev && prev.files && {
                    ...prev, files: [...prev.files.concat(data).sort((a, b) => a.title.localeCompare(b.title))],
                })

                queryClient.invalidateQueries(VaultQuery.Folders)
                setTimeout(() => dismiss(notificationId), 2000)
            }

            notify({
                Content: <UploadToastContent
                    file={file}
                    parentFolderId={activeFolderId}
                    rootFolderId={serviceId}
                    handleUploadComplete={handleUploadComplete} />,
                type: NotificationType.TOAST,
                status: NotificationStatus.INFO,
                notificationId,
                autoClose: false,
            })
        })
    }

    async function handleChangeFileInput(e: React.ChangeEvent<HTMLInputElement>) {
        const files = Array.from(e.target?.files || [])
        handleUpload(files)
        e.target.files = null
        const title = folder?.title.substring(
            folder?.title.indexOf(".") + 1,
            folder?.title.lastIndexOf(".")
        )
        switch (title) {
        case "greetings":
            GA("button", isProactiveWorkspace ? "HEB: Moment of life - Upload" : "DV: Moment of life - Upload", "Clicked the button")
            break
        case "banking":
            GA("button", isProactiveWorkspace ? "HEB: Bank papper - Upload" : "DV: Bank papper - Upload", "Clicked the button")
            break
        case "insurances":
            GA("button", isProactiveWorkspace ? "HEB: Insurances - Upload " : "DV: Insurances - Upload", "Clicked the button")
            break
        case "properties":
            GA("button", isProactiveWorkspace ? "HEB: Housing documents - Upload " : "DV: Housing documents - Upload", "Clicked the button")
            break
        case "vehicles":
            GA("button", isProactiveWorkspace ? "HEB: Vehicle - Upload" : "DV: Vehicle - Upload", "Clicked the button")
            break
        case "other":
            GA("button", isProactiveWorkspace ? "HEB: Other documents - Upload" : "DV: Other documents - Upload", "Clicked the button")
            break
        default:
            break
        }
    }

    function handleSetFolder(folder: string) {
        setIsFolderModalOpen(true)
        const title = folder.substring(
            folder.indexOf(".") + 1,
            folder.lastIndexOf(".")
        )

        notify({
            Content: <div className="toast__wrapper"><CheckCircleIcon className="font-primary-400" /><p>Mappen skapad</p></div>,
            notificationId: uuid(),
            type: NotificationType.TOAST,
            status: NotificationStatus.SUCCESS,
        })
        switch (title) {
        case "greetings":
            GA("button", isProactiveWorkspace ? "HEB: Moment of life - Create folder" : "DV: Moment of life - Create folder", "Clicked the button")
            break
        case "banking":
            GA("button", isProactiveWorkspace ? "HEB: Bank papper - Create folder" : "DV: Bank papper - Create folder", "Clicked the button")
            break
        case "insurances":
            GA("button", isProactiveWorkspace ? "HEB: Insurances - Create folder " : "DV: Insurances - Create folder", "Clicked the button")
            break
        case "properties":
            GA("button", isProactiveWorkspace ? "HEB: Housing documents - Create folder " : "DV: Housing documents - Create folder", "Clicked the button")
            break
        case "vehicles":
            GA("button", isProactiveWorkspace ? "HEB: Vehicle - Create folder" : "DV: Vehicle - Create folder", "Clicked the button")
            break
        case "other":
            GA("button", isProactiveWorkspace ? "HEB: Other documents - Create folder" : "DV: Other documents - Create folder", "Clicked the button")
            break
        default:
            break
        }

    }

    async function createFolder(newFolderTitle: string) {
        if (folder?.folders?.some(({ title }) => title === newFolderTitle)) return false
        const { data } = await Axios.post(`/api/folders/${activeFolderId}`, { title: newFolderTitle })

        const folders = (folder?.folders || [])
            .concat(data)
            .sort((a, b) => b.title.localeCompare(a.title))

        setFolder(prev => prev && { ...prev, folders })

        return !!data
    }

    const contextValue: IVaultViewContext = {
        removeItem: async (id: number) => {
            if (!folder) return

            const content: Array<IFileResource | IFolderResource> = []

            if (folder.folders) content.push(...folder.folders)
            if (folder.files) content.push(...folder.files)

            const item = content.find(item => item.id === id)
            if (!item) return
            const url = item.type === "folder" ? "/api/folders/" + item.id : "/api/files/" + item.id

            try {
                await Axios.delete(url)
                await queryClient.invalidateQueries(VaultQuery.Folders)

                setFolder(prev => prev && {
                    ...prev,
                    folders: folder.folders?.filter(f => f.id !== id),
                    files: folder.files?.filter(f => f.id !== id),
                })

                return notify({
                    Content: <div className="toast__wrapper"><CheckCircleIcon className="font-primary-400" /><p>{__("vault.confirmation.item-was-deleted", { name: item.title })}</p></div>,
                    notificationId: uuid(),
                    type: NotificationType.TOAST,
                    status: NotificationStatus.SUCCESS,
                })
            } catch (error) {
                return notify({
                    Content: <div className="toast__wrapper"><ErrorIcon className="font-danger-400" /><p>{__("vault.errors.delete_item", { title: item.title })}</p></div>,
                    notificationId: uuid(),
                    type: NotificationType.TOAST,
                    status: NotificationStatus.ERROR,
                })
            }
        },
        editItem: async (id: number, title: string, type: string) => {
            const url = type === "folder" ? "/api/folders/" + id : "/api/files/" + id
            return await Axios.patch<IFolderResource>(url, {
                title: title,
            }).then((response: AxiosResponse) => {
                if (type === "folder") {
                    setFolder(prev => prev && {
                        ...prev,
                        folders: folder?.folders?.map(folder => folder.id === id ? { ...folder, title: response.data.title } : folder),
                    })
                    return response.status
                } else {
                    setFolder(prev => prev && {
                        ...prev,
                        files: folder?.files?.map(file => file.id === id ? { ...file, title: response.data.title } : file),
                    })
                    return response.status
                }
            }).catch((error: AxiosError) => {
                return {
                    data: error.response?.data.message,
                    status: error.response?.status,
                }
            })
        },
        moveItem: async (parentId: number, itemId: number, itemType: string) => {
            const url = itemType === "folder" ? "/api/folders/" + itemId : "/api/files/" + itemId
            return await Axios.patch<IFolderResource>(url, {
                parent_folder_id: parentId,
            }).then((response: AxiosResponse) => {
                setFolder(prev => prev && {
                    ...prev,
                    folders: folder?.folders?.filter(f => f.id !== itemId),
                    files: folder?.files?.filter(f => f.id !== itemId),
                })
                return response.status
            }).catch((error: AxiosError) => {
                return {
                    data: error.response?.data.message,
                    status: error.response?.status,
                }
            })
        },
    }

    return (
        <React.Fragment>
            {isFolderModalOpen && (
                <CreateFolderModal
                    closeModal={() => setIsFolderModalOpen(false)}
                    handleSubmit={createFolder}
                    folderTitle={folder?.title} />
            )}
            {isPreviewModalOpen && previewContent && (
                <PreviewFileModal
                    closeModal={() => setIsPreviewModalOpen(false)}
                    content={previewContent}
                    files={folder?.files} />
            )}
            <VaultServiceHeader translationKey={serviceSlug} />
            <div className="vault-service-view__actions">
                <MatchMedia rule={MediaRule.TabletAndAbove}>
                    <div className="vault-service-view__action-wrapper">
                        <Button className={isProactiveWorkspace ? "button" : "button gray-button"} onClick={() => handleSetFolder(folder?.title ? folder.title : "")}>
                            <CreateNewFolderIcon /> {__("vault.actions.create-folder")}
                        </Button>
                        <strong>{__("vault.actions.create-folder")}</strong>
                    </div>
                    <div className="vault-service-view__action-wrapper">
                        {unableToUpload ? (
                            <Button className={isProactiveWorkspace ? "button" : "button gray-button"} disabled={unableToUpload}>
                                <CloudUploadOutlinedIcon /> {__("vault.actions.upload-file")}
                            </Button>
                        ) : (
                            <>
                                <label className={isProactiveWorkspace ? "button" : "button gray-button"}>
                                    <CloudUploadOutlinedIcon />
                                    <span>{__("vault.actions.upload-file")}</span>
                                    <input
                                        className="vault-service-view__upload-input"
                                        type="file"
                                        onChange={handleChangeFileInput}
                                        multiple />
                                </label>
                                <strong>{__("vault.actions.upload-file")}</strong>
                            </>
                        )}
                    </div>
                </MatchMedia>
                <MatchMedia rule={MediaRule.PhoneOnly}>
                    <div className="vault-service-view__action-wrapper">
                        <IconButton className={isProactiveWorkspace ? "button" : "button gray-button"} icon={<CreateNewFolderIcon />} onClick={() => setIsFolderModalOpen(true)} />
                        <strong>{__("vault.actions.create-folder")}</strong>
                    </div>
                    <div className="vault-service-view__action-wrapper">
                        {unableToUpload ? (
                            <>
                                <Button className={isProactiveWorkspace ? "button" : "button gray-button"} disabled={unableToUpload}>
                                    <CloudUploadOutlinedIcon />
                                    <input
                                        disabled={unableToUpload}
                                        className="vault-service-view__upload-input"
                                        type="file"
                                        onChange={handleChangeFileInput}
                                        multiple />
                                </Button>
                                <strong>{__("vault.actions.upload-file")}</strong>
                            </>
                        ) : (
                            <>
                                <label className={isProactiveWorkspace ? "button icon-button" : "button gray-button"}>
                                    <CloudUploadOutlinedIcon />
                                    <input
                                        disabled={unableToUpload}
                                        className="vault-service-view__upload-input"
                                        type="file"
                                        onChange={handleChangeFileInput}
                                        multiple />
                                </label>
                                <strong>{__("vault.actions.upload-file")}</strong>
                            </>
                        )}

                    </div>
                    <div className="vault-service-view__action-wrapper">
                        {unableToUpload ? (
                            <>
                                <Button className={isProactiveWorkspace ? "button" : "button gray-button"} disabled={unableToUpload}>
                                    <CameraAltOutlinedIcon />
                                    <input
                                        disabled={unableToUpload}
                                        className="vault-service-view__upload-input"
                                        type="file"
                                        accept="image/*"
                                        capture="environment"
                                        onChange={handleChangeFileInput} />
                                </Button>
                                <strong>{(__("vault.actions.upload-picture"))}</strong>
                            </>
                        ) : (
                            <>
                                <label className={isProactiveWorkspace ? "button icon-button" : "button gray-button"}>
                                    <CameraAltOutlinedIcon />
                                    <input
                                        disabled={unableToUpload}
                                        className="vault-service-view__upload-input"
                                        type="file"
                                        accept="image/*"
                                        capture="environment"
                                        onChange={handleChangeFileInput} />
                                </label>
                                <strong>{(__("vault.actions.upload-picture"))}</strong>
                            </>
                        )}

                    </div>
                </MatchMedia>
                <SearchInput queryHandler={queryByTitle} debounceDuration={300} />
            </div>
            <Breadcrumbs breadcrumbs={breadcrumbs} />
            <hr className="vault__hr" />
            <div className="vault-table-container">
                <VaultViewContext.Provider value={contextValue}>
                    <DropUploadArea
                        handleUpload={handleUpload}
                        overlayProps={{ className: "drop-upload-area__overlay--vault-service" }}>
                        <Table
                            rows={rows as ITableRow[]}
                            columns={columns as ITableColumn[]}
                            firstRow={RowToParentFolder}
                            className={isProactiveWorkspace ? "vault-table" : "gray-vault-table"}
                            path={linkPath} />
                        {queryResult?.length === 0 && (
                            <Placeholder
                                title={__("vault.placeholder.no-result.title")}
                                description={__("vault.placeholder.no-result.description")}
                                icon={isProactiveWorkspace ? icons["emptyFolder"] : grayIcons["emptyFolder"]} />
                        )}
                        {rows.length === 0 && !isLoading && !queryResult && (
                            <Placeholder
                                title={__("vault.placeholder.no-uploads.title")}
                                description={currentWorkspace?.deceased_user_id === currentUser?.id ? __("vault.placeholder.no-uploads.proactive") : __("vault.placeholder.no-uploads.reactive")}
                                icon={isProactiveWorkspace ? icons["emptyFolder"] : grayIcons["emptyFolder"]} />
                        )}
                    </DropUploadArea>
                </VaultViewContext.Provider>
                {isLoading && <div className="vault-table-container__loader"><Spinner /></div>}
            </div>
        </React.Fragment>
    )
}

interface ILinkToParentFolder {
    label: string
}

const LinkToParentFolder: React.FC<ILinkToParentFolder> = ({ label }) => {
    return (
        <div className="link-to-parent-wrapper">
            <div className="link-to-parent">
                {label}
            </div>
        </div>
    )
}

export const TableTitleCell: React.FC<FolderContentType> = (props) => {
    return (
        <React.Fragment>
            <div className="table-cell-title">{props.title}</div>
            <div className="table-mobile-inner-wrapper">
                <span className="table-mobile-inner-wrapper__user">
                    {props.created_by}
                </span>
                <span className="table-mobile-inner-wrapper__date">
                    {format(new Date(props.updated_at), __("misc.vault_date_2"))}
                </span>
            </div>
        </React.Fragment>
    )
}

export const TableActionCell: React.FC<FolderContentType> = (props) => {
    const [isActionMenuOpen, setIsActionMenuOpen] = useState(false)
    const dropdownTriggerRef = useRef<HTMLButtonElement>(null)
    const [isEditModalOpen, setIsEditModalOpen] = useState(false)
    const [isMoveModalOpen, setIsMoveModalOpen] = useState(false)
    const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false)

    return (
        <div className="action" onClick={(e) => e.stopPropagation()} aria-hidden>
            <button
                className="action__button"
                onClick={() => setIsActionMenuOpen(!isActionMenuOpen)}
                ref={dropdownTriggerRef}>
                <ActionIcon />
            </button>
            {isActionMenuOpen && (
                <FocusTrapWrapper>
                    <Closeable close={() => setIsActionMenuOpen(false)} triggerRef={dropdownTriggerRef}>
                        <ul className="action__list">
                            {props.type !== "folder" && (
                                <li className="action__list-item">
                                    <a href={`/api/files/download/${props.id}`} download>
                                        {icons["download"]} <span>{__("common.dictionary.download")}</span>
                                    </a>
                                </li>
                            )}
                            <li className="action__list-item">
                                <button onClick={() => setIsMoveModalOpen(!isMoveModalOpen)}>
                                    {icons["move"]} <span>{__("common.dictionary.move")}</span>
                                </button>
                            </li>
                            <li className="action__list-item">
                                <button onClick={() => setIsEditModalOpen(!isEditModalOpen)}>
                                    {icons["edit"]} <span>{__("common.dictionary.change_name")}</span>
                                </button>
                            </li>
                            <li className="action__list-item">
                                <button onClick={() => setIsConfirmationModalOpen(!isConfirmationModalOpen)}>
                                    {icons["delete"]} <span>{__("common.dictionary.delete")}</span>
                                </button>
                            </li>
                        </ul>
                    </Closeable>
                </FocusTrapWrapper>
            )}
            {isEditModalOpen && <EditVaultItemModal {...props} closeModal={() => setIsEditModalOpen(false)} />}
            {isMoveModalOpen && <MoveVaultItemModal {...props} closeModal={() => setIsMoveModalOpen(false)} />}
            {isConfirmationModalOpen && <DeleteVaultItemModal {...props} closeModal={() => setIsConfirmationModalOpen(false)} />}
        </div>
    )
}

export default DefaultServiceView
