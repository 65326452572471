import React from "react"
import ReactDOM from "react-dom"
import "./bootstrap"
import Application from "@/Application"

ReactDOM.render(
    <Application />,
    document.getElementById("root")
)

if (module.hot) {
    module.hot.accept()
}
