import React, { useContext, useEffect, useRef, useState } from "react"
import ReactDOM from "react-dom"
import { v4 as uuidv4 } from "uuid"
import { useCurrentWorkspace, useGetUserQuery } from "@/api/user"
import Message from "@/components/Chatroom/components/Message"
import { ChatContext } from "@/contexts/ChatContext"
import ForumIcon from "@mui/icons-material/Forum"
import CloseIcon from "@mui/icons-material/Close"
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown"
import Spinner from "@/components/Spinner"
import HighlightOffIcon from "@mui/icons-material/HighlightOff"
import { icons } from "@/config/icons"
import { Virtuoso, VirtuosoHandle, ListRange } from "react-virtuoso"
import {
    addUnseenMessage,
    toggleChat,
    chatInitialized,
    setLastReadMessageId,
    gotNewMessagesSinceLastLoggedIn,
    setNextQueryId,
    isFetchingNewMessages,
    setRetrievedMessagesWithoutTempData,
    setReceivedNewMessage,
    iGotNewMessage,
    setRetrievedMessages,
    setUserScrollingWhileReceivingMessage,
    setSingleMessageToRetrievedMessages,
    setUnseenMessagesAmount,
    setLoadingMessages,
    setUserIsScrolling,
    setNewScrollRange
} from "@/contexts/ChatContext/actionTypes"
import ChatMessageInput from "@/components/Chatroom/components/MessageInput"
import { IMessage } from "@/contexts/ChatContext/types"
import { ListItems } from "react-virtuoso/dist/listStateSystem"
import { ChatStatusTypes } from "@/components/Chatroom/types"
import Placeholder from "@/components/Placeholder"
import { GA } from "@/utilities/googleAnalytics"

const Chatroom = () => {
    const { state, dispatch } = useContext(ChatContext)
    const currentWorkspace = useCurrentWorkspace()

    const [messageError, setMessageError] = useState(false)
    const [messageErrorId, setMessageErrorId] = useState("")

    const { data: user } = useGetUserQuery()
    const containerRef = useRef<VirtuosoHandle>(null)

    /**
     * Here we fetch message data and updates the state
     * depending on different scenarios
     */
    useEffect(() => {
        dispatch(isFetchingNewMessages(false))
        void async function getChatHistory() {
            const [{ data: previousMessages }, { data: unseenMessages }] = await Promise.all([
                await Axios.get(`/api/chat-messages/${currentWorkspace?.deceased_user_id}`),
                await Axios.get(`/api/chat-messages/${currentWorkspace?.deceased_user_id}/unread`)
            ])

            if (unseenMessages.length > 0) {
                dispatch(setUnseenMessagesAmount(unseenMessages.length))
            }

            if (unseenMessages.length !== 0 && previousMessages.length === 0) {
                dispatch(gotNewMessagesSinceLastLoggedIn(true))
                Axios.patch("/api/chat-message/update", {
                    last_read_message_id: unseenMessages[0].id,
                    channel_id: currentWorkspace?.deceased_user_id,
                }).then(response => {
                    if (response.status === 200) {
                        dispatch(setLastReadMessageId(unseenMessages[0].id))
                    }
                })
            } else if (unseenMessages.length !== 0 && previousMessages.length > 0) {
                dispatch(setLastReadMessageId(previousMessages.slice(-1).pop().id))
                dispatch(gotNewMessagesSinceLastLoggedIn(true))
            } else if (unseenMessages.length === 0 && previousMessages.length > 0) {
                dispatch(setLastReadMessageId(previousMessages.slice(-1).pop().id))
            }

            if (previousMessages.length === 50) {
                dispatch(setNextQueryId(previousMessages[0].id))
            }

            dispatch(setRetrievedMessages([...previousMessages, ...unseenMessages]))

            dispatch(setLoadingMessages(false))
        }()
    }, [currentWorkspace])

    /**
     * Since new container is index based
     * we initialized chat once loading messages and scrolls are done
     */
    useEffect(() => {
        if (state.loading) {
            setTimeout(() => {
                dispatch(chatInitialized(true))
            }, 2000)
        }
    }, [state.loading])

    /**
     * If the user is loading new messages, this scrolls user to the last previous
     * message before spreading in the new old/fetched messages
     * @param lastIndex - number
     */
    const scrollToMessageByIndex = (lastIndex: number) => {
        if (containerRef.current) {
            containerRef?.current.scrollToIndex({ index: lastIndex, behavior: "auto" })
        }
    }

    /**
     * Here we fetch old messages, update the next query id
     * and dispatching an actions that updates the retrieved messages.
     */
    useEffect(() => {
        if (state.isFetchingPreviousMessages && state.nextQueryId) {
            Axios.get(`/api/chat-messages/${currentWorkspace?.deceased_user_id}?until=${state.nextQueryId}`).then(response => {
                if (response.data.length !== 0) {
                    if (response.data.length === 50) {
                        dispatch(setNextQueryId(response.data[0].id))
                        dispatch(setRetrievedMessages(response.data))
                        dispatch(isFetchingNewMessages(false))
                        scrollToMessageByIndex(response.data.length)
                    } else {
                        dispatch(setNextQueryId(undefined))
                        dispatch(setRetrievedMessages(response.data))
                        dispatch(isFetchingNewMessages(false))
                        scrollToMessageByIndex(response.data.length)
                    }
                } else {
                    dispatch(setNextQueryId(undefined))
                }
            })
        }
    }, [state.isFetchingPreviousMessages])

    /**
     * Here we check when we receive a message and if the chat is open or not
     * if the chat is closed and if the user is scrolling while getting the message
     */
    useEffect(() => {
        if (state.gotNewMessage && state.receivedNewMessage) {
            if (user?.id === state.receivedNewMessage.sent_by_user) {
                const userSendingNewMessages = state.retrievedMessages.map(message => {
                    // Null check at line 221 is not inferred here
                    if (state.receivedNewMessage && message.temp_id === state.receivedNewMessage?.temp_id)
                        return state.receivedNewMessage
                    else return message
                })
                dispatch(setRetrievedMessagesWithoutTempData(userSendingNewMessages))
            } else {
                dispatch(setSingleMessageToRetrievedMessages(state.receivedNewMessage))
                if (!state.open) {
                    dispatch(addUnseenMessage(1))
                } else if (state.range.endIndex < state.retrievedMessages.length - 3) {
                    dispatch(setUserScrollingWhileReceivingMessage(true))
                    dispatch(addUnseenMessage(1))
                }
            }

            dispatch(iGotNewMessage(false))
            dispatch(setReceivedNewMessage(undefined))
        }
    }, [state.gotNewMessage, state.receivedNewMessage, state.open, state.retrievedMessages, state.unseenMessagesAmount])

    /**
     * Error handling if a message fails being sent
     */
    useEffect(() => {
        if (messageError && messageErrorId.length > 0) {
            const result = state.retrievedMessages.map(message => message.temp_id === messageErrorId ? {
                ...message,
                status: ChatStatusTypes.FAILED,
            } : message)
            dispatch(setRetrievedMessagesWithoutTempData(result))
            setMessageError(false)
            setMessageErrorId("")
        }
    }, [messageError, messageErrorId])

    /**
     * Send request to backend with message data
     * Also clear input field for user to write new message
     */
    const sendMessage = async (message: string) => {
        if (!user) return

        const tempId = uuidv4()
        const tempData = {
            channel_id: currentWorkspace?.deceased_user_id,
            created_at: undefined,
            message,
            sent_by_user: user.id,
            user: {
                nickname: user.nickname,
                last_name: user.last_name,
                id: user.id,
                profile_picture_url: user.profile_picture_url,
            },
            status: ChatStatusTypes.SENT,
            temp_id: tempId,
        }

        dispatch(setSingleMessageToRetrievedMessages(tempData))

        try {
            await Axios.post("/api/chat-message", {
                message,
                channelId: currentWorkspace?.deceased_user_id,
                tempId,
            })
        } catch (error) {
            setMessageError(true)
            setMessageErrorId(tempId)
        }
    }

    /**
     * If user is watching the last messages at the bottom
     * update badge and messageSinceLastLoggedin state
     */
    useEffect(() => {
        if (state.range.endIndex > state.retrievedMessages.length - 3 && !state.loading && state.unseenMessagesAmount > 0) {
            dispatch(gotNewMessagesSinceLastLoggedIn(false))
            dispatch(setUnseenMessagesAmount(0))
        }
    }, [state.range, state.loading])

    /**
     * Closes new messages dialog.
     */
    const handleMessagesSinceLastActive = () => {
        dispatch(gotNewMessagesSinceLastLoggedIn(false))
    }

    /**
     * If user receive message while scrolling, scrolls down and update context state.
     */
    const handleNewMessageWhileScrolling = () => {
        if (containerRef.current) {
            containerRef?.current.scrollToIndex({ index: state.retrievedMessages.length - 1, behavior: "auto" })
            dispatch(setUserScrollingWhileReceivingMessage(false))
        }
    }

    /**
     * Retry to send a message that failed
     */
    const retry = (message: IMessage) => Axios.post("/api/chat-message", {
        message: message.message,
        channelId: currentWorkspace?.deceased_user_id,
        tempId: message.temp_id,
    })

    /**
     * Here we check if the user scrolled to the top
     * and then dispatches an action that triggers a fetch
     */
    useEffect(() => {
        if (state.range.startIndex === 0 && state.initialized) {
            dispatch(isFetchingNewMessages(true))
        }
    }, [state.range])

    /**
     * Update last read message id on scrolling
     * @param items - Array of visible messages
     */
    const handleLastReadMessageId = (items: ListItems) => {
        if (items.length === 0 || state.isUserScrolling || !state.lastReadMessageId || !state.initialized || state.loading) return
        const message = items.slice(-1)[0].data ? items.slice(-1)[0].data as IMessage : state.retrievedMessages.slice(-1)[0]
        if (message.id && message.id > state.lastReadMessageId) {
            Axios.patch("/api/chat-message/update", {
                last_read_message_id: message.id,
                channel_id: currentWorkspace?.deceased_user_id,
            }).then(response => {
                if (response.status === 200) {
                    dispatch(setLastReadMessageId(message.id))
                }
            })
        }
    }

    /**
     * Updating context state so we know if the user is scrolling or not
     * @param userScrolling
     */
    const handleUserScrolling = (userScrolling: boolean) => {
        dispatch(setUserIsScrolling(userScrolling))
    }

    /**
     * Updates the scroll range to track what message index its close to
     * @param range
     */
    const handleUpdateScrollIndex = (range: ListRange) => {
        dispatch(setNewScrollRange(range))
    }

    const handleChatClose = () => {
        dispatch(toggleChat())
        GA("button", "HEB: Chat Close ", "Clicked")
    }

    if (!state.open) return null

    return ReactDOM.createPortal(
        <section className="chat">
            <div className="chat__header">
                <ForumIcon className="chat__header-icon" />
                <h3>{__("chat.workspace.header").toUpperCase()}</h3>
                <button onClick={handleChatClose}>
                    <CloseIcon />
                </button>
            </div>
            <div className="chat__content">
                {state.isFetchingPreviousMessages && state.nextQueryId && state.initialized && (
                    <div className="chat__loading-messages">
                        <Spinner />
                        <strong>{__("chat.status.loading-messages")}</strong>
                    </div>
                )}
                {state.retrievedMessages.length === 0 && !state.loading && (
                    <div className="chat__none">
                        <Placeholder
                            icon={icons.chat}
                            title={__("chat.workspace.empty-chat.title")}
                            description={__("chat.workspace.empty-chat.body")} />
                    </div>
                )}
                {state.loading && (
                    <div className="chat__none">
                        <Spinner />
                    </div>
                )}
                <Virtuoso
                    className="contain-scroll"
                    ref={containerRef}
                    totalCount={state.retrievedMessages.length}
                    initialTopMostItemIndex={state.retrievedMessages.length - state.unseenMessagesAmount}
                    style={{ height: "26.375rem" }}
                    data={state.retrievedMessages}
                    rangeChanged={handleUpdateScrollIndex}
                    itemsRendered={handleLastReadMessageId}
                    isScrolling={handleUserScrolling}
                    followOutput={
                        state.initialized && state.range.endIndex === state.retrievedMessages.length - 1 ||
                        state.range.endIndex + 1 === state.retrievedMessages.length - 1
                    }
                    itemContent={(index, data) => {
                        return (
                            <Message
                                key={index}
                                user={data.user}
                                created={data.created_at}
                                self={data.sent_by_user === user?.id}
                                status={data.status}
                                message={data.message}
                                id={data.id}
                                retry={() => retry(data)} />
                        )
                    }} />
                {!state.loading && state.gotNewMessagesSinceLastLoggedIn && state.unseenMessagesAmount > 0 && (
                    <button className="chat__new-messages" onClick={handleMessagesSinceLastActive}>
                        <strong>{__("chat.status.new-messages", state.unseenMessagesAmount)}</strong>
                        <HighlightOffIcon />
                    </button>
                )}
                {!state.loading && state.userScrollingWhileReceivingMessage && (
                    <button className="chat__new-messages" onClick={handleNewMessageWhileScrolling}>
                        <strong>{__("chat.status.new-messages", state.unseenMessagesAmount)}</strong>
                        <ArrowCircleDownIcon />
                    </button>
                )}
                <ChatMessageInput onSubmit={sendMessage} />
            </div>
        </section>,
        document.querySelector("#root") as Element
    )
}

export default Chatroom
