import React from "react"
import { Link } from "react-router-dom"
import { useGetGuideStatus } from "@/api/guide"
import useMediaQuery from "@mui/material/useMediaQuery"
import Spinner from "@/components/Spinner"
import StepButton from "@/components/StepButton"
import classnames from "classnames"
import { GA } from "@/utilities/googleAnalytics"

const GuideChapterSteppers = () => {
    const guideStatusQuery = useGetGuideStatus()
    const isLarge = useMediaQuery("(min-width:768px)")

    const handleGuideStepClick = (title: string) => {
        switch (title) {
        case "Att börja med":
            GA("button", "HEB: Step by step help - 1", "Clicked the button")
            break
        case "Praktiska saker":
            GA("button", "HEB: Step by step help - 2", "Clicked the button")
            break
        case "Hantera ett dödsbo":
            GA("button", "HEB: Step by step help - 3", "Clicked the button")
            break
        case "Ekonomi":
            GA("button", "HEB: Step by step help - 4", "Clicked the button")
            break
        case "Bouppteckning":
            GA("button", "HEB: Step by step help - 5", "Clicked the button")
            break
        case "Bostad":
            GA("button", "HEB: Step by step help - 6", "Clicked the button")
            break
        case "Arvskifte":
            GA("button", "HEB: Step by step help - 7", "Clicked the button")
            break
        case "Nu är ni klara":
            GA("button", "HEB: Step by step help - 8", "Clicked the button")
            break
        default:
            break
        }
    }

    let content = null
    const gridClass = classnames("step-button-grid", {
        "step-button-grid--compact": !isLarge,
    })

    if (guideStatusQuery.isLoading) {
        content = <Spinner />
    } else if (guideStatusQuery.data) {
        const chapters = guideStatusQuery.data.chapters

        console.log(chapters)

        content = (
            <div className={gridClass}>
                {chapters.map(chapter => (
                    <Link to={`/guide/${chapter.index}`} key={chapter.index} onClick={() => handleGuideStepClick(chapter.title)}>
                        <StepButton
                            index={chapter.index}
                            title={chapter.title}
                            progress={chapter.progress}
                            compact={!isLarge} />
                    </Link>
                ))}
            </div>
        )
    }


    return (
        <div className="guide-chapter-steppers">
            {content}
        </div>
    )
}

export default GuideChapterSteppers
