import { LeadType } from "@/components/Guide/types"
import { useCurrentWorkspace } from "@/api/user"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { AxiosError } from "axios"

const leadKeys = {
    all: ["leads"] as const,
    status: (id: number, type: LeadType) => [...leadKeys.all, id, type] as const,
}

const postLeadRequest = async (workspaceId: number, leadType: LeadType): Promise<LeadStatusResponse> => {
    const { data } = await Axios.post(`/api/workspaces/${workspaceId}/leads/${leadType}`)
    return data
}

export const useRequestLead = (leadType: LeadType) => {
    const queryClient = useQueryClient()
    const workspace = useCurrentWorkspace()
    const workspaceId = workspace?.deceased_user_id || 0

    return useMutation<LeadStatusResponse, AxiosError<Error>>(() => postLeadRequest(workspaceId, leadType), {
        async onSuccess(data) {
            const queryKey = leadKeys.status(workspaceId, leadType)

            queryClient.setQueryData(queryKey, data)
            await queryClient.invalidateQueries(queryKey)
        },
    })
}

interface LeadStatusResponse {
    requested: boolean
}

const getLeadStatus = async (workspaceId: number, leadType: LeadType): Promise<LeadStatusResponse> => {
    const { data } = await Axios.get(`/api/workspaces/${workspaceId}/leads/${leadType}`)
    return data
}

export const useGetLeadStatus = (leadType: LeadType) => {
    const workspace = useCurrentWorkspace()
    const workspaceId = workspace?.deceased_user_id || 0

    return useQuery(
        leadKeys.status(workspaceId, leadType),
        () => getLeadStatus(workspaceId, leadType),
        {
            enabled: Boolean(workspace),
        }
    )
}
