import React from "react"
import classnames from "classnames"

type CardProps = React.ComponentProps<"div">

const Card: React.FC<CardProps> = ({ children, className, ...props }) => {
    const classes = classnames("card", className, {})

    return (
        <div className={classes} {...props}>
            {children}
        </div>
    )

}

export default Card
