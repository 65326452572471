import React from "react"
import { Link, Redirect } from "react-router-dom"
import { Helmet } from "react-helmet"
import { routes } from "@/config/routes"
import Add from "@mui/icons-material/Add"
import ReactiveIcon from "resources/assets/svgs/icon_round_relative.svg"
import ProactiveIcon from "resources/assets/svgs/icon_round_user.svg"
import OnboardingLayout from "@/components/OnboardingLayout"
import { useCurrentWorkspace, useWorkspaces } from "@/api/user"

const UserNotFoundView: React.FC = () => {
    const workspaces = useWorkspaces()
    const currentWorkspace = useCurrentWorkspace()

    if (currentWorkspace) {
        const redirectUrl = workspaces && workspaces.length > 1
            ? routes.workspaces
            : routes.overview

        return <Redirect to={redirectUrl} />
    }

    return (
        <OnboardingLayout>
            <Helmet>
                <title>{__("onboarding.user-not-found.meta_title")}</title>
            </Helmet>
            <h1>{__("onboarding.user-not-found.title")}</h1>
            <h2>{__("onboarding.user-not-found.subtitle")}</h2>
            <Link className="onboarding__link onboarding__link--dashed" to={routes.registration.proactive}>
                <span className="onboarding__link-icon onboarding__link-icon--left"><ProactiveIcon /></span>
                <span className="onboarding__link-laabel">{__("onboarding.root.actions.create-proactive")}</span>
                <span className="onboarding__link-icon onboarding__link-icon--right"><Add /></span>
            </Link>
            <Link className="onboarding__link onboarding__link--dashed" to={routes.registration.reactive}>
                <span className="onboarding__link-icon onboarding__link-icon--left"><ReactiveIcon /></span>
                <span className="onboarding__link-label">{__("onboarding.user-not-found.actions.create-reactive")}</span>
                <span className="onboarding__link-icon onboarding__link-icon--right"><Add /></span>
            </Link>
        </OnboardingLayout>
    )

}

export default UserNotFoundView
