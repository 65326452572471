import React, { useRef, useEffect, JSXElementConstructor, MutableRefObject, RefObject } from "react"

interface IClosableProps {
    close: () => void
    children: React.ReactElement<unknown, string | JSXElementConstructor<unknown>>
    triggerRef?: RefObject<HTMLButtonElement>
}

const Closeable = React.forwardRef<HTMLElement, IClosableProps>(({ children, close, triggerRef }, ref) => {
    const _ref = useRef(null)
    const realRef = (ref ?? _ref) as MutableRefObject<HTMLElement>

    const handleClickOutside = (event: MouseEvent) => {
        const target = event.target as Element
        if(!realRef.current.contains(target) && target !== triggerRef?.current) {
            close()
        }
    }

    const handleKeyPress = (event: KeyboardEvent) => {
        if(event.key === "Escape"){
            close()
        }
    }

    useEffect(() => {
        document.addEventListener("click", handleClickOutside, true)
        document.addEventListener("keydown", handleKeyPress, true)

        return () => {
            document.removeEventListener("click", handleClickOutside, true)
            document.removeEventListener("keydown", handleKeyPress, true)
        }
    }, [ref, handleClickOutside])

    return React.cloneElement(children, { ref: realRef })
})

Closeable.displayName = "Closable"

export default Closeable
