import React from "react"

interface DateInputProps extends React.ComponentProps<"input"> {
    label?: string
}

export const DateInput: React.FC<DateInputProps> = (props) => {
    return (
        <label className="input__wrapper">
            {props.label && <span className="input__label">{props.label}</span>}
            <input className="input" type="date" {...props} />
        </label>
    )
}
