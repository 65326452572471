import React from "react"
import Section from "@/components/Section"
import Container from "@/components/Container"
import Card from "@/components/Card"
import Error from "@mui/icons-material/Error"
import { GuideStep } from "@/components/Guide/types"
import Button from "@/components/Button"
import classnames from "classnames"
import { useToggleState } from "@/utilities/useToggleState"
import Prose from "@/components/Prose"
import { icons } from "@/config/icons"
import { renderHtml } from "@/components/Guide/utils"
import StepLead from "@/components/Guide/Steps/StepLead"

const COLLAPSED_HEIGHT = 360

const StepLayout: React.FC<GuideStep> = ({ children, title, description, required, lead }) => {
    const contentWrapperRef = React.useRef<HTMLDivElement>(null)
    const [isExpanded, toggleExpansion] = useToggleState(false)
    const [isCollapsible, setIsCollapsible] = React.useState(true)

    React.useLayoutEffect(() => {
        if (!contentWrapperRef.current) return

        setIsCollapsible(contentWrapperRef.current.scrollHeight > COLLAPSED_HEIGHT)
    }, [])

    const contentClassName = classnames("step__content", {
        "step__content--collapsed": isCollapsible && !isExpanded,
    })

    const handleKeyToggle = (e: React.KeyboardEvent<HTMLDivElement>) => {
        switch (e.key) {
        case "Escape":
            return toggleExpansion(false)
        case "Enter":
            return toggleExpansion()
        default:
            return
        }
    }

    return (
        <Section className="bg-gray-750">
            <Container>
                <Card>
                    <div
                        className="step__header" onClick={() => toggleExpansion()} role="button" tabIndex={0}
                        onKeyDown={handleKeyToggle}>
                        <h1 className="mr-auto">{title}</h1>
                        {required && (
                            <strong className="font-danger-600">
                                <Error />
                                <span className="step__obligation-info">{__("common.dictionary.required")}</span>
                            </strong>
                        )}
                        {isCollapsible && isExpanded ? icons.expandLess : icons.expandMore}
                    </div>
                    <div className={contentClassName} ref={contentWrapperRef}>
                        {description && (
                            <div className="step__description">
                                <Prose>{renderHtml(description)}</Prose>
                            </div>
                        )}
                        {lead && (
                            <div className="mb-15">
                                <StepLead lead={lead} />
                            </div>
                        )}
                        {children}
                    </div>
                    {isCollapsible && (
                        <div className="mt-8 text-center">
                            <Button outlined className="gray-button--outlined" onClick={() => toggleExpansion()}>
                                {isExpanded ? __("common.dictionary.minimize") : __("common.dictionary.expand")}
                            </Button>
                        </div>
                    )}
                </Card>
            </Container>
        </Section>
    )
}

export default StepLayout
