import { useCallback } from "react"
import { UserResource, WorkspaceResource } from "@/types/models/User"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { useHistory } from "react-router-dom"
import { routes } from "@/config/routes"

export enum UserQuery {
    Get = "user/get",
}

const getUser = async (): Promise<UserResource> => {
    const { data } = await Axios.get("/api/user")

    return data
}

export const useGetUserQuery = () => {
    return useQuery(UserQuery.Get, getUser, {
        refetchOnWindowFocus: false,
        staleTime: Infinity,
        retry: false,
    })
}

const patchUser = async (user: Partial<UserResource>): Promise<Partial<UserResource>> => {
    const { data } = await Axios.patch("/api/user", user)

    return data
}

export const useUpdateUserMutation = () => {
    const queryClient = useQueryClient()

    return useMutation(patchUser, {
        onSuccess: async () => {
            await queryClient.invalidateQueries(UserQuery.Get)
        },
    })
}

const updateProfilePicture = async (image: File | null): Promise<{ profile_picture_url: string | null }> => {
    let body
    if (image instanceof File) {
        body = new FormData()
        body.append("image", image)
    } else {
        body = { image }
    }

    const { data } = await Axios.post("/api/user/profile-image", body)

    return data
}

export const useUpdateProfilePictureMutation = () => {
    const queryClient = useQueryClient()

    const setProfilePictureUrl = useCallback((url: null | string) => {
        queryClient.setQueryData<UserResource | undefined>(UserQuery.Get, (user) => {
            if (!user) return

            const workspaces = user.workspaces.map(workspace => {
                if (workspace.deceased_user_id !== user.id) return workspace
                return { ...workspace, profile_picture_url: url }
            })

            return { ...user, profile_picture_url: url, workspaces }
        })
    }, [])

    return useMutation(updateProfilePicture, {
        onMutate: (image) => {
            if (!image) {
                setProfilePictureUrl(null)
            }
        },
        onSuccess: async ({ profile_picture_url }) => {
            setProfilePictureUrl(profile_picture_url)
        },
    })
}

export const useWorkspaces = () => {
    const queryClient = useQueryClient()

    const user = queryClient.getQueryData<UserResource | undefined>(UserQuery.Get)
    return user?.workspaces || []
}

export const useCurrentWorkspace = () => {
    const { data: user } = useGetUserQuery()

    return user?.current_workspace
}

export const useIsProactiveWorkspace = () => {
    const { data: user } = useGetUserQuery()

    if (user) {
        return user.current_workspace?.deceased_user_id === user.id
    }

    return false
}

const updateCurrentWorkspace = async (relationId: number): Promise<WorkspaceResource> => {
    const { data } = await Axios.patch("/api/user/relation/" + relationId)
    return data
}

export function useSetCurrentWorkspaceMutation() {
    const queryClient = useQueryClient()
    const history = useHistory()

    return useMutation(updateCurrentWorkspace, {
        onSuccess: async (workspace) => {
            queryClient.setQueryData<UserResource | undefined>(UserQuery.Get, (user) => {
                if (!user) return

                return { ...user, current_workspace: workspace }
            })

            queryClient.invalidateQueries()

            history.push(routes.overview)
        },
    })
}

export function useIsLoggedIn() {
    const { data: user } = useGetUserQuery()
    return !!user
}

const deleteUser = async () => {
    return await Axios.delete("/api/user/")
}

export function useDeleteUserMutation() {
    const queryClient = useQueryClient()
    const history = useHistory()

    return useMutation(deleteUser, {
        onSuccess: async () => {
            await queryClient.invalidateQueries()
            history.push(routes.root)
        },
    })
}
