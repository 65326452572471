import { ArrowForward } from "@mui/icons-material"
import React from "react"

const DvSupport = () => {
    return (
        <div id="support" className="dv-support-container">
            <a href="#support" aria-hidden="true">
            </a>
            <h1>Ditt digitala valv</h1>
            <p>Att ha ordning på viktiga papper ger en känsla av trygghet och säkerhet. Genom att organisera och hålla reda på viktiga dokument som identifikationshandlingar, försäkringspapper, kontrakt och liknande kan du enkelt komma åt dem vid behov och undvika stress och panik i oväntade situationer.</p>
            <div className="dv-support-button">
                <a href="https://fernita.se/tjanster/digitala-valvet" className="button--dv-support"><b>Läs mer </b><ArrowForward /></a>
            </div>
        </div>




    )
}

export default DvSupport