import React, { useState } from "react"
import { useGetUserQuery } from "@/api/user"
import CircleCheck from "resources/assets/svgs/circle-check.svg"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import ArrowForwardIcon from "@mui/icons-material/ArrowForward"
import NumberCircle from "../NumberCircle"
const DvWalkthrough = () => {
    const { data: user } = useGetUserQuery()
    const dvSteps = [
        {
            title: "Ladda upp din första fil",
            text: "Genom att ladda upp, tar du det första steget i processen.",
        },
        {
            title: "Bjud in kontaktpersoner",
            text: "Förbered för framtiden - bjud in en kontaktperson som får tillgång till viktig information om något händer.",
        },
        {
            title: "Utforska vad mer vi erbjuder",
            text: "Ta dig tid att lära känna sidan och alla de tillgängliga funktionerna för att maximera din användning av vår tjänst.",
        }
    ]
    const [currentStep, setCurrentStep] = useState(0)
    const canGoBack = currentStep > 0
    const canGoForward = currentStep < dvSteps.length - 1
    const [closed, setClosed] = useState(() => {
        const savedState = localStorage.getItem("walkthrough-dv-closed")
        return savedState !== null ? JSON.parse(savedState) : false
    })
    const updateClosedState = (newClosedState: boolean) => {
        localStorage.setItem("walkthrough-dv-closed", JSON.stringify(newClosedState))
        setClosed(newClosedState)
    }
    return (
        !closed ? (
            <div className={`walkthrough-container ${closed ? "closed" : ""}`}>
                <div className="walkthrough-left-box">
                    <div className="walkthrough-intro">
                        <h3>Hej {user ? user.nickname : ""}!</h3>
                        <p>Vi ska hjälpa dig att komma igång, för att du ska kunna fokusera på det som spelar roll; minnen, viktiga handlingar och mycket mer.</p>
                    </div>
                    <div className="walkthrough-list">
                        <ul>
                            {dvSteps.map((item, index) => (
                                <li className="" key={index}>
                                    <svg className={`walkthrough-outro-icon ${currentStep !== index ? "inactive" : ""}`}>
                                        <NumberCircle currentStep={index + 1} width={17} height={17} />
                                    </svg>
                                    <span className={`walkthrough-outro-text ${currentStep !== index ? "inactive" : ""}`}>{item.title}</span>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className="walkthrough-outro">
                        <CircleCheck className="walkthrough-outro-icon" width={17} height={17} />
                        <span className="walkthrough-outro-text">
                            <b>Klar med alla steg?</b> Då kan du{" "}
                            <button className="walkthrough-outro-button" onClick={() => updateClosedState(!closed)}>
                                stänga guiden.
                            </button>{" "}
                            Den nås igen i övre högra hörnet.
                        </span>
                    </div>
                </div>
                <div className="walkthrough-right-box">
                    <div className="walkthrough-right-box-upper">
                        <NumberCircle currentStep={currentStep + 1} width={46} height={46} />
                        <h3>{dvSteps[currentStep].title}</h3>
                        <p>{dvSteps[currentStep].text}</p>
                    </div>
                    <div className="walkthrough-right-box-inner">
                        <div className="walkthrough-right-box-arrows">
                            <button className={!canGoBack ? "inactive" : ""} onClick={() => setCurrentStep(currentStep - 1)} disabled={!canGoBack}>
                                <ArrowBackIcon />
                            </button>
                            <button className={!canGoForward ? "inactive" : ""} onClick={() => setCurrentStep(currentStep + 1)} disabled={!canGoForward}>
                                <ArrowForwardIcon />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
            : (
                <div className={`start-guide ${!closed ? "closed" : ""}`}>
                    <button onClick={() => updateClosedState(!closed)}>
                        <u><b>STARTA KOM-IGÅNG-HJÄLPEN</b></u>
                    </button>
                </div>
            )
    )
}
export default DvWalkthrough