import React from "react"
import classnames from "classnames"

// TODO: Extract props common with IButtonProps to another interface and extend that one.
interface IconButtonProps extends React.ComponentProps<"button"> {
    icon: JSX.Element
    variant?: "primary" | "secondary" | "danger"
    size?: "medium" | "small" | "large"
    outlined?: boolean
}

const IconButton = ({
    icon,
    variant = "primary",
    size = "medium",
    outlined = false,
    className,
    ...props
}: IconButtonProps) => {
    const buttonClassName = classnames(className, "icon-button", {
        "button--secondary": variant === "secondary",
        "button--danger": variant === "danger",
        "button--small": size === "small",
        "button--large": size === "large",
        "button--outlined": outlined,
    })

    return (
        <button className={buttonClassName} {...props}>
            {icon}
        </button>
    )
}

export default IconButton
