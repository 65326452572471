import React from "react"
import Spinner from "@/components/Spinner"

const Initialiser = () => (
    <div className="initaliser">
        <Spinner />
        <p>{__("misc.loading")}</p>
    </div>
)


export default Initialiser
