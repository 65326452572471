import React from "react"
import Backlink from "@/components/Backlink"
import Spinner from "@/components/Spinner"
import FooterNavigation from "../FooterNavigation"

interface IOnboardingLayoutProps {
    hasBacklink?: boolean
    isLoading?: boolean
}

const OnboardingLayout: React.FC<IOnboardingLayoutProps> = ({ children, hasBacklink = false, isLoading = false }) => {
    return (
        <div className="onboarding__container">
            <section className="onboarding__section onboarding__section--content">
                {isLoading ? (
                    <Spinner />
                ) : (

                    <>
                        <div className="onboarding__section--inner">

                            {hasBacklink && <Backlink />}
                            {children}
                        </div>
                        <div className="onboarding__footer"></div>
                        <FooterNavigation />
                    </>
                )}
            </section>


        </div>
    )
}

export default OnboardingLayout
