/**
 * Components
 */

export enum ComponentType {
    Input = "input",
    RadioGroup = "radio",
    Repeater = "repeater",
    Select = "select",
}

export enum ComponentInputType {
    Checkbox = "checkbox",
    Date = "date",
    Email = "email",
    Number = "number",
    Phone = "phone",
    Text = "text",
    TextArea = "textarea",
}

interface IComponentBase {
    label: string
}

export interface IInputComponent extends IComponentBase {
    type: ComponentType.Input
    id: string
    subtype: ComponentInputType
    required: boolean
}

export interface ISelectComponent extends Omit<IInputComponent, "type" | "subtype"> {
    type: ComponentType.Select
    other_choice: {label: string; components: GuideComponent[]} | null
    options: Record<string, string>
}

export interface IRadioGroupComponent extends Omit<ISelectComponent, "type"> {
    type: ComponentType.RadioGroup
}

export interface IRepeaterComponent extends IComponentBase {
    type: ComponentType.Repeater
    id: string
    min: number | null
    max: number | null
    components: GuideComponent[]
}

export type GuideComponent = IInputComponent
    | ISelectComponent
    | IRadioGroupComponent
    | IRepeaterComponent


/**
 * Section
 */

export interface ISection {
    title: string
    components: GuideComponent[]
    more_info: null | string
}


/**
 * Step
 */

export enum StepType {
    Checklist = "checklist",
    Confirmation = "confirmation",
    Form = "form",
    Information = "information",
    Special = "special",
}

export enum LeadType {
    Social = "social",
    Insurance = "insurance",
}

export interface IStepLead {
    type: LeadType
    label: {
        default: string
        requested: string
    }
    description: string
}

interface IStepBase {
    type: StepType
    id: string
    title: string
    description?: string | null
    required: boolean
    lead: IStepLead | null
}

export interface IChecklistStep extends IStepBase {
    type: StepType.Checklist
    entries: Array<{id: string; label: string}>
}

export interface IConfirmationStep extends IStepBase {
    type: StepType.Confirmation
    confirmation_label: string
    cta: { title: string; url: string } | null
}

export interface IInformationStep extends Omit<IConfirmationStep, "type"> {
    type: StepType.Information
    entries: Array<{title: string; text: string}>
}

export interface IFormStep extends IStepBase {
    type: StepType.Form
    sections: ISection[]
    pdf_export: string | null
}

export type GuideStep = IChecklistStep
    | IConfirmationStep
    | IFormStep
    | IInformationStep


/**
 * Chapter
 */

export interface IChapter {
    index: number
    title: string
    description: string | null
    steps: GuideStep[]
}
