import React from "react"
import Modal, { FormActions, ModalBody, ModalFooter, ModalHeader } from "@/components/Modal"
import Button from "@/components/Button"
import { useVaultContext } from "@/components/Vault/Views/DefaultServiceView"
import { icons } from "@/config/icons"
import { VaultModalProps } from "@/views/VaultServiceView/types"
import Heading from "@/components/Heading"
import parseHtml from "html-react-parser"

const DeleteVaultItemModal: React.FC<VaultModalProps> = ({ closeModal, id , title }) => {
    const { removeItem } = useVaultContext()

    const handleDeletion = () => {
        removeItem?.(id)
        closeModal()
    }
    return (
        <Modal closeModal={closeModal}>
            <ModalHeader>
                <Heading level={2} icon={icons.warning} title={__("common.dictionary.delete")} />
            </ModalHeader>
            <ModalBody>
                <p>{parseHtml(__("vault.confirmation.delete-item", { name: title }))}</p>
            </ModalBody>
            <ModalFooter>
                <FormActions>
                    <Button variant="secondary" onClick={closeModal}>
                        {__("common.dictionary.cancel")}
                    </Button>
                    <Button variant="danger" onClick={handleDeletion}>
                        {__("common.dictionary.delete")}
                    </Button>
                </FormActions>
            </ModalFooter>
        </Modal>
    )
}

export default DeleteVaultItemModal
