import React, { useState, useEffect } from "react"
import Modal, { IModalProps } from "@/components/Modal"
import { IFileResource } from "@/views/VaultServiceView/types"
import { Document, Page } from "react-pdf/dist/esm/entry.webpack5"
import Spinner from "@/components/Spinner"
import "react-pdf/dist/esm/Page/TextLayer.css"
import "react-pdf/dist/esm/Page/AnnotationLayer.css"
import ReactPlayer from "react-player"
import { PlayArrow, Pause } from "@mui/icons-material"
import { icons } from "@/config/icons"
import { SizeMe } from "react-sizeme"
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos"
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos"

interface PreviewProps extends IModalProps {
    content: IFileResource
    files: IFileResource[] | undefined
}
interface IDocumentLoad {
    // TODO: fix this type on numPages
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    numPages: any
}

const PeviewFileModal: React.FC<PreviewProps> = ({
    content,
    files,
    ...modalProps
}) => {
    const [isLoading, setIsLoading] = useState(false)
    const [numPages, setNumPages] = useState(null)
    const [pageNumber, setPageNumber] = useState(1)
    const [shouldPlay, setShouldPlay] = useState(true)
    const [activeFile, setActiveFile] = useState<IFileResource>(content)



    const videoFormats = ["webm",
        "WEBM",
        "mp3",
        "MP3",
        "mp4",
        "MP4",
        "mov",
        "MOV"]
    let browser = ""

    if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
        browser = "Safari"
    }
    //const isMobile = /Android|iPhone/i.test(navigator.userAgent)
    if (!content) {
        throw new Error("something went wrong")
    }

    function onDocumentLoadSuccess({ numPages }: IDocumentLoad) {
        setNumPages(numPages)
    }
    function downloadFile(content: IFileResource) {
        const a = window.document.createElement("a")
        a.href = `/api/files/download/${content.id}`
        a.download = content.title
        a.click()
    }

    const switchFile = (direction: string) => {
        if (files) {
            const index = files.map((item) => item.id).indexOf(activeFile.id)
            switch (direction) {
            case "right":
                if (index === files.length - 1) {
                    setActiveFile(files[0])
                    return
                }
                setActiveFile(files[index + 1])
                return
            case "left":
                if (index === 0) {
                    setActiveFile(files[files.length - 1])
                    return
                }
                setActiveFile(files[index - 1])
                return
            default:
                return
            }
        } else {
            return
        }
    }

    useEffect(() => {
        void (async function () {
            setIsLoading(true)
            const a = window.document.createElement("a")
            a.href = `/api/files/download/${content.id}`
            setIsLoading(false)
        })()
    }, [])
    if (browser === "Safari" && videoFormats.includes(activeFile.type)) {
        return (
            <Modal {...modalProps}>
                <div className="preview-error">
                    {activeFile.title ? (
                        <h1>
                            {activeFile.title} kunde inte förhandsgranskas
                        </h1>
                    ) : (
                        <h1>Filen kunde inte förhandsgranskas</h1>
                    )}
                    <p>Var god att ladda ner filen</p>
                    <div className="preview-error-button">
                        <button
                            className="left-button-navigation-nonfile"
                            onClick={() => switchFile("left")}>
                            <ArrowBackIosIcon />
                        </button>
                        <button
                            className="right-button-navigation-nonfile"
                            onClick={() => switchFile("right")}>
                            <ArrowForwardIosIcon />
                        </button>
                        <button
                            onClick={() => downloadFile(activeFile)}
                            type="button"
                            aria-label="download">
                            {__("common.dictionary.download")}
                            {icons.download}
                        </button>
                    </div>
                </div>
            </Modal>
        )
    }
    else {


        switch (activeFile.type) {
        case "pdf":
        case "PDF": {
            return (
                <Modal {...modalProps}>
                    {isLoading ? (
                        <Spinner />
                    ) : (
                        <SizeMe
                            monitorHeight
                            refreshRate={128}
                            refreshMode="debounce">
                            {({ size }) => (
                                <div className="pdf-document">
                                    <Document
                                        file={`/api/files/download/${activeFile.id}`}
                                        onLoadSuccess={onDocumentLoadSuccess}
                                        loading={<Spinner />}>
                                        <Page
                                            pageNumber={pageNumber}
                                            width={
                                                size.width
                                                    ? size.width
                                                    : undefined
                                            } />
                                    </Document>
                                    <button
                                        className="left-button-navigation"
                                        onClick={() => switchFile("left")}>
                                        <ArrowBackIosIcon />
                                    </button>
                                    <button
                                        className="right-button-navigation"
                                        onClick={() => switchFile("right")}>
                                        <ArrowForwardIosIcon />
                                    </button>
                                    <div className="page-controls">
                                        <span>
                                            {pageNumber} of {numPages}
                                        </span>
                                        {numPages && (
                                            <>
                                                <button
                                                    disabled={pageNumber <= 1}
                                                    onClick={() => setPageNumber(pageNumber - 1)}
                                                    type="button"
                                                    aria-label="Previous page">
                                                        ‹
                                                </button>
                                                <button
                                                    disabled={pageNumber >= numPages}
                                                    onClick={() => setPageNumber(
                                                        pageNumber + 1
                                                    )}
                                                    type="button"
                                                    aria-label="Next page">
                                                        ›
                                                </button>
                                            </>
                                        )}
                                    </div>
                                </div>
                            )}
                        </SizeMe>
                    )}
                </Modal>
            )
        }
        case "jpg":
        case "JPG":
        case "jpeg":
        case "JPEG":
        case "png":
        case "PNG": {
            return (
                <Modal {...modalProps}>
                    {isLoading ? (
                        <Spinner />
                    ) : (
                        <>
                            <button
                                className="left-button-navigation"
                                onClick={() => switchFile("left")}>
                                <ArrowBackIosIcon />
                            </button>
                            <img
                                src={`/api/files/download/${activeFile.id}`}
                                alt="test" />
                            <button
                                className="right-button-navigation"
                                onClick={() => switchFile("right")}>
                                <ArrowForwardIosIcon />
                            </button>
                        </>
                    )}
                </Modal>
            )
        }
        case "webm":
        case "WEBM":
        case "mp3":
        case "MP3":
        case "mp4":
        case "MP4":
        case "mov":
        case "MOV": {
            return (
                <Modal {...modalProps}>
                    {isLoading ? (
                        <Spinner />
                    ) : (
                        <div className="player-wrapper">
                            <button
                                className="left-button-navigation"
                                onClick={() => switchFile("left")}>
                                <ArrowBackIosIcon />
                            </button>
                            <button
                                className="right-button-navigation"
                                onClick={() => switchFile("right")}>
                                <ArrowForwardIosIcon />
                            </button>
                            <ReactPlayer
                                playsInline
                                autoPlay
                                className="react-player"
                                url={`/api/files/download/${activeFile.id}`}
                                playing={shouldPlay}
                                width="100%"
                                height="100%" />
                            <div className="player-controls">
                                <button
                                    onClick={() => setShouldPlay(!shouldPlay)}
                                    type="button"
                                    aria-label="puse">
                                    {shouldPlay ? <Pause /> : <PlayArrow />}
                                </button>
                            </div>
                        </div>
                    )}
                </Modal>
            )
        }
        default: {
            return (
                <Modal {...modalProps}>
                    <div className="preview-error">
                        {activeFile.title ? (
                            <h1>
                                {activeFile.title} kunde inte förhandsgranskas
                            </h1>
                        ) : (
                            <h1>Filen kunde inte förhandsgranskas</h1>
                        )}
                        <p>Var god att ladda ner filen</p>
                        <div className="preview-error-button">
                            <button
                                className="left-button-navigation-nonfile"
                                onClick={() => switchFile("left")}>
                                <ArrowBackIosIcon />
                            </button>
                            <button
                                className="right-button-navigation-nonfile"
                                onClick={() => switchFile("right")}>
                                <ArrowForwardIosIcon />
                            </button>
                            <button
                                onClick={() => downloadFile(activeFile)}
                                type="button"
                                aria-label="download">
                                {__("common.dictionary.download")}
                                {icons.download}
                            </button>
                        </div>
                    </div>
                </Modal>
            )
        }
        }
    }
}

export default PeviewFileModal