import React from "react"
import Notifications from "@/components/Notifications"
import { useIsProactiveWorkspace } from "@/api/user"

const View: React.FC = ({ children }) => {

    const isProactiveWorkspace = useIsProactiveWorkspace()
    return (
        <main id={isProactiveWorkspace ? "page-content" : "gray-page-content"}>
            <Notifications />
            {children}
        </main>
    )
}

export default View
