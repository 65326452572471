import { Optional } from "@/utilities/types"


/**
 * Type Definitions
 */

export enum CredentialCategory {
    WebAccount = "web-account",
    Email = "email",
    Passport = "passport",
    Passcode = "passcode",
    Banking = "banking",
    Insurance = "insurance",
    Membership = "membership",
    Other = "other",
}

export interface CredentialResource {
    id: number
    category_key: CredentialCategory
    title: string
    description?: string
    // TODO: make nullable
    entries: CredentialEntry[]
}

export interface CredentialEntry {
    label: string
    value: string
}

export type CredentialFormData = Optional<CredentialResource, "id">

export enum CrudOperation {
    Create = "create",
    Read = "read",
    Update = "updated",
}
