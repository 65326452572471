import { useQuery, useQueryClient, useMutation } from "react-query"

export type RelativeForm = Omit<Relative, "id">;
export interface Relative {
    id: number
    first_name: string
    last_name: string
    email: string
    phone: string
    ssn: string
    type: "user" | "invite"
}

export enum RelativesQuery {
    GetRelatives = "relatives"
}


/**
 * Get relatives
 */

const getRelatives = async (): Promise<Relative[]> => {
    const { data } = await Axios.get("/api/relatives/")
    return data
}

export const useGetRelatives = () => {
    return useQuery(RelativesQuery.GetRelatives, getRelatives, {
        refetchOnWindowFocus: false,
        staleTime: Infinity,
    })
}


/**
 * Update relative
 */

const patchRelative = async (relative: Relative) => {
    const { data } = await Axios.patch<Relative>(`/api/relatives/${relative.id}`, {
        first_name: relative.first_name,
        last_name: relative.last_name,
        email: relative.email,
        phone: relative.phone,
    })

    return data
}

export const usePatchRelative = () => {
    const queryClient = useQueryClient()

    return useMutation(patchRelative, {
        onSuccess: () => {
            queryClient.invalidateQueries(RelativesQuery.GetRelatives)
        },
    })
}


/**
 * Delete relative
 */

const deleteRelative = (id: number) => Axios.delete(`/api/relatives/${id}`)

export const useDeleteRelative = () => {
    const queryClient = useQueryClient()

    return useMutation(deleteRelative, {
        onSuccess: () => {
            queryClient.invalidateQueries(RelativesQuery.GetRelatives)
        },
    })
}


/**
 * Create relative
 */

const postRelative = async (relative: RelativeForm) => {
    const { data } = await Axios.post<Relative>("/api/relatives/", relative)
    return data
}

export const useCreateRelative = () => {
    const queryClient = useQueryClient()

    return useMutation(postRelative, {
        onSuccess: () => {
            queryClient.invalidateQueries(RelativesQuery.GetRelatives)
        },
    })
}
