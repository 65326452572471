import { ArrowBack } from "@mui/icons-material"
import React from "react"
import Modal, { IModalProps } from "../Modal"
const DemiseModal: React.FC<IModalProps> = ({ ...modalProps }) => {
    return (
        <Modal {...modalProps}>
            <button className=" demiseModal-backlink button button--rectangle button--white" onClick={() => modalProps.closeModal()}><ArrowBack />Tillbaka</button>
            <div className="modalContainer">
                <h1>
                    Stegvis hjälp vid anhörigs bortgång
                </h1>
                <p>
                    Oavsett om döden var väntad eller kom plötsligt så är en anhörigs bortgång aldrig lätt.Inte nog med att du måste hantera sorgen över personen du mist, som närstående förväntas du dessutom ta hand om din anhöriges dödsbo och allt det han eller hon har lämnat efter sig.För att arbetet med att sköta ett dödsbo och alla saker relaterade till detta inte ska kännas för övermäktigt har vi sammanställt en checklista över de saker som måste tas hand om efter en närståendes död.
                    Tänk på att du också behöver tid till att bearbeta sorgen.
                </p>
                <div className="modalQuestions">
                    <div className="modalQuestions--image" />
                    <div className="modalQuestions--text-box">
                        <h4>Frågor? Vi finns här för dig!</h4>
                        <p>Är det något du undrar över eller behöver hjälp med? Tveka inte att kontakta oss – vi finns här för att stötta dig genom processen.</p>
                        <div className="modalQuestions--buttons">
                            <a className="button button--rectangle button--wide button--dark-green" href="mailto:?subject=Tips!"> KONTAKTA OSS </a>
                            <a href="/register/reactive" className="button button--rectangle button--white button--wide">SKAPA ARBETSYTA FÖR STÖD</a>
                        </div>

                    </div>
                </div>
                <div className="demiseModal-secound">
                    <h3>En tjänst med stöd genom hela processen</h3>
                    <p className="demiseModal-transparent-text">När någon går bort finns det mycket att ta hand om. Använd tjänsten Hantera en Bortgång för att få en guide genom processen.
                    I varje steg får du relevant information, checklistor och mallar. Använd förvaringsutrymmet
                    för att spara viktiga dokument som testamente, samt minnen som brev och foton.
                    Bank ID och en sökning via SPAR garanterar korrekta uppgifter,
                    och många fält kommer att vara förifyllda. Du kan lägga till anhöriga och kommunicera
                    via den inbyggda chatten. Tjänsten är tillgänglig i 12 månader och kan förlängas för en årsavgift.
                    Ditt konto kan sedan fungera som ett digitalt minnesrum, där ni kan fortsätta att dela minnen och
                    dokument. Alla mallar och formulär är kvalitetssäkrade.
                    </p>
                    <h3>
                    I tjänsten ingår:
                    </h3>
                    <ul className="demiseModal-transparent-text demiseModal-list-items">
                        <li>
                        Stegvis hjälp
                        </li>
                        <li>
                        Checklistor
                        </li>
                        <li>
                        Samarbetsyta
                        </li>
                        <li>
                        Chattfunktion
                        </li>
                        <li>
                        Dokumentmallar
                        </li>
                        <li>
                        Information
                        </li>
                        <li>
                        Tydlig mappstruktur
                        </li>
                        <li>
                        Minnesrum
                        </li>
                        <li>
                        Våra kontakter
                        </li>
                    </ul>
                    <p className="demiseModal-transparent-text">Med Hantera en bortgång, är det praktiska och känslomässiga sammanflätade. Vi går genom detta tillsammans, en dag i taget, med omsorg och förståelse.</p>
                    <div className="demiseModal-unsplash" />
                    <h3>Börja med det viktigaste – informera nära och kära</h3>
                    <p className="demiseModal-transparent-text">Det första du måste göra är att informera folk i personens närhet om att han eller hon har dött. I första hand ska du kontakta de övriga delägarna i dödsboet, dvs. barn, barnbarn, syskon och andra familjemedlemmar som har direkt rätt till arv eller bodelning. Mer om vilka som har arvsrätt kan du läsa om i vår artikel “Vem ärver och av vem?”.</p>
                    <p className="demiseModal-transparent-text">När detta är klart kan du gå vidare med övriga släktingar, samt de vänner och bekanta som du vet att personen umgicks med. Det kan även vara klokt att informera grannarna om att din släkting har gått bort och att be dem hålla ett extra öga på bostaden under tiden den är i dödsboets ägo. Du bör även kontakta hemtjänst och personlig assistans om personen hade sådan hjälp.</p>
                </div>
                <div className="demiseModal-get-started">
                    <div className="demiseModal-get-started--image" />
                    <div className="demiseModal-get-started--text">
                        <h4>Kom igång med Hantera en Bortgång</h4>
                        <p>Förenkla processen och få tillgång till våra resurser nu genom att skapa en till arbetsyta. </p>
                        <a className="button button--rectangle button--white button--wide" href="https://app.staging.fernita.se/register/reactive"> SKAPA ARBETSYTA </a>

                    </div>

                </div>
            </div>


        </Modal>
    )
}

export default DemiseModal