import React from "react"

interface MediumCardProps {
    icon: JSX.Element
    title: string
    subtitle?: string
    label: string
    onClick?: () => void
    disabled?: boolean
    className: string
}

const MediumCard = ({ icon, title, subtitle, label, onClick, disabled = false, className }: MediumCardProps) => {
    const divProps: React.ComponentProps<"div"> = {}
    if (onClick) {
        divProps.onClick = onClick
        divProps.role = "button"
    }

    if (disabled) {
        divProps["aria-disabled"] = true
        divProps.tabIndex = -1
    }

    return (
        <div {...divProps} className="medium-card">
            {icon}
            <div className="medium-card__title">{title}</div>
            <div className="medium-card__subtitle">{subtitle}</div>
            <div className={className}>{label}</div>
        </div>
    )
}

export default MediumCard
