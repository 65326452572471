import React, { useContext, useState } from "react"
import { Link, useHistory } from "react-router-dom"
import UserProfile from "@/components/UserProfile"
import FernitaLogo from "resources/assets/svgs/fernita-logo-default.svg"
import { toggleChat } from "@/contexts/ChatContext/actionTypes"
import ForumIcon from "@mui/icons-material/Forum"
import Button from "@/components/Button"
import { ChatContext } from "@/contexts/ChatContext"
import useMediaQuery from "@mui/material/useMediaQuery"
import Badge from "@mui/material/Badge"
import { routes } from "@/config/routes"
import MatchMedia from "@/components/MatchMedia"
import { MediaRule } from "@/utilities/useMatchMedia"
import IconButton from "@/components/IconButton"
import ProfileMenuModal from "@/components/ProfileMenuModal"
import { useCurrentWorkspace, useGetUserQuery } from "@/api/user"
import { GA } from "@/utilities/googleAnalytics"
import HebIntroModal from "../HebIntroModal"

const badgeStyle = {
    color: "error",
    anchorOrigin: { vertical: "top", horizontal: "right" },
    max: 99,
    sx: { "& .MuiBadge-badge": { fontFamily: "inherit" } },
    overlap: "circular",
} as const

const NavigationBar = () => {
    const { state, dispatch } = useContext(ChatContext)
    const [openHebModal, setOpenHebModal] = useState(false)
    const [modalOpen, setModalOpen] = useState(false)
    const workspace = useCurrentWorkspace()
    const history = useHistory()
    const isScreenBig = useMediaQuery("(min-width:48rem)")
    const { data: user } = useGetUserQuery()


    const showChat = workspace && !workspace.has_sole_actor && workspace.deceased_user_id !== (user && user.id)


    const handleChat = () => {
        dispatch(toggleChat())
        GA("button", "HEB: Chat", "Clicked the button")
    }


    const toWorkspace = () => {
        history.push(routes.account)

        setTimeout(() => {
            const element = document.getElementById("workspace")
            if (element) {
            // 👇 Will scroll smoothly to the top of the next section
                element.scrollIntoView({ behavior: "smooth" })
            }
        }, 100)

    }

    const currentLocation = window.location.pathname

    return (
        <div className="navigation-bar">
            <div className="onboarding__footer-elements">
                <Link to={routes.overview}>
                    <FernitaLogo className="navigation-bar__logo" />
                </Link>
            </div>
            <div className="onboarding__navigation-elements">
                <Link to={routes.overview} className={currentLocation === "/overview" ? "current-navigation-item": ""}>
                    <MatchMedia rule={MediaRule.TabletAndAbove}>
                        <span>{__("common.dictionary.overview")}</span>
                    </MatchMedia>
                </Link>
                {!showChat && (

                    <MatchMedia rule={MediaRule.TabletAndAbove}>
                        <button onClick={() => setOpenHebModal(true)}>Stöd vid bortgång</button>
                    </MatchMedia>

                )}
                <MatchMedia rule={MediaRule.TabletAndAbove}>
                    <button className={currentLocation === "/account" ? "current-navigation-item": ""} onClick={toWorkspace}>Min prenumeration</button>
                </MatchMedia>


            </div>
            <div className="onboarding__navigation-account">
                {
                    showChat && (
                        <Badge
                            invisible={state.unseenMessagesAmount <= 0}
                            badgeContent={state.unseenMessagesAmount}
                            variant={isScreenBig ? "standard" : "dot"}
                            {...badgeStyle}>
                            <MatchMedia rule={MediaRule.PhoneOnly}>
                                <IconButton
                                    size="small"
                                    icon={<ForumIcon />}
                                    outlined={!state.open}
                                    className="gray-button--outlined"
                                    onClick={handleChat} />
                            </MatchMedia>
                            <MatchMedia rule={MediaRule.TabletAndAbove}>
                                <Button
                                    size="small"
                                    outlined={!state.open}
                                    className="gray-button--outlined"
                                    onClick={handleChat}>
                                    <ForumIcon /> {__("chat.workspace.header")}
                                </Button>
                            </MatchMedia>
                        </Badge>
                    )
                }
                <button className="navigation-bar__button" onClick={() => setModalOpen(true)}>
                    {user && <UserProfile user={user} workspace={user.current_workspace} compact={!isScreenBig} />}
                </button>
                {modalOpen && <ProfileMenuModal closeModal={() => setModalOpen(false)} />}
                {openHebModal && <HebIntroModal closeModal={() => setOpenHebModal(false)} />}

            </div>



        </div>
    )

}

export default NavigationBar
