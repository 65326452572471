import React from "react"
import { InputProps } from "@/components/Inputs/TextInput"
import classNames from "classnames"

type RadioProps = Omit<InputProps, "Icon" | "placeholder" | "error" >

const Radio: React.FC<RadioProps> = ({ label, disabled, ...props }) => {

    const classes = classNames("checkbox-radio", { "checkbox-radio__label--disabled": disabled })

    return (
        <label className={classes}>
            <input
                className="checkbox-radio__input"
                type="radio"
                disabled={disabled}
                { ...props } />
            <span className="checkbox-radio__custom-radio" />
            <span className="checkbox-radio__label-text">{label}</span>
        </label>
    )
}

export default Radio
