import React, { useState } from "react"
import { useParams } from "react-router-dom"
import Modal, { FormActions, ModalBody, ModalFooter, ModalHeader } from "@/components/Modal"
import Select from "@/components/Inputs/Select"
import Button from "@/components/Button"
import { vaultServiceIdTranslationKeyMap } from "@/api/vault"
import { useGetAllSubfolders } from "@/api/folders"
import MoveToInboxIcon from "@mui/icons-material/MoveToInbox"
import SubmitButton from "@/components/SubmitButton"
import { AxiosError } from "axios"
import { useVaultContext } from "@/components/Vault/Views/DefaultServiceView"
import { VaultModalProps, VaultServiceViewUrlParams } from "@/views/VaultServiceView/types"
import Heading from "@/components/Heading"

const MoveVaultItemModal: React.FC<VaultModalProps> = ({ id, type, ...modalProps }) => {
    const { serviceId, folderId } = useParams<VaultServiceViewUrlParams>()
    const translationKey = vaultServiceIdTranslationKeyMap[serviceId]
    const { moveItem } = useVaultContext()
    const [chosenFolderId, setChosenFolderId] = useState<undefined | number>(undefined)
    const [error, setError] = useState("")
    const [isSubmitting, setIsSubmitting] = useState(false)
    const parentFolderId = folderId || serviceId

    const rootOption = { value: serviceId, label: __(`vault.${translationKey}.title`) }
    const { data } = useGetAllSubfolders(serviceId)
    const filterData = data ? data.filter((folder) => folder.id !== id) : []
    const subFolders = filterData.map((folder) => ({ value: folder.id.toString(), label: folder.title }))
    const allFolderOptions = [rootOption, ...subFolders].filter((folder) => folder.value !== parentFolderId)

    const handleSelectedFolder = (option: unknown) => {
        const id = parseInt((option as { value: string }).value)
        setChosenFolderId(id)
    }

    const handleChangeFolder = async (e: React.FormEvent) => {
        e.preventDefault()
        if (!chosenFolderId) return

        setError("")
        setIsSubmitting(true)

        try {
            await moveItem?.(chosenFolderId, id, type)
            modalProps.closeModal()
        } catch (_error) {
            const error = _error as AxiosError
            setIsSubmitting(false)
            setError(error.code === "422" ? error.message : __("common.errors.network.unknown"))
        }
    }

    return (
        <Modal {...modalProps}>
            <form onSubmit={handleChangeFolder}>
                <ModalHeader>
                    <Heading level={2} icon={<MoveToInboxIcon />} title={__("common.dictionary.move")} />
                </ModalHeader>
                <ModalBody>
                    <Select options={allFolderOptions || []} onChange={handleSelectedFolder} />
                    {error && <span className="input__wrapper--error">{error}</span>}
                </ModalBody>
                <ModalFooter>
                    <FormActions>
                        <Button type="button" variant="secondary" onClick={modalProps.closeModal}>
                            {__("common.dictionary.cancel")}
                        </Button>
                        <SubmitButton isSubmitting={isSubmitting}>
                            {__("common.dictionary.save")}
                        </SubmitButton>
                    </FormActions>
                </ModalFooter>
            </form>
        </Modal>
    )
}

export default MoveVaultItemModal
