import React from "react"
import { Helmet } from "react-helmet"
import OnboardingLayout from "@/components/OnboardingLayout"
import WorkspaceButton from "@/components/WorkspaceButton"
import { nicknameToWorkspaceTitle } from "@/utilities"
import { useGetUserQuery, useSetCurrentWorkspaceMutation, useWorkspaces } from "@/api/user"
import { Redirect } from "react-router-dom"
import { routes } from "@/config/routes"

const WorkspacePickerView = () => {
    const workspaces = useWorkspaces()
    const { data: user } = useGetUserQuery()
    const setCurrentWorkspaceMutation = useSetCurrentWorkspaceMutation()

    const changeWorkspace = async (workspaceId: number) => {
        setCurrentWorkspaceMutation.mutate(workspaceId)
    }

    if (!workspaces.length) {
        return <Redirect to={routes.root} />
    }

    return (
        <OnboardingLayout>
            <Helmet>
                <title>{__("views.workspaces.meta_title")}</title>
            </Helmet>
            <h1 className="onboarding__title">{__("views.workspaces.title")}</h1>
            <div className="onboarding__workspace-list">
                {workspaces?.map(workspace => {
                    const workspaceTitle = workspace.deceased_user_id === user?.id
                        ? __("misc.my_workspace")
                        : nicknameToWorkspaceTitle(workspace.nickname)

                    return (
                        <WorkspaceButton
                            key={workspace.relation_id}
                            profilePictureSrc={workspace.profile_picture_url}
                            workspaceTitle={workspaceTitle}
                            notMine={workspace.deceased_user_id === user?.id}
                            className="white-workspace-button"
                            onClick={() => changeWorkspace(workspace.relation_id)} />
                    )
                })}
            </div>
        </OnboardingLayout>
    )
}

export default WorkspacePickerView
