import React from "react"
import { Redirect } from "react-router-dom"
import { Helmet } from "react-helmet"
import { UserResource } from "@/types/models/User"
import { routes } from "@/config/routes"
import BankId from "@/components/BankId"
import OnboardingLayout from "@/components/OnboardingLayout"
import { useQueryClient } from "react-query"
import { useIsLoggedIn, UserQuery, useUpdateUserMutation, useWorkspaces } from "@/api/user"
import UserNotFoundView from "../UserNotFoundView"
import ReactGA from "react-ga4"
import { Userpilot } from "userpilot"

const LoginView = () => {
    const isLoggedIn = useIsLoggedIn()
    const workspaces = useWorkspaces()
    const [showRegistrationPrompt, setShowRegistrationPrompt] = React.useState((!isLoggedIn && location?.hash === "#redirect") ?? false)
    const queryClient = useQueryClient()
    const updateUserMutation = useUpdateUserMutation()

    const handleSuccess = async () => {
        ReactGA.event({
            category: "Button",
            action: "Log in - Success ",
            label: "Sign in",
            value: 1,
        })
        await queryClient.invalidateQueries(UserQuery.Get)

        const user = queryClient.getQueryData<UserResource>(UserQuery.Get)
        if (user) {
            Userpilot.identify(String(user.id), { name: user.first_name, email: user.email, created_at: null })
        }

        const details = {
            last_active: new Date().toISOString(),
        }
        updateUserMutation.mutate(details)


        if (user?.workspaces.length === 0) setShowRegistrationPrompt(true)
    }

    const handleFailure = (reason?: "no_user_found" | string) => {
        if (reason === "user_not_found") {
            ReactGA.event({
                category: "Button",
                action: "Log in - failed (user not found)",
                label: "Sign in",
                value: 1,
            })
            setShowRegistrationPrompt(true)
        } else {
            ReactGA.event({
                category: "Button",
                action: "Log in - failed",
                label: "Sign in",
                value: 1,
            })
        }
    }

    if (isLoggedIn) {
        let redirectUrl: string = routes.root

        if (workspaces && workspaces.length === 1) redirectUrl = routes.overview
        else if (workspaces && workspaces.length > 1) redirectUrl = routes.workspaces

        const details = {
            last_active: new Date().toISOString(),
        }
        updateUserMutation.mutate(details)

        return <Redirect to={redirectUrl} />
    }
    return (
        !showRegistrationPrompt ? (
            <OnboardingLayout hasBacklink>
                <Helmet>
                    <title>Logga in</title>
                </Helmet>
                <h1>Logga in med BankID</h1>
                <BankId onSuccess={handleSuccess} onFailure={handleFailure} />
            </OnboardingLayout>
        ) : (
            <UserNotFoundView />
        )
    )

}

export default LoginView

